import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from "react-query";

type MutationError = AxiosError<boolean>;
type MutationOptions = UseMutationOptions<
  boolean,
  MutationError,
  VerifyExistCpf
>;
interface VerifyExistCpf {
  cpf: string;
}

export const useGetStudentRegisterCpf = (
  options?: MutationOptions
): UseMutationResult<boolean, MutationError, VerifyExistCpf> => {
  const handleGetStudentRegisterCpf = async ({ cpf }: VerifyExistCpf) => {
    try {
      const url = "/platform/students/register/cpf";
      const params = { cpf };
      const { data } = await api.get<boolean>(url, { params });
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  return useMutation<boolean, MutationError, VerifyExistCpf>({
    mutationFn: handleGetStudentRegisterCpf,
    ...options,
  });
};
