import { FontSize } from "@constants";
import styled, { css } from "styled-components";

export const HtmlTextSectionStyled = styled.div`
  ${({ theme }) => css`
    font-size: ${FontSize.Medium};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: ${theme.font.regular};
    color: ${theme.colors.Gray700};
    line-height: ${theme.font.LineHeight.Large};
    text-align: left;

    white-space: pre-wrap;

    margin-right: ${theme.spacings.Medium};

    & * {
      font-family: ${theme.font.Family};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    & img {
      width: 100%;
    }

    & ol {
      padding-left: 16px;
      margin: ${theme.spacings.Small} 0;
      list-style-type: decimal;
      counter-reset: item;
    }

    & ul {
      padding-top: 0;
      padding-left: ${theme.spacings.Large};
    }

    & li {
      padding: 0;
      display: list-item;
      list-style-position: inside;
      margin: ${theme.spacings.Nano} 0;
    }

    & p {
      margin-top: ${theme.spacings.SemiSmall};
    }

    & span {
      display: block;
      margin-top: ${theme.spacings.Small};
    }

    iframe {
      width: 100%;
      min-height: 19rem;
    }

    h1 {
      font-size: ${FontSize.XXLarge};
      line-height: ${theme.font.LineHeight.Small};
      font-weight: ${theme.font.semiBold};
      color: ${theme.colors.Black};
      display: block;
      text-align: left;

      display: block;

      strong,
      b {
        font-weight: ${theme.font.bold};
      }
    }

    h2 {
      font-size: ${FontSize.XLarge};
      color: ${theme.colors.Black};
      line-height: ${theme.font.LineHeight.Small};
      font-weight: ${theme.font.medium};
      display: block;
      text-align: left;

      margin-top: ${theme.spacings.Large};

      strong,
      b {
        font-weight: ${theme.font.semiBold};
      }
    }

    h3 {
      font-size: ${FontSize.Large};
      line-height: ${theme.font.LineHeight.Medium};
      font-weight: ${theme.font.medium};
      color: ${theme.colors.Gray900};

      display: inline;
      text-align: left;

      strong,
      b {
        font-weight: ${theme.font.semiBold};
      }
    }

    h4 {
      font-size: ${FontSize.Medium};
      line-height: ${theme.font.LineHeight.Medium};
      font-weight: ${theme.font.medium};
      color: ${theme.colors.Gray700};

      display: block;
      text-align: left;

      strong,
      b {
        font-weight: ${theme.font.medium};
        color: ${theme.colors.Gray800};
      }
    }

    h5 {
      font-size: ${FontSize.Small};
      line-height: ${theme.font.LineHeight.Large};
      font-weight: ${theme.font.regular};
      color: ${theme.colors.Gray700};
      text-align: left;

      strong,
      b {
        color: ${theme.colors.Gray800};
        font-weight: ${theme.font.medium};
      }
    }

    h6 {
      font-size: ${FontSize.Small};
      line-height: ${theme.font.LineHeight.Large};
      font-weight: ${theme.font.regular};
      color: ${theme.colors.Gray600};
      text-align: left;

      strong,
      b {
        font-weight: ${theme.font.medium};
      }
    }

    p,
    li,
    ol,
    ul,
    div,
    section,
    text,
    body {
      font-size: ${FontSize.Medium};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: ${theme.font.regular};
      color: ${theme.colors.Gray700};
      line-height: ${theme.font.LineHeight.Large};
      text-align: left;

      strong,
      b {
        font-weight: ${theme.font.medium};
        color: ${theme.colors.Gray800};
      }

      & + & {
        margin-top: ${theme.spacings.SemiSmall};
      }
    }

    a {
      font-size: 1em;
      text-decoration: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: ${theme.font.medium};
      color: ${theme.colors.Primary};
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    * strong {
      color: ${theme.colors.Gray800};
      font-weight: ${theme.font.semiBold};
    }
  `}
`;
