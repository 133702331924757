import { ENV_EMAIL_SUPPORT, ENV_WEBSITE_NAME } from "@variables";

export * from "./error-fallback";
export * from "./error-handler";

export const mailto = (error: Error, componentStack?: string): string => {
  const formatStack = (stack?: string) => {
    return stack?.replace(/\s+/g, " ")?.replaceAll(" at", "%0D%0Aat");
  };

  const content = `mailto:${ENV_EMAIL_SUPPORT}?subject=Error crítico na plataforma ${ENV_WEBSITE_NAME}&body=
    Olá, equipe de suporte da ${ENV_WEBSITE_NAME}!
    %0D%0A
    Descreva como ocorreu o erro e o que estava fazendo na plataforma.
    .%0D%0A
    .%0D%0A
    .%0D%0A
    .%0D%0A
    %0D%0A
    Segue abaixo o erro detectado na plataforma.
    %0D%0A
    %0D%0A
    NOME: ${error?.name}
    %0D%0A
    MENSAGEM: ${error?.message}
    %0D%0A
    STACK: ${formatStack(error?.stack)}`;

  return componentStack
    ? content + `%0D%0A COMPONENT STACK: ${formatStack(componentStack)}`
    : content;
};
