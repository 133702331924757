import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from "react-query";
import { ObjectStudentContinue, StudentContinue } from ".";

type MutationError = AxiosError<ObjectStudentContinue>;
type MutationOptions = UseMutationOptions<
  ObjectStudentContinue,
  MutationError,
  StudentContinue
>;

export const usePutStudentContinue = (
  options?: MutationOptions
): UseMutationResult<ObjectStudentContinue, MutationError, StudentContinue> => {
  const handlePutOneStudent = async (student: StudentContinue) => {
    try {
      const url = "/platform/students/continue";
      const { data } = await api.put<ObjectStudentContinue>(url, student);
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<ObjectStudentContinue, MutationError, StudentContinue>({
    mutationFn: handlePutOneStudent,
    ...options,
  });
};
