import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useQuery,
  QueryKey,
  UseQueryResult,
  UseQueryOptions,
} from "react-query";
import { StudentSavedMaterial } from ".";

export const getStudentSavedMaterialsKey = (): QueryKey =>
  "SavedMaterialsControllerFindAll";

type QueryError = AxiosError<StudentSavedMaterial[]>;
type QueryOptions = UseQueryOptions<StudentSavedMaterial[], QueryError>;

export const useGetStudentSavedMaterials = (
  options?: QueryOptions
): UseQueryResult<StudentSavedMaterial[], QueryError> => {
  const handleGetStudentSavedMaterials = async () => {
    try {
      const url = "/platform/students/saved-materials";
      const { data } = await api.get<StudentSavedMaterial[]>(url);
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  return useQuery<StudentSavedMaterial[], QueryError>({
    queryKey: getStudentSavedMaterialsKey(),
    queryFn: handleGetStudentSavedMaterials,
    ...options,
  });
};
