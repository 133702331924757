import { Student } from "@interfaces/kerala-api";
import { createContext, Dispatch, SetStateAction } from "react";

export interface Location {
  pathname: string;
  search: string;
  state: unknown;
  hash: string;
  key?: string | undefined;
}

export interface AuthContextI {
  logout: () => void;

  accessTokenLocalStorage: string;
  setAccessTokenLocalStorage: Dispatch<SetStateAction<string>>;

  userLocalStorage: Student;
  setUserLocalStorage: Dispatch<SetStateAction<Student>>;

  currentUrl: Location;
}

export const AuthContext = createContext<AuthContextI>({} as AuthContextI);
