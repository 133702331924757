import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from "react-query";
import { OnboardingStudent } from ".";

type MutationError = AxiosError<OnboardingStudent>;
type UpdateOnboarding = Partial<OnboardingStudent>;
type MutationOptions = UseMutationOptions<
  OnboardingStudent,
  MutationError,
  UpdateOnboarding
>;

export const usePutStudentOnboarding = (
  options?: MutationOptions
): UseMutationResult<OnboardingStudent, MutationError, UpdateOnboarding> => {
  const handlePutStudentOnboarding = async (
    onboardingData: UpdateOnboarding
  ) => {
    try {
      const url = "/platform/students/onboarding";
      const { data } = await api.put<OnboardingStudent>(url, onboardingData);
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<OnboardingStudent, MutationError, UpdateOnboarding>({
    mutationFn: handlePutStudentOnboarding,
    ...options,
  });
};
