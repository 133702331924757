import { ScriptSettings, useScripts } from "@hooks";

export const useHotJar = (): void => {
  const innerHTML = `
  (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){
      (h.hj.q=h.hj.q||[]).push(arguments);};
      h._hjSettings={hjid:3133878,hjsv:6};
      a=o.getElementsByTagName("head")[0];
      r=o.createElement("script");
      r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);})(window,document,"https://static.hotjar.com/c/hotjar-",".js?sv=")
`;

  const options: ScriptSettings = { inScope: "head" };
  useScripts({ innerHTML, async: true, id: "hotjar" }, options);
};
