/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
enum FontSizes {
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
  XLarge = "XLarge",
  XXLarge = "XXLarge",
  Mega = "Mega",
}

type ResponsiveFontSizes = {
  [key in FontSizes]?: {
    xs?: string;
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
  };
};

const fontSizes: ResponsiveFontSizes = {
  [FontSizes.Small]: {
    xs: "10px",
    sm: "11px",
    md: "11.5px",
    lg: "12px",
    xl: "12px",
  },
  [FontSizes.Medium]: {
    xs: "11px",
    sm: "11.5px",
    md: "12px",
    lg: "13px",
    xl: "14px",
  },
  [FontSizes.Large]: {
    xs: "12px",
    sm: "13px",
    md: "14px",
    lg: "15px",
    xl: "16px",
  },
  [FontSizes.XLarge]: {
    xs: "16px",
    sm: "17px",
    md: "17.5px",
    lg: "19px",
    xl: "21px",
  },
  [FontSizes.XXLarge]: {
    xs: "19px",
    sm: "19px",
    md: "24px",
    lg: "26px",
    xl: "28px",
  },
  [FontSizes.Mega]: {
    xs: "24px",
    sm: "24px",
    md: "28px",
    lg: "32px",
    xl: "35px",
  },
};

export class FontSize {
  static Small = `
    ${fontSizes.Small?.xl};

    @media only screen and (max-width: 767px) and (min-width: 576px) {
      font-size: ${fontSizes.Small?.sm};
    }

    @media only screen and (max-width: 991px) and (min-width: 768px) {
      font-size: ${fontSizes.Small?.md};
    }

    @media only screen and (max-width: 1199px) and (min-width: 992px) {
      font-size: ${fontSizes.Small?.lg};
    }

    @media only screen and (min-width: 1200px) {
      font-size: ${fontSizes.Small?.xl};
    }

    @media only screen and (max-width: 575px) {
      font-size: ${fontSizes.Small?.xs};
    }
  `;

  static Medium = `
    ${fontSizes.Medium?.xl};

    @media only screen and (max-width: 767px) and (min-width: 576px) {
      font-size: ${fontSizes.Medium?.sm};
    }

    @media only screen and (max-width: 991px) and (min-width: 768px) {
      font-size: ${fontSizes.Medium?.md};
    }

    @media only screen and (max-width: 1199px) and (min-width: 992px) {
      font-size: ${fontSizes.Medium?.lg};
    }

    @media only screen and (min-width: 1200px) {
      font-size: ${fontSizes.Medium?.xl};
    }

    @media (max-width: 575px) {
      font-size: ${fontSizes.Medium?.xs};
    }
  `;

  static Large = `
    ${fontSizes.Large?.xl};

    @media only screen and (max-width: 767px) and (min-width: 576px) {
      font-size: ${fontSizes.Large?.sm};
    }

    @media only screen and (max-width: 991px) and (min-width: 768px) {
      font-size: ${fontSizes.Large?.md};
    }

    @media only screen and (max-width: 1199px) and (min-width: 992px) {
      font-size: ${fontSizes.Large?.lg};
    }

    @media only screen and (min-width: 1200px) {
      font-size: ${fontSizes.Large?.xl};
    }

    @media (max-width: 575px) {
      font-size: ${fontSizes.Large?.xs};
    }
  `;

  static XLarge = `
    ${fontSizes.XLarge?.xl};

    @media only screen and (max-width: 767px) and (min-width: 576px) {
      font-size: ${fontSizes.XLarge?.sm};
    }

    @media only screen and (max-width: 991px) and (min-width: 768px) {
      font-size: ${fontSizes.XLarge?.md};
    }

    @media only screen and (max-width: 1199px) and (min-width: 992px) {
      font-size: ${fontSizes.XLarge?.lg};
    }

    @media only screen and (min-width: 1200px) {
      font-size: ${fontSizes.XLarge?.xl};
    }

    @media (max-width: 575px) {
      font-size: ${fontSizes.XLarge?.xs};
    }
  `;

  static XXLarge = `
    ${fontSizes.XXLarge?.xl};

    @media only screen and (max-width: 767px) and (min-width: 576px) {
      font-size: ${fontSizes.XXLarge?.sm};
    }

    @media only screen and (max-width: 991px) and (min-width: 768px) {
      font-size: ${fontSizes.XXLarge?.md};
    }

    @media only screen and (max-width: 1199px) and (min-width: 992px) {
      font-size: ${fontSizes.XXLarge?.lg};
    }

    @media only screen and (min-width: 1200px) {
      font-size: ${fontSizes.XXLarge?.xl};
    }

    @media (max-width: 575px) {
      font-size: ${fontSizes.XXLarge?.xs};
    }
  `;

  static Mega = `
    ${fontSizes.Mega?.xl};

    @media only screen and (max-width: 767px) and (min-width: 576px) {
      font-size: ${fontSizes.Mega?.sm};
    }

    @media only screen and (max-width: 991px) and (min-width: 768px) {
      font-size: ${fontSizes.Mega?.md};
    }

    @media only screen and (max-width: 1199px) and (min-width: 992px) {
      font-size: ${fontSizes.Mega?.lg};
    }

    @media only screen and (min-width: 1200px) {
      font-size: ${fontSizes.Mega?.xl};
    }

    @media (max-width: 575px) {
      font-size: ${fontSizes.Mega?.xs};
    }
  `;
}
