/* eslint-disable curly */
import { useLocalStorage, useSessionStorage } from "@hooks";
import { Student } from "@interfaces/kerala-api";
import {
  LOCAL_STORAGE_USER,
  LOCAL_STORAGE_ACCESS_TOKEN,
  SESSION_STORAGE_IS_SEND_ANALYTICS,
} from "@variables";
import React, { FC, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { analyticsSet } from "../../tools/data-analytics-tools/g-tag";
import { AuthContextI, AuthContext } from "./auth-provider.context";

export const AuthProvider: FC = ({ children }) => {
  const route = useHistory();

  const accessToken = useLocalStorage(LOCAL_STORAGE_ACCESS_TOKEN, "");
  const [accessTokenLocalStorage, setAccessTokenLocalStorage] = accessToken;

  const analytics = useSessionStorage(SESSION_STORAGE_IS_SEND_ANALYTICS, false);
  const [isSendAnalytics, setIsSendAnalytics] = analytics;

  //TODO: Pode ser substituído pelo contexto do react-query key: getStudentInfoKey()
  const student = useLocalStorage<Student>(LOCAL_STORAGE_USER, {} as Student);
  const [studentLocalStorage, setStudentLocalStorage] = student;

  useMemo(() => {
    // Criar uma propriedade de usuário para o analytics!!!
    // Só pode ser chamada restritamente apenas uma vez para evitar duplicidade de dados
    if (!isSendAnalytics && studentLocalStorage) {
      const studentConfigProperties = {
        id: studentLocalStorage._id,
        admin: studentLocalStorage.admin,
        gender: studentLocalStorage?.gender,
        class: studentLocalStorage.class?.name,
        company: studentLocalStorage.company?.name,
      };

      analyticsSet({ user_properties: studentConfigProperties });
      console.info("Dados enviados para o google analytics");
      setIsSendAnalytics(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendAnalytics, studentLocalStorage]);

  const logout = () => {
    setIsSendAnalytics(false);
    setAccessTokenLocalStorage("");
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_USER);

    sessionStorage.clear();
    route.push("/login");

    setTimeout(() => window.location.reload(), 500);
  };

  return (
    <AuthContext.Provider
      value={{
        logout,

        userLocalStorage: studentLocalStorage,
        setUserLocalStorage: setStudentLocalStorage,

        accessTokenLocalStorage,
        setAccessTokenLocalStorage,

        currentUrl: route.location,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextI => {
  const config = useContext(AuthContext);
  return config;
};
