import React, { FC, useState } from "react";
import { useTheme } from "styled-components";
import { SwitchStyled, SwitchMarkerStyled } from "./switch.component.style";

interface SwitchProps {
  onChange: () => void;
  disabled?: boolean;
  toggleTheme?: boolean;
  defaultChecked?: boolean;
}

export const Switch: FC<SwitchProps> = (props) => {
  const { onChange, disabled, toggleTheme, defaultChecked } = props;

  const checkedValue = defaultChecked ? defaultChecked : false;
  const [checked, setChecked] = useState(checkedValue);

  //TODO: Remover o toggleTheme e deixar o componente mais genérico;
  const theme = useTheme().title;

  return (
    <SwitchStyled
      checked={toggleTheme ? theme === "dark" : checked}
      disabled={disabled}
      onClick={() => {
        !disabled && setChecked((prev) => !prev);
        onChange();
      }}
    >
      <SwitchMarkerStyled
        checked={toggleTheme ? theme === "dark" : checked}
        disabled={disabled}
      />
    </SwitchStyled>
  );
};
