import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useQuery,
  QueryKey,
  UseQueryResult,
  UseQueryOptions,
} from "react-query";
import { StudentContinue } from ".";

export const getStudentContinueKey = (): QueryKey =>
  "StudentsControllerGetStudentContinue";

type QueryError = AxiosError<StudentContinue>;
type QueryOptions = UseQueryOptions<StudentContinue, QueryError>;

export const useGetStudentContinue = (
  options?: QueryOptions
): UseQueryResult<StudentContinue, QueryError> => {
  const handleGetStudentContinue = async () => {
    try {
      const url = "/platform/students/continue";
      const { data } = await api.get<StudentContinue>(url);
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  return useQuery<StudentContinue, QueryError>({
    queryKey: getStudentContinueKey(),
    queryFn: handleGetStudentContinue,
    ...options,
  });
};
