import { Breakpoint, Radius, Spacing } from "@constants";
import styled from "styled-components";

export const LogoStyled = styled.img`
  display: inline-block;

  border-radius: ${Radius.Small};
  max-height: ${Spacing.SemiLarge};
`;

export const LogoDefaultStyled = styled.img`
  object-fit: scale-down;
  max-height: 100px;
  width: 100%;

  @media only screen and (max-width: ${Breakpoint.Small}) {
    max-height: 50px;
  }
`;
