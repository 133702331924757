import { useEffect, useState } from "react";

type Width = number;
type Height = number;

export const useResize = (): [Width, Height] => {
  const [width, setWidth] = useState<Width>(window.innerWidth);
  const [height, setHeight] = useState<Height>(window.innerHeight);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    updateWidthAndHeight();

    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [width, height]);

  return [width, height];
};
