import { api } from "@utils";
import { AxiosError } from "axios";
import {
  MutationKey,
  useMutation,
  UseMutationResult,
  UseMutationOptions,
  useQueryClient,
} from "react-query";
import { MutationState } from "react-query/types/core/mutation";
import { StudentValidation } from ".";

interface MutationVariables {
  value: string;
  key: string;
}

type MutationError = AxiosError<StudentValidation>;
type MutationOptions = UseMutationOptions<
  StudentValidation,
  MutationError,
  MutationVariables
>;
type MutationReturn = UseMutationResult<
  StudentValidation,
  MutationError,
  MutationVariables
>;

export const getAuthenticationValidationKey = (): MutationKey =>
  "AuthenticationControllerFindStudentValidation";

export const useGetAuthenticationValidation = (
  options?: MutationOptions
): MutationReturn => {
  const handleGetAuthenticationValidation = async (
    bodyData: MutationVariables
  ) => {
    try {
      const url = "/platform/authentication/validation";
      const { key, value } = bodyData;
      const params = { value, key };

      const { data } = await api.get<StudentValidation>(url, {
        params,
      });

      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<StudentValidation, MutationError, MutationVariables>({
    mutationKey: getAuthenticationValidationKey(),
    mutationFn: handleGetAuthenticationValidation,
    ...options,
  });
};

// TODO: Solução temporária para pegar o estado da última mutation de useGetAuthenticationValidation;
// No mundo ideal seria melhor a getAuthenticationValidationKey aceitar um parâmetro com um identificador e assim ficar única;
type MutationStateAuthValidation = MutationState<
  StudentValidation,
  MutationError,
  MutationVariables
>;

export const useLastStateAuthValidation = (): MutationStateAuthValidation => {
  const queryClient = useQueryClient();

  return queryClient
    .getMutationCache()
    .findAll({ mutationKey: getAuthenticationValidationKey() })
    .slice(-1)[0].state as MutationStateAuthValidation;
};
