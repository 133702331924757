/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import { useClickOutside } from "@hooks";
import { useField } from "@unform/core";
import React, {
  useRef,
  useState,
  useEffect,
  ReactNode,
  MutableRefObject,
  FC,
} from "react";
import { InputLabel, Separator } from "@atomic";
import {
  IconStyled,
  OptionStyled,
  SelectTextStyled,
  SelectInputStyled,
  SelectWrapperStyled,
  OptionsWrapperStyled,
  SelectContainerStyled,
  SelectInputArrowStyled,
  NoResultsWrapperStyled,
  SelectInputWrapperStyled,
  OptionLeftSideWrapperStyled,
  OptionRightSideWrapperStyled,
  SelectInputArrowWrapperStyled,
  SelectInputContentWrapperStyled,
} from "./custom-select.component.style";

interface SelectProps {
  id: string;
  name: string;
  options: any[];
  label?: string;
  icon?: ReactNode;
  leftSide?: string;
  rightSide?: string;
  disabled?: boolean;
  placeholder: string;
  optionLabel: string;
  defaultOption?: string;
  searchType?: "startWith" | "includes";
  setSelectedOption?: (selectedOption: any) => void;
}

export const Select: FC<SelectProps> = ({
  id,
  name,
  icon,
  label,
  options,
  leftSide,
  disabled,
  rightSide,
  searchType,
  optionLabel,
  placeholder,
  defaultOption = "",
  setSelectedOption,
}) => {
  const [open, setOpen] = useState(false);
  const SelectContainerRef = useRef() as MutableRefObject<HTMLDivElement>;
  useClickOutside(SelectContainerRef, () => {
    setOpen(false);

    if (hoveredItem > -1 && query.length > 0) {
      setQuery("");
      setValue(filteredOptions[hoveredItem]);
    }

    inputRef.current?.blur();
  });

  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, inputRef, registerField]);

  const [value, setValue] = useState<any>();

  const [hoveredItem, setHoveredItem] = useState(-1);

  const [selectedItem, setSelectedItem] = useState(-1);

  const [query, setQuery] = useState("");

  const optionsRef = useRef<HTMLDivElement>(null);

  const itemsRef = useRef<HTMLDivElement[]>([]);
  itemsRef.current = [];

  const addToRefs = (el: HTMLDivElement) => {
    if (el && !itemsRef.current.includes(el)) {
      itemsRef.current.push(el);
    }
  };

  const filter = (options: any[]) => {
    if (searchType === "startWith") {
      return options?.filter((option: any) =>
        option[optionLabel]
          .toLowerCase()
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .startsWith(
            query
              .toLowerCase()
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          )
      );
    } else {
      return options?.filter(
        (option: any) =>
          option[optionLabel]
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .indexOf(
              query
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            ) > -1
      );
    }
  };

  const displayValue = () => {
    if (query.length > 0 && searchType) {
      return query;
    }
    if (value) {
      return value[optionLabel];
    }
    return "";
  };

  const handleClick = () => {
    if (open === false) {
      setOpen(true);
      inputRef.current?.focus();

      if (searchType) {
        inputRef.current?.setSelectionRange(-1, -1);
      }
    }

    if (open) {
      if (hoveredItem > -1 && query.length > 0 && searchType === "startWith") {
        setQuery("");
        setValue(filteredOptions[hoveredItem]);
      }

      setOpen(false);
      inputRef.current?.blur();

      if (searchType) {
        inputRef.current?.setSelectionRange(-1, -1);
      }
    }
  };

  const startWithFilteredOptions = options.filter((option: any) =>
    option[optionLabel]
      .toLowerCase()
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .startsWith(
        query
          .toLowerCase()
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      )
  );

  const includesFilteredOptions = options.filter(
    (option: any) =>
      option[optionLabel]
        .toLowerCase()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .indexOf(
          query
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        ) > -1
  );

  const filteredOptions =
    searchType === "startWith"
      ? startWithFilteredOptions
      : includesFilteredOptions;

  useEffect(() => {
    if (selectedItem > -1) {
      setHoveredItem(selectedItem);
    }

    if (selectedItem <= -1) {
      if (searchType === "includes") {
        setHoveredItem(-1);
      }

      if (searchType === "startWith") {
        setHoveredItem(0);
      }

      if (query.length <= 0) {
        setHoveredItem(-1);
      }
    }

    if (query.length > 0) {
      setHoveredItem(0);
      setSelectedItem(-1);
    }
  }, [open, query]);

  useEffect(() => {
    itemsRef.current.forEach((item) => {
      if (
        open &&
        selectedItem > -1 &&
        value[id] === item.getAttribute("value") &&
        optionsRef.current
      ) {
        optionsRef.current.scrollTop = item.offsetTop - 82;
      }
    });

    if (open && selectedItem <= -1 && optionsRef.current) {
      setHoveredItem(-1);
      optionsRef.current.scrollTop = 0;
    }
  }, [open]);

  useEffect(() => {
    itemsRef.current.forEach((item, index) => {
      if (
        options.includes(value) &&
        String(value[id]) === item.getAttribute("value") &&
        optionsRef.current
      ) {
        setSelectedItem(index);

        if (setSelectedOption) {
          setSelectedOption(value);
        }
      }
    });
  }, [value]);

  const onKeyDown = (e) => {
    if (e.keyCode === 40) {
      setHoveredItem(
        hoveredItem < filteredOptions.length - 1
          ? hoveredItem + 1
          : filteredOptions.length - 1
      );

      if (hoveredItem >= 2) {
        if (optionsRef.current !== undefined && optionsRef.current !== null) {
          const optionsRefScrollTop = optionsRef.current?.scrollTop;

          optionsRef.current.scrollTop = optionsRefScrollTop + 42;
        }
      }
    }

    if (e.keyCode === 38) {
      e.preventDefault();

      setHoveredItem(hoveredItem > -1 ? hoveredItem - 1 : -1);

      if (hoveredItem >= 2) {
        if (optionsRef.current !== undefined && optionsRef.current !== null) {
          const optionsRefScrollTop = optionsRef.current?.scrollTop;

          optionsRef.current.scrollTop = optionsRefScrollTop - 42;
        }
      }
    }

    if (
      (e.keyCode === 13 && hoveredItem >= -1) ||
      (open && e.keyCode === 13 && !options.includes(value))
    ) {
      e.preventDefault();

      setValue(filteredOptions[hoveredItem]);
      setOpen(false);
      setQuery("");

      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      form.elements[index + 1].focus();
    }

    if (!open && e.keyCode === 13 && !options.includes(value)) {
      e.preventDefault();

      setOpen(true);
      setValue("");
      inputRef.current?.focus();
      setHoveredItem(-1);
    }

    if (e.keyCode === 27) {
      setOpen(false);
      inputRef.current?.blur();
    }

    if (e.keyCode === 9 && open) {
      setOpen(false);
      setQuery("");
    }
  };

  return (
    <SelectContainerStyled ref={SelectContainerRef}>
      {label && (
        <>
          <InputLabel onClick={handleClick}>{label}</InputLabel>
          <Separator type="XNano" />
        </>
      )}

      <SelectWrapperStyled
        onChange={(val) => {
          searchType && setValue(val);
        }}
      >
        <SelectInputWrapperStyled
          disabled={disabled}
          onClick={handleClick}
          isOpen={open}
          searchType={searchType}
        >
          {icon && (
            <IconStyled disabled={disabled} isOpen={open || selectedItem > -1}>
              {icon}
            </IconStyled>
          )}
          <SelectInputContentWrapperStyled>
            {query.length > 0 && searchType === "startWith" && (
              <>
                {filter(options)?.map(
                  (option: any, index: number) =>
                    hoveredItem === index && (
                      <SelectTextStyled
                        disabled={disabled}
                        searchType={searchType}
                        key={option[optionLabel]}
                      >
                        {option[optionLabel]}
                      </SelectTextStyled>
                    )
                )}
              </>
            )}

            {!searchType && (
              <SelectTextStyled
                readOnly
                disabled={disabled}
                hasValue={value}
                value={
                  value
                    ? value[optionLabel]
                    : defaultOption
                    ? defaultOption
                    : placeholder
                }
              />
            )}

            <SelectInputStyled
              disabled={disabled}
              searchType={searchType}
              value={value ? displayValue() : defaultOption}
              onChange={(e) => {
                searchType && setQuery(e.target.value);
              }}
              onKeyDown={onKeyDown}
              onFocus={handleClick}
              ref={inputRef}
              placeholder={placeholder}
              autoComplete="off"
              defaultValue={defaultValue}
            />
          </SelectInputContentWrapperStyled>

          {!disabled && (
            <SelectInputArrowWrapperStyled disabled={disabled} isOpen={open}>
              <SelectInputArrowStyled disabled={disabled} isOpen={open} />
            </SelectInputArrowWrapperStyled>
          )}
        </SelectInputWrapperStyled>

        {!disabled && (
          <OptionsWrapperStyled isOpen={open} ref={optionsRef}>
            {filter(options)?.map((option: any, index: number) => (
              <OptionStyled
                disabled={disabled}
                searchType={searchType}
                key={option[id]}
                value={option[id]}
                ref={addToRefs}
                onClick={() => {
                  setQuery("");
                  setOpen(false);
                  setValue(option);
                  setHoveredItem(index);
                }}
                hovered={index === hoveredItem}
                onMouseEnter={() => setHoveredItem(index)}
                selected={selectedItem === index}
              >
                <OptionLeftSideWrapperStyled>
                  {leftSide !== undefined && (
                    <>
                      {option[leftSide]}
                      <Separator type="Small" />
                    </>
                  )}
                  {icon && !leftSide && (
                    <IconStyled
                      disabled={disabled}
                      selected={selectedItem === index}
                    >
                      {icon}
                    </IconStyled>
                  )}

                  {option[optionLabel]}
                </OptionLeftSideWrapperStyled>

                <OptionRightSideWrapperStyled>
                  {rightSide && (
                    <>
                      <Separator type="Small" />
                      {option[rightSide]}
                    </>
                  )}
                </OptionRightSideWrapperStyled>
              </OptionStyled>
            ))}
            {filteredOptions.length <= 0 && (
              <NoResultsWrapperStyled>
                <InputLabel>Nenhum resultado encontrado!</InputLabel>
              </NoResultsWrapperStyled>
            )}
          </OptionsWrapperStyled>
        )}
      </SelectWrapperStyled>
    </SelectContainerStyled>
  );
};
