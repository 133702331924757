import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const InternalLinkStyled = styled(Link)`
  color: ${(props) => props.theme.colors.Primary};
`;

export const ExternalLinkStyled = styled.a`
  color: ${(props) => props.theme.colors.Primary};

  ${({ theme }) => css`
    font-family: ${theme.font.Family};
  `}

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
