import { Breakpoint, Spacing } from "@constants";
import styled from "styled-components";
import { Col } from "../../atomic/grid";

export const FooterWrapperStyled = styled.footer`
  background-color: ${(props) => props.theme.colors.Gray200};

  padding: ${Spacing.Medium} 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FooterContentStyled = styled(Col)`
  display: flex;

  @media only screen and (max-width: ${Breakpoint.Medium}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterRightLinksStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  flex: 1;

  @media only screen and (max-width: ${Breakpoint.Medium}) {
    margin-top: ${Spacing.SemiSmall};
  }

  @media only screen and (max-width: ${Breakpoint.XSmall}) {
    flex-wrap: wrap;

    flex-direction: column;
    align-items: center;
  }
`;
