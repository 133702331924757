import { FontSize } from "@constants";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

interface TypographyProps {
  center?: boolean;
  enhance?: boolean;
  white?: boolean;
  light?: boolean;
  userSelect?: boolean;
  inheritColor?: boolean;
}

export const Display = styled.strong<TypographyProps>`
  ${({ theme, center, inheritColor, userSelect, white }) => css`
    display: block;

    font-family: ${theme.font.Family};
    font-size: ${FontSize.Mega};
    font-weight: ${theme.font.bold};

    text-align: ${center ? "center" : "left"};
    line-height: ${theme.font.LineHeight.XSmall};

    color: ${white ? theme.colors.White : theme.colors.Black};

    ${inheritColor && "color: inherit"};

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    ${userSelect === false &&
    css`
      user-select: none;
    `}
  `}
`;

export const H1 = styled.h1<TypographyProps>`
  display: block;

  ${({ theme, center, enhance, userSelect, white }) => css`
    font-family: ${theme.font.Family};
    font-size: ${FontSize.XXLarge};
    font-weight: ${enhance ? theme.font.bold : theme.font.semiBold};

    text-align: ${center ? "center" : "left"};
    line-height: ${theme.font.LineHeight.Small};

    color: ${white ? theme.colors.White : theme.colors.Black};

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    ${userSelect === false &&
    css`
      user-select: none;
    `}
  `}
`;

export const H2 = styled.h2<TypographyProps>`
  display: block;

  ${({ theme, center, enhance, userSelect, white }) => css`
    font-family: ${theme.font.Family};
    font-size: ${FontSize.XLarge};
    font-weight: ${enhance ? theme.font.semiBold : theme.font.medium};

    text-align: ${center ? "center" : "left"};
    line-height: ${theme.font.LineHeight.Small};

    color: ${white ? theme.colors.White : theme.colors.Black};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ${userSelect === false &&
    css`
      user-select: none;
    `}
  `}
`;

export const H3 = styled.h3<TypographyProps>`
  ${({ theme, center, enhance, userSelect, white }) => css`
    font-family: ${theme.font.Family};
    font-size: ${FontSize.Large};
    font-weight: ${enhance ? theme.font.semiBold : theme.font.medium};

    line-height: ${theme.font.LineHeight.Large};
    text-align: ${center ? "center" : "left"};

    color: ${white ? theme.colors.White : theme.colors.Gray900};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ${userSelect === false &&
    css`
      user-select: none;
    `}
  `}
`;

export const H4 = styled.h4<TypographyProps>`
  ${({ theme, center, enhance, inheritColor, userSelect, white, light }) => css`
    font-family: ${theme.font.Family};
    font-size: ${FontSize.Medium};
    ${enhance
      ? `font-weight: ${theme.font.medium}; color: ${theme.colors.Gray800}`
      : `font-weight: ${theme.font.regular}; color: ${theme.colors.Gray700}`};

    ${light &&
    css`
      color: ${theme.colors.Gray600};
    `};

    color: ${white ? theme.colors.White : theme.colors.Gray700};

    ${inheritColor && "color: inherit"};

    line-height: ${theme.font.LineHeight.Large};
    text-align: ${center ? "center" : "left"};

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    ${userSelect === false &&
    css`
      user-select: none;
    `}
  `}
`;

export const H5 = styled.h5<TypographyProps>`
  ${({ theme, center, enhance, userSelect, light }) => css`
    font-family: ${theme.font.Family};
    font-size: ${FontSize.Small};
    ${enhance
      ? `font-weight: ${theme.font.medium}; color: ${theme.colors.Gray800}`
      : `font-weight: ${theme.font.regular}; color: ${theme.colors.Gray700}`};
    ${light &&
    css`
      color: ${theme.colors.Gray600};
    `}

    line-height: ${theme.font.LineHeight.Large};
    text-align: ${center ? "center; width: 100%" : "left"};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ${userSelect === false &&
    css`
      user-select: none;
    `}
  `}
`;

export const Body = styled.p<TypographyProps>`
  ${({ theme, center, enhance, userSelect, white }) => css`
    font-family: ${theme.font.Family};
    font-size: ${FontSize.Medium};
    ${enhance
      ? `font-weight: ${theme.font.medium}; color: ${theme.colors.Gray800}`
      : `font-weight: ${theme.font.regular}; color: ${theme.colors.Gray700}`};

    line-height: ${theme.font.LineHeight.Large};
    text-align: ${center ? "center; width: 100%" : "left"};

    color: ${white ? theme.colors.White : theme.colors.Gray700};

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    ${userSelect === false &&
    css`
      user-select: none;
    `}

    & + & {
      margin-top: ${theme.spacings.SemiSmall};
    }
  `}
`;

export const Quote = styled.q<TypographyProps>`
  ${({ theme, center, enhance, userSelect, white }) => css`
    font-family: ${theme.font.Family};
    font-size: ${FontSize.Medium};
    ${enhance
      ? `font-weight: ${theme.font.medium}; color: ${theme.colors.Gray800}`
      : `font-weight: ${theme.font.regular}; color: ${theme.colors.Gray700}`};

    line-height: ${theme.font.LineHeight.Large};
    text-align: ${center ? "center; width: 100%" : "left"};

    color: ${white ? theme.colors.White : theme.colors.Gray900};

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    ${userSelect === false &&
    css`
      user-select: none;
    `}

    & + & {
      margin-top: ${theme.spacings.SemiSmall};
    }
  `}
`;

export const BodyLink = styled(Link)<TypographyProps>`
  ${({ theme, userSelect }) => css`
    font-family: ${theme.font.Family};
    font-size: ${FontSize.Medium};
    font-weight: ${theme.font.medium};

    text-decoration: none;
    line-height: ${theme.font.LineHeight.XSmall};

    color: ${theme.colors.Primary};

    cursor: pointer;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:hover {
      text-decoration: underline;
    }

    ${userSelect === false &&
    css`
      user-select: none;
    `}
  `}
`;

export const InputLabel = styled.label<TypographyProps>`
  ${({ theme, userSelect, white }) => css`
    display: block;
    width: 100%;

    font-family: ${theme.font.Family};
    font-size: ${FontSize.Medium};
    font-weight: ${theme.font.medium};
    color: ${theme.colors.Gray800};

    ${white &&
    css`
      color: ${theme.colors.White};
    `};

    line-height: ${theme.font.LineHeight.XSmall};
    text-align: left;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    cursor: pointer;

    ${userSelect === false &&
    css`
      user-select: none;

      cursor: default;
    `}
  `}
`;

export const InputText = css<TypographyProps>`
  ${({ theme, userSelect, white }) => css`
    font-family: ${theme.font.Family};
    font-size: ${FontSize.Medium};
    font-weight: ${theme.font.medium};
    color: ${theme.colors.Gray800};

    ${white &&
    css`
      color: ${theme.colors.White};
    `};

    line-height: ${theme.font.LineHeight.XSmall};
    text-align: left;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    ${userSelect === false &&
    css`
      user-select: none;
    `}
  `}
`;
