import { ENV_APP_THEME, ENV_WEBSITE_NAME } from "@variables";
import React, { FC, useState } from "react";
import { Button } from "../button";
import { FaIcon } from "../fontawesome";
import { CustomLinkShare } from "./share-button.component.style";

interface Share extends Navigator {
  canShare: (data?: ShareData) => boolean;
  share: (data?: ShareData) => Promise<void>;
}

interface ShareLinkedInProps {
  title: ShareData["title"];
  url: ShareData["url"];
}

interface ShareButtonProps extends ShareData {
  LinkedIn?: boolean;
}

export const ShareButton: FC<ShareButtonProps> = (props) => {
  const { children, LinkedIn, ...shareData } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hasShare = (window.navigator as Share)?.canShare
    ? (window.navigator as Share).canShare(shareData)
    : false;

  const handleShare = async () => {
    if (!window.navigator.share) {
      return console.error("Not supported share API");
    }

    setIsLoading(true);
    try {
      await window.navigator.share(shareData);
      console.info("Shared successfully");
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (LinkedIn || !hasShare) {
    return <ShareLinkedIn title={shareData?.title} url={shareData?.url} />;
  }

  return (
    <Button
      expanded
      kind="callToAction"
      loading={isLoading}
      onClick={handleShare}
      icon={<FaIcon.Share />}
      iconPosition="right"
    >
      Compartilhar
    </Button>
  );
};

const ShareLinkedIn: FC<ShareLinkedInProps> = ({ title, url }) => {
  const organizationId = {
    mbm: 25046924, //https://www.linkedin.com/school/mbmbs/
    omni: 65690617, //https://www.linkedin.com/company/omni-brasil/
    atmo: 10180253, //https://www.linkedin.com/company/atmo-educa%C3%A7%C3%A3o/
    gentelab: 11746655, //https://www.linkedin.com/company/gentelab/
  };

  const hash = url?.split("by-hash")[1].replace(/(\/pdf)|\//g, "");

  const organizationValue = organizationId[ENV_APP_THEME]
    ? `organizationId=${organizationId[ENV_APP_THEME]}`
    : `organizationName=${ENV_WEBSITE_NAME}`;

  //Confira aqui para mais detalhes de como montar a url 'https://addtoprofile.linkedin.com/'
  const urlLinkedIn = `https://www.linkedin.com/profile/add?startTask=${title}&name=${title}&${organizationValue}&certUrl=${url}&certId=${hash}`;

  return (
    <CustomLinkShare target="_blank" rel="noopener" href={urlLinkedIn}>
      <Button
        expanded
        kind="callToAction"
        iconPosition="right"
        icon={<FaIcon.LinkedIn />}
      >
        Compartilhar
      </Button>
    </CustomLinkShare>
  );
};
