/**
 * @param date Data do material
 * @returns retorna Data no formato DD/MM/YYYY
 */
export const formatDate = (date: string): string => {
  const dataFormat = new Date(date.length === 10 ? `${date} 00:00` : date);

  const formatedDate = `${dataFormat.getDate()}/${
    dataFormat.getMonth() + 1
  }/${dataFormat.getFullYear()}`;

  return formatedDate;
};

/**
 * Inverte a data para inserção no Hasura e também envio Mailchimp
 * Tanto Hasura quando Mailchimp esperam a data com YYYY-MM-DD
 * Porém, o formulário para o usuário precisa continuar tratando a data como DD/MM/YYYY
 * @param date Data no formato DD/MM/YYYY
 * @returns retorna Data no formato YYYY-MM-DD
 */
export const auxDateDDMMYYYYinverter = (date: string): string => {
  let data_invertida = date;
  if (date.length === 8) {
    data_invertida =
      date.slice(4, 8) + "-" + date.slice(2, 4) + "-" + date.slice(0, 2);
  }
  return data_invertida;
};

/**
 * Inverte a data que é recebida do endpoint YYYY-MM-DD para o formato DD/MM/YYYY
 * @param string Data no formato YYYY-MM-DD
 * @returns retorna Data no formato DD/MM/YYYY
 */
export const auxDateYYYYMMDDinverter = (date: string): string => {
  let data_invertida = date.replaceAll("-", "");
  if (data_invertida.length === 8) {
    data_invertida = `${date.slice(8, 10)}/${date.slice(5, 7)}/${date.slice(
      0,
      4
    )}`;
  }

  return data_invertida;
};

/**
 * Função utilitária para gerar uma string da data atual formatada com separador
 * @param string será o separador da data
 * @returns retorna Data no formato DD(separador)MM(separado)YYYY
 */
export const getCurrentDate = (separator: string): string => {
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${date < 10 ? `0${date}` : `${date}`}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${year}`;
};

export const getYesterday = (data: string): string => {
  const day = String(Number(data.substr(-1)) - 1);
  const dataReplace = data.substr(0, data.length - 1);
  return dataReplace + day;
};
