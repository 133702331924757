import { LOCAL_STORAGE_THEME } from "@variables";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCurrentTheme = () => {
  const savedTheme = localStorage.getItem(LOCAL_STORAGE_THEME);

  if (savedTheme) {
    const theme = JSON.parse(savedTheme);
    return theme?.title;
  }
};
