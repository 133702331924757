import styled, { css } from "styled-components";
import { H4, H3 } from "../../atomic/typography";

export const ProfileDropdownWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfileDropdownButtonWrapperStyled = styled.button`
  ${({ theme }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;

    width: calc(${theme.spacings.Medium} + ${theme.spacings.XNano});
    height: calc(${theme.spacings.Medium} + ${theme.spacings.XNano});

    border-radius: ${theme.radius.Large};

    cursor: pointer;

    padding: 0;
    background: transparent;
    transition: box-shadow ${theme.transition.Fast} ease-in;

    &:focus-within {
      z-index: 10;
      border-radius: 5px;
      box-shadow: 0 0 0 100000rem #0000005a;
    }

    @media only screen and (max-width: ${theme.breakpoints.Small}) {
      margin-left: ${theme.spacings.Nano};
    }
  `}
`;

interface ProfileDropdownButtonImageStyledProps {
  imageUrl?: string;
}

export const ProfileDropdownButtonImageStyled = styled.img<ProfileDropdownButtonImageStyledProps>`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    background-size: cover;

    border-radius: ${theme.radius.Small};
  `}
`;

export const ProfileDropdownHeaderWrapperStyled = styled.div`
  ${({ theme }) => css`
    display: flex;

    padding: ${theme.spacings.Small} 0;
    margin: 0 ${theme.spacings.Small};

    box-shadow: ${theme.border.Bottom} ${theme.colors.Gray200};

    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      top: -8px;
      right: 6px;
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
      background: ${theme.colors.White};
    }
  `}
`;

export const ProfileDropdownLinksStyled = styled.div`
  ${({ theme }) => css`
    & > * {
      margin-bottom: ${theme.spacings.XNano};

      &:first-child {
        margin-top: ${theme.spacings.Nano};
      }
    }
  `}
`;

interface DropdownItemStyledProps {
  active?: boolean;
}

export const DropdownItemStyled = styled(H4)<DropdownItemStyledProps>`
  ${({ theme, active }) => active && `color: ${theme.colors.Primary}`}
`;

export const InitialsStyled = styled(H3)`
  ${({ theme }) => css`
    color: ${theme.colors.BrandContrast};
    padding: 0;
    margin: 0;
  `}
`;
