import { ConfigProvider, AuthProvider } from "@contexts";
import { usePersistTheme } from "@hooks";
import { lightTheme, darkTheme } from "@styles";
import { ENV_DEVELOPMENT, ENV_GTAG_ID } from "@variables";
import React, { FC } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { ErrorFallback, errorHandler } from "./pages/error-boundary";
import { AppRoutes } from "./routes";
import { useGTag } from "./tools/data-analytics-tools/g-tag";
import { useHotJar } from "./tools/data-analytics-tools/hotjar";
import { queryClient } from "./tools/query-client";

export const App: FC = () => {
  useHotJar();
  useGTag(ENV_GTAG_ID);

  const [theme, setTheme] = usePersistTheme(lightTheme);

  const toggleTheme = () => {
    setTheme(theme.title === "light" ? darkTheme : lightTheme);
  };

  return (
    <MyErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <ThemeProvider theme={theme}>
          <ToastContainer />
          <AuthProvider>
            <ConfigProvider>
              <AppRoutes toggleTheme={toggleTheme} theme={theme} />
            </ConfigProvider>
          </AuthProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </MyErrorBoundary>
  );
};

const MyErrorBoundary: FC = ({ children }) => {
  if (ENV_DEVELOPMENT) {
    return <>{children}</>;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
      {children}
    </ErrorBoundary>
  );
};
