import { useAuthContext } from "@contexts";
import { useGetLabelHook, useGetRegisterLabelHook } from "@hooks";
import { ENV_APP_THEME } from "@variables";
import React, { useContext } from "react";
import { ConfigContext, ConfigContextData } from "./config-provider.context";
import { getSingleGreeting } from "./greetings";

export const ConfigProvider: React.FC = ({ children }) => {
  const { userLocalStorage } = useAuthContext();
  const { getPathLabel } = useGetLabelHook();

  const { getRegisterLabel } = useGetRegisterLabelHook();

  const studentName = userLocalStorage?.social_name
    ? userLocalStorage?.social_name
    : userLocalStorage?.name;

  const greeting = getSingleGreeting(ENV_APP_THEME, studentName);

  return (
    <ConfigContext.Provider
      value={{
        greeting,
        getPathLabel,
        getRegisterLabel,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfigContext = (): ConfigContextData => {
  const config = useContext(ConfigContext);
  return config;
};
