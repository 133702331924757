import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from "react-query";
import { TokenProblem } from ".";

type MutationError = AxiosError<void>;
type MutationOptions = UseMutationOptions<void, MutationError, TokenProblem>;

export const usePostEmailTokenProblem = (
  options?: MutationOptions
): UseMutationResult<void, MutationError, TokenProblem> => {
  const handlePostEmailTokenProblem = async (bodyData: TokenProblem) => {
    try {
      const url = "/email/token-problem";
      const { data } = await api.post<void>(url, bodyData);
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<void, MutationError, TokenProblem>({
    mutationFn: handlePostEmailTokenProblem,
    ...options,
  });
};
