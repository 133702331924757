import React, { ReactNode } from "react";
import { ContentItemActionWrapperStyled } from "./content-item.component.style";

interface ContentItemActionProps {
  icon: ReactNode;
  clickAction?: () => void;
}

export const ContentItemAction: React.FC<ContentItemActionProps> = ({
  icon,
  clickAction,
}) => {
  return (
    <ContentItemActionWrapperStyled onClick={clickAction}>
      {icon}
    </ContentItemActionWrapperStyled>
  );
};
