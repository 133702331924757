import { FontSize } from "@constants";
import styled, { css } from "styled-components";

export const PaginationWrapperStyled = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

interface PaginationButtonProps {
  active?: boolean;
}

export const PaginationButtonStyled = styled.button<PaginationButtonProps>`
  ${({ theme, active }) => css`
    background-color: ${theme.colors.Gray200};
    color: ${theme.colors.Gray800};
    padding: ${theme.spacings.Small} ${theme.spacings.SemiSmall};
    border-radius: ${theme.radius.Small};

    user-select: none;

    font-family: ${theme.font.Family};
    font-size: ${FontSize.Medium};
    font-weight: ${theme.font.medium};

    line-height: ${theme.font.LineHeight.XSmall};
    text-decoration: none;
    text-align: center;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    cursor: pointer;
    outline: none;
    transition: all 0.2s ease-in-out;
    box-shadow: none;
    border: none;

    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;

    & + & {
      margin-left: ${theme.spacings.XNano};
    }

    &:hover {
      background-color: ${theme.colors.Gray300};
      color: ${theme.colors.Gray800};
    }

    ${active &&
    css`
      background-color: ${theme.colors.Gray800};
      color: ${theme.colors.Gray200};

      cursor: not-allowed;
      pointer-events: none;
    `};
  `}
`;
