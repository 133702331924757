import {
  LineHeight,
  FontFamily,
  FontWeight,
  FontSize,
  Spacing,
  Border,
} from "@constants";
import styled, { css } from "styled-components";
import { Frame } from "../../atomic/frame2";

interface ModalStyledProps {
  isOpen?: boolean;
}

export const ModalOverlayStyled = styled.div<ModalStyledProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;

  background-color: #000000d6;

  display: flex;
  align-items: center;
  justify-content: center;

  visibility: hidden;
  opacity: 0;

  @keyframes openMenu {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${(props) =>
    props.isOpen &&
    css`
      visibility: visible;
      animation: openMenu 0.2s ease-out;
      animation-fill-mode: forwards;
    `}
`;

export const ModalFrameStyled = styled(Frame)`
  max-height: 70vh;

  display: flex;
  flex-direction: column;

  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
`;

export const ModalHeaderStyled = styled.header`
  padding: ${Spacing.Medium};

  box-shadow: ${Border.Bottom} ${(props) => props.theme.colors.Gray200};

  position: relative;
`;

export const ModalHeaderLabelStyled = styled.label`
  font-family: ${FontFamily.Primary};
  font-size: ${FontSize.Medium};
  font-weight: ${FontWeight.Medium};
  line-height: ${LineHeight.XSmall};

  color: ${(props) => props.theme.colors.Gray800};

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseButtonStyled = styled.span`
  width: ${Spacing.Medium};
  height: ${Spacing.Medium};

  position: absolute;
  top: 50%;
  right: calc(${Spacing.Medium} - (${Spacing.Nano} * 0.75));
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  & > * {
    width: 50%;
    height: 50%;
  }
`;

export const ModalBodyStyled = styled.div`
  padding: ${Spacing.Medium};
  padding-top: calc(${Spacing.SemiSmall} + ${Spacing.XNano});

  flex: 1;
  overflow-y: auto;
`;
