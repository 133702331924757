import { Spacing } from "@constants";
import styled, { css } from "styled-components";

interface RadioButtonStyledProps {
  disabled?: boolean;
  checked?: boolean;
  active?: boolean;
}

export const RadioButtonStyled = styled.div<RadioButtonStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: ${Spacing.SemiSmall};
  max-width: ${Spacing.SemiSmall};
  height: ${Spacing.SemiSmall};

  border-radius: 50%;

  box-shadow: inset 0px 0px 0px 2px ${(props) => props.theme.colors.Gray400};

  ${(props) =>
    props.checked &&
    css`
      box-shadow: inset 0px 0px 0px 2px ${props.theme.colors.Primary};
    `}
`;

export const RadioButtonMarkerStyled = styled.span<RadioButtonStyledProps>`
  display: block;

  width: calc(${Spacing.SemiSmall} - 8px);
  height: calc(${Spacing.SemiSmall} - 8px);

  border-radius: 50%;

  background-color: ${(props) => props.theme.colors.Primary};

  opacity: 0;

  transition: all 0.2s ease-out;

  ${(props) =>
    props.checked &&
    css`
      opacity: 1;

      transition: all 0.2s ease-out;
    `};
`;
