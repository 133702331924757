import React, { FC, Fragment, useState } from "react";
import { BoxStyled, Separator } from "@atomic";
import { TabButton } from "./tab-button-navigation.component.style";

interface TabButtonNavigationProps {
  onClick: (value: string) => void;
  contents: { label: string; icon: JSX.Element }[];
}

export const TabButtonNavigation: FC<TabButtonNavigationProps> = (props) => {
  const { contents: labels, onClick } = props;
  const [active, setActive] = useState("");

  return (
    <BoxStyled v_align="flex-end" h_align="flex-start">
      {labels?.map(({ label, icon }) => (
        <Fragment key={label}>
          <TabButton
            icon={icon}
            kind="tertiary"
            disableActiveState
            active={label === active}
            onClick={() => {
              setActive((prev) => (label === prev ? "" : label));
              onClick(label === active ? "" : label);
            }}
          >
            {label}
          </TabButton>
          <Separator type="Nano" />
        </Fragment>
      ))}
    </BoxStyled>
  );
};
