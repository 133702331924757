import { useAuthContext } from "@contexts";
import { Toast } from "@molecules";
import { useGetStudentInfo } from "@services";
import { api } from "@utils";
import axios from "axios";
import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

interface UseLoginReturn {
  handleLogin: (accessToken: string) => Promise<void>;
}

export const useLogin = (): UseLoginReturn => {
  const { push } = useHistory();
  const authContext = useAuthContext();
  const { refetch, isFetching } = useGetStudentInfo({ enabled: false });

  const handleLogin = useCallback(async (accessToken: string) => {
    //TODO: Remover instancia do axios quando terminar de migrar para o react-query
    axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
    api.defaults.headers.Authorization = `Bearer ${accessToken}`;

    const { setUserLocalStorage, setAccessTokenLocalStorage, currentUrl } =
      authContext;

    const { data, isError } = await refetch();

    if (isError || !data) {
      Toast({
        type: "error",
        title: "Erro ao logar na plataforma",
        message:
          "Aguarde alguns instantes e tente novamente, se o erro persistir, entre em contato com o suporte.",
      });
      return;
    }

    setUserLocalStorage(data);
    setAccessTokenLocalStorage(accessToken);
    return push(currentUrl.pathname === "/login" ? "/" : currentUrl);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetching) {
      Toast({
        type: "info",
        title: "Logando na plataforma...",
        message: "Aguarde alguns instantes.",
      });
    }
  }, [isFetching]);

  return { handleLogin };
};
