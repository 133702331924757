import React from "react";
import { Icon, FaIcon, Frame, Separator, H3, Hbox } from "@atomic";

interface PlaceholderProps {
  minWidth?: string;
  minHeight?: string;
  type: string;
  date?: string;
  // message?: string;
}

export const Placeholder: React.FC<PlaceholderProps> = (props) => {
  // const typeKeyValue = props.type ? props.type : "empty";
  const ErrorTypeMessage = {
    400: "Requisição inválida. ",
    401: "Você não está autenticado.",
    403: "Você não possui acesso para esta requisição.",
    404: "Este recurso não foi encontrado. ",
    405: "Método não permitido. ",
    408: "Tempo de requisição esgotado. ",
    500: "Erro interno do servidor. ",
    501: "Método não implementado. ",
    empty: "Não há nenhum conteúdo deste tipo. ",
    unavailable: "Este conteúdo não está disponível. ",
  };
  return (
    <Frame type="tertiary" border padding="SemiLarge">
      <Hbox>
        <Hbox.Item h_align="center">
          <Icon
            kind="tertiary"
            iconSize="Medium"
            icon={<FaIcon.Exclamation />}
          />
          <Separator type="Small" />
          {/* <H3>{props.message ? props.message : ErrorTypeMessage[typeKeyValue]}</H3> */}
          <H3>{ErrorTypeMessage[props.type]}</H3>
        </Hbox.Item>
      </Hbox>
    </Frame>
  );
};
