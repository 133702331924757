import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from "react-query";
import { Doubts } from ".";

type MutationError = AxiosError<void>;
type MutationOptions = UseMutationOptions<void, MutationError, Doubts>;

export const usePostEmailDoubts = (
  options?: MutationOptions
): UseMutationResult<void, MutationError, Doubts> => {
  const handlePostEmailDoubts = async (bodyData: Doubts) => {
    try {
      const url = "/email/doubts";
      const { data } = await api.post<void>(url, bodyData);
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<void, MutationError, Doubts>({
    mutationFn: handlePostEmailDoubts,
    ...options,
  });
};
