import { LOCAL_STORAGE_THEME } from "@variables";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

type PersistThemeResponse<T> = [T, Dispatch<SetStateAction<T>>];

export function usePersistTheme<DefaultTheme>(
  initialValue: DefaultTheme
): PersistThemeResponse<DefaultTheme> {
  const [state, setState] = useState(() => {
    const savedTheme = localStorage.getItem(LOCAL_STORAGE_THEME);

    if (savedTheme) {
      return JSON.parse(savedTheme);
    } else {
      return initialValue;
    }
  });

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_THEME, JSON.stringify(state));
  }, [initialValue, state]);

  return [state, setState];
}
