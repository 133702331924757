import { Gtag, WindowWithGtag } from "./types";

export * from "./gtag";

//Adicionar eventos personalizados ao Analytics
const analyticsEvent = (event: string, params?: Record<string, any>): void => {
  return gtag("event", event, params);
};

//Adicionar propriedades personalizados do usuário ao Analytics
const analyticsSet = (params?: Record<string, any>): void => {
  return gtag("set", params ?? {});
};

export const gtag: Gtag = function (): void {
  // Can't use arrow func + destructuring as Google expects
  // arguments objects in dataLayer (not an array of arguments).
  // eslint-disable-next-line prefer-rest-params
  (window as WindowWithGtag)?.dataLayer?.push(arguments);
};

export { analyticsEvent, analyticsSet };
