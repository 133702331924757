/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { ReactNode } from "react";
import { H3, H5 } from "@atomic";
import { IconStyled } from "./icon.component.style";
interface IconProps {
  iconSize?: "Small" | "Medium" | "Large";
  icon: ReactNode;
  iconImage?: string;
  date?: string;
  kind?: "primary" | "secondary" | "tertiary" | "quaternary";
}

export const Icon: React.FC<IconProps> = ({
  iconSize,
  icon,
  iconImage,
  date,
  kind,
}) => {
  const dateSplited = date?.split("-");

  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const monthNumber = Number(dateSplited && dateSplited[1]);

  const monthName = months[+monthNumber - 1];

  if (iconImage) {
    return <IconStyled iconSize={iconSize} iconImage={iconImage} />;  
  }

  if (date) {
    return (
      <IconStyled iconSize={iconSize} date={date}>
        <H3>{dateSplited && dateSplited[2]}</H3>
        <H5 enhance>{monthName}</H5>
      </IconStyled>
    );
  }

  return (
    <IconStyled iconSize={iconSize} kind={kind}>
      {icon}
    </IconStyled>
  );
};
