import React from "react";
import { Hbox, ProgressCircle, Body, H3 } from "@atomic";

interface SelectItemProps {
  label: string;
  percentage?: number;
}

export const SelectItem: React.FC<SelectItemProps> = ({
  label,
  percentage,
}) => {
  return (
    <>
      <Hbox>
        {percentage && (
          <Hbox.Item noGrow v_align="center">
            <ProgressCircle progress={percentage} />
          </Hbox.Item>
        )}

        <Hbox.Separator />

        <Hbox.Item v_align="center">
          <Body>{label}</Body>
        </Hbox.Item>

        {percentage && (
          <Hbox.Item v_align="center" noGrow>
            <H3>{percentage}%</H3>
          </Hbox.Item>
        )}
      </Hbox>
    </>
  );
};
