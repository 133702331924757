import React from "react";
import { Separator, FaIcon, Col, Grid, Row, H1 } from "@atomic";
import { Button } from "../button";
import { H3 } from "../typography";
import { BannerStyled } from "./banner.component.style";
interface BannerProps {
  insideGrid?: boolean;
  title?: string;
  text?: string;
  linkUrl?: string;
  linkText?: string;
  image?: string;
  h_align?: "left" | "center" | "right";
  isInformative?: boolean;
  bgColor?: string;
}

export const Banner: React.FC<BannerProps> = ({
  text,
  title,
  image,
  h_align,
  linkUrl,
  bgColor,
  linkText,
  insideGrid,
  isInformative,
}) => {
  const inner = (
    <>
      {!!title && (
        <>
          <H1>{title}</H1>
          <Separator type="Small" />
        </>
      )}
      {!!text && <H3>{text}</H3>}
      {!!linkUrl && !!linkText && (
        <Col>
          <Separator type="Nano" />
          <Button
            kind="callToAction"
            onClick={() => window.open(linkUrl)}
            icon={<FaIcon.ArrowRight />}
            iconPosition="right"
          >
            {linkText}
          </Button>
        </Col>
      )}
    </>
  );

  return insideGrid ? (
    <Grid>
      <BannerStyled
        image={image}
        h_align={h_align}
        isInformative={isInformative}
        bg_color={bgColor}
      >
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {inner}
        </Col>
      </BannerStyled>
    </Grid>
  ) : (
    <BannerStyled
      insideGrid
      image={image}
      h_align={h_align}
      isInformative={isInformative}
      bg_color={bgColor}
    >
      <Grid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {inner}
          </Col>
        </Row>
      </Grid>
    </BannerStyled>
  );
};
