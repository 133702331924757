import { ScriptSettings, useScripts } from "@hooks";

export const useGTag = (id: string): void => {
  const type = "text/javascript";
  const src = `https://www.googletagmanager.com/gtag/js?id=${id}`;

  const innerHTML = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${id}');
`;

  const options: ScriptSettings = {
    inScope: "head",
    shouldUnmount: false,
    insertPosition: "afterbegin",
  };

  useScripts({ id: "ga-gtag", async: true, type, src }, options);
  useScripts({ id: "ga-gtag-script", innerHTML }, options);
};
