import { Radius } from "@constants";
import styled, { css } from "styled-components";

interface ModuleCardStyledProps {
  cardSize?: "Medium" | "Large";
  cardImage?: string;
}

const largeProportion = "156.25%";
const mediumProportion = "56.25%";

const CardRatio = {
  Medium: mediumProportion,
  Large: largeProportion,
};

export const ModuleCardStyled = styled.div<ModuleCardStyledProps>`
  width: 100%;

  ${(props) =>
    props.cardSize === "Medium"
      ? css`
          padding-top: ${CardRatio.Medium};
        `
      : css`
          padding-top: ${CardRatio.Large};
        `}

  border-radius: ${Radius.Small};

  background-color: ${(props) => props.theme.colors.Primary};
  background-image: url("${(props) => props.cardImage}");
  background-size: cover;
`;
