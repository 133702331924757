import { FontSize } from "@constants";
import styled, { css } from "styled-components";
import { InputText } from "../typography";

interface InputProps {
  disabled?: boolean;
}

export const InputItemStyled = styled.div``;

export const InputWrapperStyled = styled.div<InputProps>`
  ${({ theme, disabled }) => css`
    width: 100%;
    display: flex;
    align-items: center;

    overflow: hidden;
    position: relative;

    background-color: ${theme.colors.Gray200};

    padding: ${theme.spacings.Nano} ${theme.spacings.SemiSmall};
    border-radius: ${theme.radius.Small};

    font-size: ${FontSize.Medium};

    cursor: text;

    ${disabled &&
    css`
      cursor: not-allowed;
      background-color: transparent;
      box-shadow: ${theme.border.All} ${theme.colors.Gray200};
    `}

    &:focus-within {
      box-shadow: ${theme.border.All} ${theme.colors.Gray300};
    }
  `}
`;

interface InputStyledProps {
  showPassword?: boolean;
}

export const InputStyled = styled.input<InputStyledProps>`
  ${({ theme, type, showPassword }) => css`
    background-color: ${theme.colors.Gray200};
    outline: none;
    padding: none;
    border: none;

    flex: 1;

    width: 100%;

    ${type === "password" &&
    css`
      margin-right: calc(${theme.spacings.Nano} + 1em);
    `};

    ${showPassword &&
    css`
      margin-right: calc(${theme.spacings.Nano} + 1em);
    `};

    ${InputText};

    &::placeholder {
      color: ${theme.colors.Gray500};
    }

    &:disabled {
      cursor: not-allowed;
      color: ${theme.colors.Gray500};
      background-color: transparent;
    }

    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  `}
`;

export const IconStyled = styled.span`
  ${({ theme }) => css`
    display: block;

    min-width: 1em;
    width: 1em;
    min-height: 1em;
    height: 1em;

    margin-right: ${theme.spacings.Small};

    color: ${theme.colors.Gray400};

    &,
    * {
      transition: none;
    }

    ${InputWrapperStyled}:focus-within & {
      color: ${theme.colors.Gray600};
    }
  `}
`;

export const PasswordIconStyled = styled(IconStyled)`
  ${({ theme }) => css`
    margin-right: 0;

    position: absolute;
    right: 1px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;

    display: flex;
    align-items: center;
    justify-content: center;

    height: ${theme.spacings.Medium};

    cursor: pointer;

    padding: ${theme.spacings.Nano};
    padding-right: calc(${theme.spacings.SemiSmall} + 0.5em - 1px);

    background-color: ${theme.colors.Gray200};

    ${InputStyled}:disabled + & {
      background-color: transparent;
    }

    & > * {
      min-width: 1em;
      width: 1em;
      min-height: 1em;
      height: 1em;
    }
  `}
`;

export const InputHiddenStyled = styled.input``;
