import React from "react";
import { Icon, Separator, FaIcon, Frame, H4, H1 } from "@atomic";
import { TextWrapperStyled } from "./stats.component.style";

interface StatsProps {
  type: "primary" | "secondary" | "tertiary" | "quaternary";
  increase?: string;
  title: string;
  value: string;
  icon?: JSX.Element;
}

export const Stats: React.FC<StatsProps> = ({
  increase,
  value,
  title,
  type,
  icon,
}) => {
  const primary = type === "primary";
  return (
    <Frame
      type={primary ? "primary" : "tertiary"} //Dúvida: aqui não teria que também permitir os demais tipos?
      padding="Medium"
      paddingRight="Large"
      border
    >
      <Icon
        kind={type}
        icon={icon ? icon : <FaIcon.ThList />}
        iconSize="Large"
      />
      <Separator type="Medium" />
      <TextWrapperStyled>
        <H4 inheritColor={primary}>{title}</H4>
        <Separator type="Small" />
        <H1 inheritColor={primary}>{value}</H1>
        {increase && (
          <>
            <Separator type="XNano" />
            <H4 inheritColor={primary}>{increase}</H4>
          </>
        )}
      </TextWrapperStyled>
    </Frame>
  );
};
