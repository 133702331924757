/* eslint-disable no-shadow */
import { FontSize } from "@constants";
import { darken } from "polished";
import styled, { css, DefaultTheme } from "styled-components";

export enum ButtonKind {
  callToAction = "callToAction",
  callToActionSecondary = "callToActionSecondary",
  primary = "Primary",
  secondary = "secondary",
  tertiary = "tertiary",
}

export interface ButtonWrapperStyledProps {
  expanded?: boolean;
  vExpanded?: boolean;
  outlined?: boolean;
  kind: keyof typeof ButtonKind | "callToAction";
  type?: string;
  active?: boolean;
  disableActiveState?: boolean;
}

interface ButtonColor {
  textColor?: string;
  fontWeight?: number;
  shapeColor?: string;

  clickedShapeColor?: string;
  clickedTextColor?: string;

  hoverShapeColor?: string;
  hoverTextColor?: string;

  activeShapeColor?: string;
  activeTextColor?: string;
}

const getButtonType = (
  theme: DefaultTheme,
  type: string | undefined
): ButtonColor => {
  switch (type) {
    case ButtonKind.callToAction:
      return {
        textColor: theme.colors.BrandContrast,
        fontWeight: theme.font.regular,
        shapeColor: theme.colors.Primary,

        hoverTextColor: theme.colors.White,
        hoverShapeColor: darken(0.15, theme.colors.Primary),

        clickedTextColor: theme.colors.White,
        clickedShapeColor: theme.colors.Primary,

        activeTextColor: theme.colors.Primary,
        activeShapeColor: theme.colors.Primary + "26",
      };
    case ButtonKind.callToActionSecondary:
      return {
        textColor: theme.colors.Primary,
        fontWeight: theme.font.regular,
        shapeColor: theme.colors.Primary + "26",

        hoverTextColor: theme.colors.White,
        hoverShapeColor: darken(0.15, theme.colors.Primary),

        clickedTextColor: theme.colors.White,
        clickedShapeColor: theme.colors.Primary,

        activeTextColor: theme.colors.BrandContrast,
        activeShapeColor: theme.colors.Primary,
      };
    case ButtonKind.primary:
      return {
        textColor: theme.colors.Black,
        fontWeight: theme.font.medium,
        shapeColor: theme.colors.Gray300,

        hoverTextColor: theme.colors.Black,
        hoverShapeColor: theme.colors.Gray400,

        clickedTextColor: theme.colors.Black,
        clickedShapeColor: theme.colors.Gray300,

        activeTextColor: theme.colors.Black,
        activeShapeColor: theme.colors.Gray300,
      };
    case ButtonKind.secondary:
      return {
        textColor: theme.colors.Gray800,
        fontWeight: theme.font.medium,
        shapeColor: theme.colors.Transparent,

        hoverTextColor: theme.colors.Gray800,
        hoverShapeColor: theme.colors.Gray100,

        clickedTextColor: theme.colors.Gray800,
        clickedShapeColor: theme.colors.Transparent,

        activeTextColor: theme.colors.Gray800,
        activeShapeColor: theme.colors.Transparent,
      };
    case ButtonKind.tertiary:
      return {
        textColor: theme.colors.BrandContrast,
        fontWeight: theme.font.regular,
        shapeColor: theme.colors.Primary,

        hoverTextColor: theme.colors.White,
        hoverShapeColor: darken(0.15, theme.colors.Primary),

        clickedTextColor: theme.colors.White,
        clickedShapeColor: theme.colors.Primary,

        activeTextColor: theme.colors.Primary,
        activeShapeColor: theme.colors.Primary + "26",
      };
    default:
      return {
        textColor: theme.colors.BrandContrast,
        fontWeight: theme.font.regular,
        shapeColor: theme.colors.Primary,

        hoverTextColor: theme.colors.White,
        hoverShapeColor: darken(0.15, theme.colors.Primary),

        clickedTextColor: theme.colors.White,
        clickedShapeColor: theme.colors.Primary,

        activeTextColor: theme.colors.Primary,
        activeShapeColor: theme.colors.Primary + "26",
      };
  }
};

export const ButtonWrapperStyled = styled.button<ButtonWrapperStyledProps>`
  ${({
    theme,
    expanded,
    vExpanded,
    kind,
    active,
    disableActiveState,
    type,
  }) => css`
    position: relative;
    display: inline-block;
    width: ${expanded ? "100%" : "auto"};
    height: ${vExpanded && "100%"};
    ${expanded && "flex-basis: 100%;"};
    background-color: ${getButtonType(theme, kind).shapeColor};
    color: ${getButtonType(theme, kind).textColor};
    padding: ${theme.spacings.Small} ${theme.spacings.SemiSmall};
    border-radius: ${theme.radius.Small};
    user-select: none;
    font-family: ${theme.font.Family};
    font-size: ${FontSize.Medium};
    font-weight: ${getButtonType(theme, kind).fontWeight};
    line-height: ${theme.font.LineHeight.XSmall};
    text-decoration: none;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease-in-out;
    box-shadow: none;
    border: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${getButtonType(theme, kind).hoverShapeColor};
      color: ${getButtonType(theme, kind).hoverTextColor};
    }

    &:active {
      background-color: ${getButtonType(theme, kind).clickedShapeColor};
      color: ${getButtonType(theme, kind).clickedTextColor};
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    ${active &&
    css`
      background-color: ${getButtonType(theme, kind).activeShapeColor};
      color: ${getButtonType(theme, kind).activeTextColor};
    `}

    ${disableActiveState &&
    css`
      ${active &&
      css`
        background-color: ${getButtonType(theme, type).shapeColor};
        color: ${getButtonType(theme, type).textColor};
      `}
    `}

    ${kind === "tertiary" &&
    css`
      padding: ${theme.spacings.Nano} ${theme.spacings.Small};
      font-size: ${FontSize.Small};
      line-height: ${theme.font.LineHeight.XSmall};
    `}
  `}
`;

interface ButtonContentStyledProps {
  processing?: boolean;
}

export const ButtonContentStyled = styled.div<ButtonContentStyledProps>`
  ${({ processing }) => css`
    opacity: ${processing ? 0 : 1};
    transition: all 0.2s ease-in-out;
    font-stretch: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const ResponsiveButtonTextStyled = styled.span`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: ${theme.breakpoints.XSmall}) {
      display: none;
    }
  `}
`;

interface LoadingSpinStyledProps {
  kind: ButtonWrapperStyledProps["kind"];
  processing: ButtonContentStyledProps["processing"];
}

export const LoadingSpinStyled = styled.span<LoadingSpinStyledProps>`
  ${({ theme, kind, processing }) =>
    processing &&
    css`
      position: absolute;
      inset: 0;
      width: 1rem;
      height: 1rem;
      margin: auto;
      border: 2px solid transparent;
      border-radius: 50%;
      border-top-color: ${getButtonType(theme, kind).textColor};
      animation: button-loading-spinner 1s ease infinite;

      @keyframes button-loading-spinner {
        from {
          transform: rotate(0turn);
        }

        to {
          transform: rotate(1turn);
        }
      }
    `}
`;
