import { Toast } from "@molecules";
import {
  RegisterDataComplete,
  usePostAuthenticationLoginSenha,
  usePostAuthenticationRegister,
  usePostEmailWelcome,
} from "@services";
import { ENV_APP_THEME, ENV_WEBSITE_NAME, ENV_PLATFORM_AUTH_KEY } from "@variables";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useLogin } from "./use-login.hook";

interface UseRegisterNewStudentReturn {
  isLoading: boolean;
  register: (student: RegisterDataComplete) => Promise<void>;
}

export const useRegisterNewStudent = (): UseRegisterNewStudentReturn => {
  const { push } = useHistory();
  const { handleLogin } = useLogin();
  const emailWelcome = usePostEmailWelcome();
  const postRegister = usePostAuthenticationRegister();
  const postLoginSenha = usePostAuthenticationLoginSenha();

  const register = useCallback(async (student: RegisterDataComplete) => {
    await postRegister.mutateAsync(student, {
      onSuccess: () => {
        emailWelcome.mutate({
          client: ENV_APP_THEME,
          email: `${student?.email}`,
          username: `${student?.name} ${student?.last_name}`,
        });

        Toast({
          type: "success",
          title: "Cadastro realizado com sucesso!",
          message: `Seja bem-vindo(a) à ${ENV_WEBSITE_NAME}!`,
        });
      },

      onError: () => {
        Toast({
          type: "error",
          title: "Erro ao realizar cadastro!",
          message: `Não foi possível realizar o cadastro. Tente novamente mais tarde.`,
        });
      },
    });

    if (!student?.email || !student?.password) {
      return push("/login");
    }

    const { access_token } = await postLoginSenha.mutateAsync({
      password: student?.password,
      username: student?.[ENV_PLATFORM_AUTH_KEY],
    });

    if (!access_token) {
      return push("/login");
    }

    await handleLogin(access_token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { register, isLoading: postRegister.isLoading };
};
