import { api } from "@utils";
import { ENV_PLATFORM_AUTH_KEY } from "@variables";
import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
  MutationKey,
} from "react-query";
import { UserJWT } from "../use-query-authentication-validation-user-token";

interface MutationVariables {
  username: string;
  password: string;
}

type MutationError = AxiosError<UserJWT>;
type MutationOptions = UseMutationOptions<
  UserJWT,
  MutationError,
  MutationVariables
>;
type MutationReturn = UseMutationResult<
  UserJWT,
  MutationError,
  MutationVariables
>;

export const postAuthenticationLoginSenhaKey = (): MutationKey =>
  "AuthenticationControllerLogin";

export const usePostAuthenticationLoginSenha = (
  options?: MutationOptions
): MutationReturn => {
  const handleAuthenticationLoginSenha = async (student: MutationVariables) => {
    try {
      const url = "/platform/authentication/login-senha";
      const body = {
        password: student.password,
        username: `${ENV_PLATFORM_AUTH_KEY}:${student.username}`,
      };

      const { data } = await api.post<UserJWT>(url, body);
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<UserJWT, MutationError, MutationVariables>({
    mutationKey: postAuthenticationLoginSenhaKey(),
    mutationFn: handleAuthenticationLoginSenha,
    ...options,
  });
};
