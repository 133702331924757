import { PaddingSizes, Radius, Spacing } from "@constants";
import styled, { css } from "styled-components";

export const SearchWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    transition: box-shadow ${theme.transition.Fast} ease-in;

    &:focus-within {
      z-index: 10;
      border-radius: 5px;
      box-shadow: 0 0 0 100000rem #0000005a;
    }

    @media only screen and (max-width: ${theme.breakpoints.XSmall}) {
      & > form > div > div {
        border-radius: 0;
      }
    }
  `}
`;

export const SearchResultsWrapper = styled.div`
  ${({ theme }) => css`
    display: none;

    position: absolute;
    z-index: 4;

    padding: ${PaddingSizes.Nano};

    background: ${theme.colors.Gray100};
    border-radius: ${Radius.Small};

    max-height: 50vh;
    width: 100%;

    overflow-y: auto;

    margin-top: 1.2rem;

    ${SearchWrapper}:focus-within & {
      display: block;
    }

    @media only screen and (max-width: ${theme.breakpoints.XSmall}) {
      width: 90%;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  `}
`;

export const SearchDiamond = styled.span`
  display: none;
  position: absolute;
  bottom: -30px;
  left: 10%;

  width: 20px;
  height: 20px;

  transform: rotate(45deg);
  background: ${({ theme }) => theme.colors.Gray100};

  ${SearchWrapper}:focus-within & {
    display: block;
  }
`;

export const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.Nano};
`;
