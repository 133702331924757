import { useAuthContext } from "@contexts";

type RouteGuard = () => boolean;

export const useLoginGuard: RouteGuard = () => {
  const { userLocalStorage, logout, accessTokenLocalStorage } =
    useAuthContext();

  if (!accessTokenLocalStorage) {
    return false;
  }

  if (userLocalStorage?._id) {
    return true;
  } else {
    logout();
    return false;
  }
};
