import React from "react";
import { DropdownFrameStyled } from "./dropdown-frame.component.style";

interface DropdownFrameProps {
  paddingSize?: "XNano" | "Nano" | "Small" | "SemiSmall";
  align?: "center" | "left" | "right";
  dropdownSize?: "Medium" | "Large";
  expanded?: boolean;
  flex?: boolean;
}

export const DropdownFrame: React.FC<DropdownFrameProps> = ({
  dropdownSize,
  children,
  expanded,
  align,
  paddingSize,
  flex,
}) => {
  return (
    <DropdownFrameStyled
      dropdownSize={dropdownSize}
      expanded={expanded}
      align={align}
      paddingSize={paddingSize}
      flex={flex}
    >
      {children}
    </DropdownFrameStyled>
  );
};
