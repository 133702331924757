import React from "react";
import { DefaultTheme } from "styled-components";
import { GridSystemStyled } from "@atomic";
import { GlobalStyled } from "../styles";
import { Routes } from "./routes";
interface RoutesProps {
  toggleTheme: () => void;
  theme: DefaultTheme;
}
export const AppRoutes: React.FC<RoutesProps> = ({ toggleTheme }): any => {
  return (
    <>
      <Routes toggleTheme={toggleTheme} />
      <GridSystemStyled />
      <GlobalStyled />
    </>
  );
};
