import { api, auxDateDDMMYYYYinverter } from "@utils";
import { ENV_APP_THEME, ENV_SHOW_MEETINGS } from "@variables";
import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
  MutationKey,
} from "react-query";
import { RegisterStudent, RegisterReturn, RegisterDataComplete } from ".";

type MutationError = AxiosError<RegisterReturn>;
type MutationOptions = UseMutationOptions<
  RegisterReturn,
  MutationError,
  RegisterDataComplete
>;
type MutationReturn = UseMutationResult<
  RegisterReturn,
  MutationError,
  RegisterDataComplete
>;

export const postAuthenticationRegisterKey = (): MutationKey =>
  "AuthenticationControllerFinalizeRegistration";

export const usePostAuthenticationRegister = (
  options?: MutationOptions
): MutationReturn => {
  const handleAuthenticationRegister = async (
    student: RegisterDataComplete
  ) => {
    try {
      const url = "/platform/authentication/register";
      const body = handleFormatBody(student);
      const { data } = await api.post<RegisterReturn>(url, body);
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<RegisterReturn, MutationError, RegisterDataComplete>({
    mutationKey: postAuthenticationRegisterKey(),
    mutationFn: handleAuthenticationRegister,
    ...options,
  });
};

const handleFormatBody = (student: RegisterDataComplete): RegisterStudent => {
  const date_of_birth = student.date_of_birth.replaceAll("/", "");

  return {
    gender: student.other_gender ? student.other_gender : student.gender,
    telephone: +student.telephone.replace(/\D/g, ""),
    cpf: student.cpf.replace(/\D/g, ""),
    birthday: auxDateDDMMYYYYinverter(date_of_birth),
    last_name: student.last_name,
    password: student.password,
    email: student.email,
    name: student.name,

    class_room_id: !ENV_SHOW_MEETINGS ? 1 : student?.class_room?.value,
    department_id: student?.department?.value,
    schooling_id: student?.schooling?.value,
    company_id: student?.company?.value,
    class_id: student?.class?.value,
    role_id: student?.role?.value,

    terms_of_services: Boolean(student.terms_of_services),
    privacy_policy: Boolean(student.privacy_policy),
    client: ENV_APP_THEME,
  };
};
