import React from "react";
import { CheckIcon } from "@assets/icons/check-icon";
import { ProgressCircle } from "@atomic";
import {
  StateIndicatorCompletedStyled,
  StateIndicatorMarkerStyled,
  StateIndicatorActiveStyled,
  StateIndicatorCircle,
  StateIndicatorStyled,
  StateIndicatorSVG,
} from "./state-indicator.component.style";

interface StateIndicatorProps {
  progressCirclePercentage?: number;
  size?: "Small" | "Medium";
  notFinishable?: boolean;
  progressBar?: boolean;
  numberMarker?: number;
  completed?: boolean;
  noColor?: boolean;
  active?: boolean;
  single?: boolean;
  marker?: string;
}

export const StateIndicator: React.FC<StateIndicatorProps> = ({
  size,
  single,
  marker,
  active,
  noColor,
  completed,
  progressBar,
  numberMarker,
  notFinishable,
  progressCirclePercentage,
}) => {
  return (
    <StateIndicatorStyled progressBar={progressBar} single={single} size={size}>
      {progressBar ? (
        <ProgressCircle noColor={noColor} progress={progressCirclePercentage}>
          <StateIndicatorActiveStyled noColor={noColor} active={active} cx="15" cy="15" r="8.5" />
        </ProgressCircle>
      ) : (
        <StateIndicatorSVG
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/StateIndicatorSVG"
          size={size}
        >
          <StateIndicatorCircle cx="15" cy="15" r="13.5" size={size} />
          <StateIndicatorActiveStyled
            active={active}
            cx="15"
            cy="15"
            r="8.5"
            size={size}
          />
        </StateIndicatorSVG>
      )}

      <StateIndicatorCompletedStyled
        completed={completed}
        notFinishable={notFinishable}
        active={active}
        size={size}
      >
        <CheckIcon />
      </StateIndicatorCompletedStyled>

      {numberMarker !== undefined && (
        <StateIndicatorMarkerStyled size={size}>
          {numberMarker}
        </StateIndicatorMarkerStyled>
      )}

      {marker !== undefined && (
        <StateIndicatorMarkerStyled size={size}>
          {marker}
        </StateIndicatorMarkerStyled>
      )}
    </StateIndicatorStyled>
  );
};
