import { useAuthContext } from "@contexts";
import { usePutStudentOnboarding, OnboardingStudent } from "@services";
import { lightTheme } from "@styles";
import React, { FC, useCallback, useMemo } from "react";
import Joyride, { Step, CallBackProps, Styles, EVENTS } from "react-joyride";

interface TourProps {
  steps: Step[];
  key: keyof OnboardingStudent;
}

export type TourSteps = Step[];

export const useTour: FC<TourProps> = ({ key, steps }) => {
  const putOnboarding = usePutStudentOnboarding();
  const { userLocalStorage, setUserLocalStorage } = useAuthContext();
  const { onboarding } = userLocalStorage;
  const isRun = !onboarding[key];

  const joyrideCallback = useCallback(
    ({ type }: CallBackProps) => {
      if (type === EVENTS.TOUR_END) {
        const updated: OnboardingStudent = { ...onboarding, [key]: true };

        const handleOnSuccess = () => {
          setUserLocalStorage((oldUserLocalStorage) => {
            return { ...oldUserLocalStorage, onboarding: updated };
          });
        };

        return putOnboarding.mutate(updated, {
          onSuccess: handleOnSuccess,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key, onboarding]
  );

  return useMemo(() => {
    return (
      <Joyride
        run={isRun}
        steps={steps}
        callback={joyrideCallback}
        continuous
        showProgress
        hideCloseButton
        spotlightClicks
        scrollToFirstStep
        styles={joyrideStyles()}
        locale={{
          back: "voltar",
          close: "fechar",
          last: "finalizar",
          next: "próximo",
          skip: "pular",
          open: "Retornar à apresentação da página",
        }}
      />
    );
  }, [joyrideCallback, isRun, steps]);
};

const joyrideStyles = (): Styles => {
  const defaultStyles = {
    color: lightTheme.colors.Black,
    fontFamily: lightTheme.font.Family,
    fontWeight: lightTheme.font.regular,
    fontSize: lightTheme.spacings.Small,
    borderRadius: lightTheme.radius.Small,
  };

  const options: Styles["options"] = {
    zIndex: 1000,
    textColor: lightTheme.colors.White,
    arrowColor: lightTheme.colors.Gray300,
    primaryColor: lightTheme.colors.Primary,
    backgroundColor: lightTheme.colors.Gray300,
  };

  const tooltipTitle: Styles["tooltipTitle"] = {
    ...defaultStyles,
    fontWeight: lightTheme.font.medium,
    fontSize: lightTheme.spacings.SemiSmall,
    textAlign: "left",
    padding: 0,
  };

  const tooltipContent: Styles["tooltipContent"] = {
    ...defaultStyles,
    textAlign: "left",
    padding: 0,
  };

  const buttonBack: Styles["buttonBack"] = {
    ...defaultStyles,
    backgroundColor: lightTheme.colors.Gray400,
    color: lightTheme.colors.Gray800,
  };

  return {
    options,
    buttonBack,
    tooltipTitle,
    tooltipContent,
    tooltip: { ...defaultStyles },
    buttonNext: { ...defaultStyles },
  };
};
