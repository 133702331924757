import { PaddingProps } from "@constants";
import React, { forwardRef } from "react";
import { FrameStyled } from "./frame2.component.style";
interface FrameProps extends PaddingProps {
  type: "primary" | "secondary" | "tertiary" | "quaternary";
  border?: boolean;
  clickable?: boolean;
}

export const Frame = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<FrameProps>
>(({ children, type, border, clickable, ...rest }, ref) => {
  return (
    <FrameStyled
      type={type}
      border={border}
      clickable={clickable}
      {...rest}
      ref={ref}
    >
      {children}
    </FrameStyled>
  );
});
