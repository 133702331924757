/* eslint-disable curly */
import { Breakpoint } from "@constants";
import { useResize } from "@hooks";
import React, {
  FC,
  useRef,
  useState,
  useEffect,
  MouseEvent,
  TouchEvent,
  useCallback,
  MutableRefObject,
} from "react";
import { FaIcon } from "../fontawesome";
import {
  SlideDot,
  SlideDots,
  SlideItem,
  SlideItems,
  SlideContainer,
  SlideButtonLeft,
  SlideButtonRight,
} from "./slider.component.style";

interface SlideProps {
  slides_to_show: number;
  show_arrows?: boolean;
  is_animated?: boolean;
  show_dots?: boolean;
  children: any[];
}

interface SlideDots {
  content: string;
  isActive: boolean;
}

export const Slide: FC<SlideProps> = ({
  children,
  show_dots,
  show_arrows,
  is_animated,
  slides_to_show,
}) => {
  const itemsRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [width] = useResize();

  const [touchPosition, setTouchPosition] = useState<number | null>(null);
  const [isShowButtons, setIsShowButtons] = useState<boolean>(!!show_arrows);
  const [itemWidth, setItemWidth] = useState<number>(0);
  const [dots, setDots] = useState<SlideDots[]>([]);
  const [mouseDrag, setMouseDrag] = useState({
    isDown: false,
    startX: 0,
  });

  const BreakpointSm = Number(Breakpoint.XSmall.replace("px", ""));

  const handleLeftClick = useCallback(
    (evt?: MouseEvent<HTMLButtonElement>) => {
      evt?.preventDefault();

      const offsetWidth = itemsRef?.current?.offsetWidth;
      const widthItem =
        slides_to_show > 1
          ? offsetWidth / slides_to_show - 30
          : offsetWidth / slides_to_show;
      const qtdItensInDisplay = offsetWidth / widthItem;

      itemsRef.current.scrollLeft -= is_animated
        ? widthItem
        : widthItem * (qtdItensInDisplay - 1);

      if (itemsRef.current.scrollLeft <= 0) {
        itemsRef.current.scrollLeft = itemsRef.current.scrollWidth;
      }
    },
    [is_animated, slides_to_show]
  );

  const handleRightClick = useCallback(
    (evt?: MouseEvent<HTMLButtonElement>) => {
      evt?.preventDefault();

      const offsetWidth = itemsRef?.current?.offsetWidth;
      const widthItem =
        slides_to_show > 1
          ? offsetWidth / slides_to_show - 30
          : offsetWidth / slides_to_show;
      const qtdItensInDisplay = offsetWidth / widthItem;

      itemsRef.current.scrollLeft += is_animated
        ? widthItem
        : widthItem * (qtdItensInDisplay - 1);

      if (
        Math.ceil(
          itemsRef.current.scrollLeft + itemsRef.current.offsetWidth + 30
        ) >= itemsRef.current.scrollWidth
      ) {
        itemsRef.current.scrollLeft = 0;
      }
    },
    [is_animated, slides_to_show]
  );

  const handleMouseDown = useCallback(
    (evt: MouseEvent<HTMLDivElement>) =>
      setMouseDrag({
        isDown: true,
        startX: evt.pageX - itemsRef.current.offsetLeft,
      }),
    []
  );

  const handleMouseLeave = useCallback(
    () =>
      setMouseDrag((prevState) => ({
        ...prevState,
        isDown: false,
      })),
    []
  );

  const handleMouseUp = useCallback(
    () =>
      setMouseDrag((prevState) => ({
        ...prevState,
        isDown: false,
      })),
    []
  );

  const handleMouseMove = useCallback(
    (evt: MouseEvent<HTMLDivElement>) => {
      const { isDown, startX } = mouseDrag;
      if (isDown) {
        evt.preventDefault();
        const x = evt.pageX - itemsRef.current.offsetLeft;
        const walk = (x - startX) * 2; //scroll-fast
        if (walk > 5) {
          handleLeftClick();
        }

        if (walk < -5) {
          handleRightClick();
        }
      }
    },
    [handleLeftClick, handleRightClick, mouseDrag]
  );

  const handleTouchStart = useCallback((e: TouchEvent<HTMLDivElement>) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  }, []);

  const handleTouchMove = useCallback(
    (e: TouchEvent<HTMLDivElement>) => {
      const touchDown = touchPosition;

      if (touchDown) {
        const currentTouch = e.touches[0].clientX;
        const diff = touchDown - currentTouch;

        if (diff > 5) {
          handleRightClick();
        }

        if (diff < -5) {
          handleLeftClick();
        }

        setTouchPosition(null);
      }
    },
    [handleLeftClick, handleRightClick, touchPosition]
  );

  const handleDots = useCallback(() => {
    if (!itemsRef?.current) return;

    const scrollLeft = itemsRef?.current?.scrollLeft;
    const scrollWidth = itemsRef?.current?.scrollWidth;
    const offsetWidth = itemsRef?.current?.offsetWidth;

    const auxDots: SlideDots[] = [];
    const qtdItemsScroll = Math.ceil(scrollWidth / offsetWidth);
    const activeDot = Math.ceil(scrollLeft / offsetWidth);

    for (let i = 0; i < qtdItemsScroll; i++) {
      auxDots.push({
        content: "-",
        isActive: activeDot === i,
      });
    }

    return setDots(auxDots);
  }, []);

  useEffect(() => {
    handleDots();
  }, [handleDots, width, itemsRef?.current?.offsetWidth]);

  useEffect(() => {
    if (itemsRef?.current) {
      const offsetWidth = itemsRef?.current?.offsetWidth;
      const widthItem =
        slides_to_show > 1
          ? offsetWidth / slides_to_show - 30
          : offsetWidth / slides_to_show;
      const qtdItensInDisplay = offsetWidth / widthItem;

      setItemWidth(widthItem);
      setIsShowButtons(
        !!show_arrows && Math.floor(qtdItensInDisplay) < children.length
      );
    }
  }, [children.length, show_arrows, slides_to_show, width]);

  useEffect(() => {
    setIsShowButtons((prevState) =>
      show_arrows && width <= BreakpointSm ? false : prevState
    );
  }, [BreakpointSm, width, show_arrows]);

  useEffect(() => {
    if (!is_animated) return;
    const timer = setInterval(handleRightClick, 1000 * 20);
    return () => clearInterval(timer);
  }, [handleRightClick, is_animated]);

  useEffect(() => {
    setIsShowButtons((prevState) =>
      width <= BreakpointSm ? false : prevState
    );
  }, [BreakpointSm, width]);

  useEffect(handleDots, [handleDots, itemsRef?.current?.scrollLeft]);

  return (
    <SlideContainer showButtons={isShowButtons}>
      <SlideButtonLeft onClick={handleLeftClick}>
        <FaIcon.ArrowLeft />
      </SlideButtonLeft>

      {show_dots && (
        <SlideDots>
          {dots?.map(({ content, isActive }, i) => (
            <SlideDot key={i} isActive={isActive}>
              {content}
            </SlideDot>
          ))}
        </SlideDots>
      )}

      <SlideItems
        ref={itemsRef}
        onScroll={handleDots}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onTouchMove={handleTouchMove}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
      >
        {[...children].map((item, i) => (
          <SlideItem
            key={`${item}${i}`}
            item_width={itemWidth}
            snap_align={slides_to_show === 1}
          >
            {item}
          </SlideItem>
        ))}
      </SlideItems>

      <SlideButtonRight onClick={handleRightClick}>
        <FaIcon.ArrowRight />
      </SlideButtonRight>
    </SlideContainer>
  );
};
