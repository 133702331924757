import { Author as AuthorI } from "@interfaces/kerala-api";
import { ContentItem } from "@molecules";
import { Modal } from "@organism";
import React, { useState } from "react";
import { Body, FaIcon } from "@atomic";

interface AuthorPros {
  authors: AuthorI[];
  duration?: string | number;
}

export const Author: React.FC<AuthorPros> = ({ authors, duration }) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  //Se a url da foto vir do banco só adicionar nessa constante!
  const imageUrl = "";

  //Validação do nome do autor caso venha null
  const firstNameAuthor = authors[0].name ? authors[0].name : "";
  const lastNameAuthor = authors[0].last_name ? authors[0].last_name : "";
  const biographyAuthor = authors[0].biography ? authors[0].biography : "";

  return (
    <>
      <ContentItem
        alignCenter
        noPadding
        hasHover
        iconSize="Small"
        iconImage={imageUrl ? imageUrl : undefined}
        icon={<FaIcon.User />}
        onClick={() => setModalIsOpen((oldState) => !oldState)}
      >
        <Body>
          {duration
            ? `${firstNameAuthor} ${lastNameAuthor} • ${duration} min`
            : `${firstNameAuthor} ${lastNameAuthor}`}
        </Body>
      </ContentItem>
      <Modal
        title={`${firstNameAuthor} ${lastNameAuthor}`}
        modalIsOpen={modalIsOpen}
        setCloseModal={() => setModalIsOpen(false)}
      >
        <Body>{biographyAuthor}</Body>
      </Modal>
    </>
  );
};
