import { FontSize } from "@constants";
import styled, { css } from "styled-components";

export const TextareaStyled = styled.textarea`
  ${({ theme }) => css`
    box-sizing: border-box;
    resize: vertical;

    font-family: ${theme.font.Family};
    font-size: ${FontSize.Medium};

    border-radius: ${theme.radius.Small};
    border: none;
    outline: none;
    padding: ${theme.spacings.Small};

    width: 100%;
    height: 200px;
    background-color: ${theme.colors.Gray200};
    color: ${theme.colors.Black};
  `}
`;
