import { useField } from "@unform/core";
import React, { InputHTMLAttributes, useEffect, useState } from "react";
import {
  CheckboxBoxStyled,
  CheckboxWrapperStyled,
  DefaultCheckboxStyled,
  CheckboxArrowStyled,
} from "./checkbox.component.style";
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  icon?: JSX.Element;
  type?: string;
  disabled?: boolean;
  checked?: boolean;
}
export const Checkbox: React.FC<InputProps> = ({
  name,
  label,
  type,
  icon,
  disabled,
  checked,
  ...rest
}) => {
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const [isChecked, setIsChecked] = useState(checked || false);

  const isDisabled = disabled || false;

  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, inputRef, registerField]);

  return (
    <CheckboxWrapperStyled
      onClick={() => !isDisabled && setIsChecked(!isChecked)}
    >
      <DefaultCheckboxStyled
        ref={inputRef}
        type="checkbox"
        {...rest}
        defaultValue={defaultValue}
        checked={isChecked}
        value={String(isChecked)}
        disabled={isDisabled}
      />
      <CheckboxBoxStyled checked={isChecked} disabled={isDisabled}>
        <CheckboxArrowStyled checked={isChecked} />
      </CheckboxBoxStyled>
    </CheckboxWrapperStyled>
  );
};
