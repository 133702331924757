import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_USER,
  ENV_KERALA_BACKEND,
} from "@variables";
import axios, { AxiosError } from "axios";

export const api = axios.create({
  baseURL: ENV_KERALA_BACKEND,
});

api.interceptors.request.use(
  (config) => {
    const _token = window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
    const token = _token?.replaceAll('"', "");

    if (token) {
      config.headers.Authorization
        ? (config.headers.Authorization = `Bearer ${token}`)
        : (config.headers = { Authorization: `Bearer ${token}` });
    }

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const err: AxiosError = error as AxiosError;
    const url = err.response?.config?.url;

    const routes = [
      "/platform/authentication/login-senha",
      "/platform/authentication/validation-token",
      "/platform/authentication/validation-user-token",
      "/platform/authentication/change-password-with-old",
    ];

    if (url && routes.includes(url)) {
      return Promise.reject(error);
    }

    if (err?.response?.status === 401) {
      localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_USER);
      sessionStorage.clear();
      window.location.href = "/login";
      window.location.reload();
    }

    return Promise.reject(error);
  }
);
