/* eslint-disable curly */
import { useEffect } from "react";
import { v4 } from "uuid";

type ScriptProps = Partial<HTMLScriptElement>;

export interface ScriptSettings {
  insertPosition?: InsertPosition;
  inScope?: "head" | "body";
  shouldUnmount?: boolean;
}

/**
 * Hook to load a script tag into the DOM, default settings { position = "body", shouldUnmount = true, insertPosition = "beforeend" }
 * @param attributes
 * @param options
 * @returns void
 */

export const useScripts = (
  { id = v4(), ...attributes }: ScriptProps,
  options: ScriptSettings
): void => {
  useEffect(() => {
    const hasExistingScript = document.getElementById(id);
    if (hasExistingScript) return;

    const {
      inScope = "body",
      shouldUnmount = true,
      insertPosition = "beforeend",
    } = options;

    const script = window.document.createElement("script");
    script.id = id;
    Object.keys(attributes).forEach((key) => (script[key] = attributes[key]));
    window.document[inScope].insertAdjacentElement(insertPosition, script);

    return () => {
      shouldUnmount && window.document[inScope].removeChild(script);
    };
  }, [attributes, id, options]);
};
