import { ContentItem } from "@molecules";
import { ENV_DISQUS_SHORTNAME, ENV_DISQUS_URL } from "@variables";
import { DiscussionEmbed } from "disqus-react";
import React from "react";
import { useLocation } from "react-router-dom";
import { H3, FaIcon } from "@atomic";
interface DisqusCommentProps {
  title: string;
}

type DisquesConfig = Record<string, string>;

export const DisqusComment: React.FC<DisqusCommentProps> = ({ title }) => {
  const location = useLocation();

  const disqusShortname = String(ENV_DISQUS_SHORTNAME);
  const disqusConfig: DisquesConfig = {
    url: ENV_DISQUS_URL + location.pathname,
    identifier: location.pathname,
    title: title,
    language: "pt_BR",
  };

  if (!disqusShortname || Object.entries(disqusConfig).length === 0) {
    return (
      <ContentItem icon={<FaIcon.Info />} alignCenter active>
        <H3>Ops! Erro interno com o componente de comentários.</H3>
      </ContentItem>
    );
  }

  return (
    <>
      <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
    </>
  );
};
