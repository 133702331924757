import React from "react";
import { FaIcon } from "@atomic";
import {
  LockerWrapperStyled,
  LockerWrapperIconStyled,
} from "./locker-overlay.component.style";

export const LockerOverlay: React.FC = ({ children }) => {
  return (
    <LockerWrapperStyled>
      <LockerWrapperIconStyled>
        <FaIcon.Lock />
      </LockerWrapperIconStyled>
      {children}
    </LockerWrapperStyled>
  );
};
