import {
  Radius,
  Spacing,
  PaddingProps,
  PaddingSizes,
  FontSize,
  Transition,
} from "@constants";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Button } from "../../atomic/button";

interface ContentItemWrapperStyledProps extends PaddingProps {
  alignCenter?: boolean;
  hasHover?: boolean;
  userSelect?: boolean;
  noPadding?: boolean;
  active?: boolean;
}

export const ContentItemWrapperStyled = styled.div<ContentItemWrapperStyledProps>`
  padding: ${(props) =>
    props.padding !== undefined
      ? `${PaddingSizes[props.padding]}`
      : `${Spacing.Small}`};
  ${(props) =>
    props.paddingTop !== undefined &&
    `padding-top: ${PaddingSizes[props.paddingTop]}`};
  ${(props) =>
    props.paddingBottom !== undefined &&
    `padding-bottom: ${PaddingSizes[props.paddingBottom]}`};
  ${(props) =>
    props.paddingRight !== undefined &&
    `padding-right: ${PaddingSizes[props.paddingRight]}`};
  ${(props) =>
    props.paddingLeft !== undefined &&
    `padding-left: ${PaddingSizes[props.paddingLeft]}`};
  ${(props) =>
    props.paddingTopBottom !== undefined &&
    `padding-top: ${PaddingSizes[props.paddingTopBottom]}; padding-bottom: ${
      PaddingSizes[props.paddingTopBottom]
    }`};
  ${(props) =>
    props.paddingLeftRight !== undefined &&
    `padding-left: ${PaddingSizes[props.paddingLeftRight]}; padding-right: ${
      PaddingSizes[props.paddingLeftRight]
    }`};
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  border-radius: ${Radius.Small};
  transition: background-color 0.2s ease-out;
  ${(props) =>
    props.hasHover === true &&
    `
    &:hover {
      background-color: ${props.theme.colors.Gray200};
      cursor: pointer;
      & > span {
        transition: all 0.2s ease-out;
        background-color: ${props.theme.colors.Gray300};
      }
    }
  `}
  ${(props) =>
    props.alignCenter === true &&
    `
    align-items: center;
  `}
  ${(props) =>
    props.noPadding === true &&
    `
    padding: 0;
    border-radius: 0;
  `}
  ${(props) =>
    props.userSelect === false &&
    `
    user-select:none;
  `}

  ${(props) =>
    props.active &&
    css`
      background-color: ${props.theme.colors.Primary}14;

      * {
        color: ${props.theme.colors.Primary};
      }

      & > span {
        background-color: ${props.theme.colors.Primary}26;
      }

      &:hover {
        background-color: ${props.theme.colors.Primary}26;

        * {
          color: ${props.theme.colors.Primary};
        }

        & > span {
          background-color: ${props.theme.colors.Primary}40;
        }
      }
    `};
`;

export const ContentItemLinkStyled = styled(Link)`
  display: block;
  width: 100%;
  padding: 0;
  color: ${(props) => props.theme.colors.Gray700};
`;

export const ExternalContentItemLinkStyled = styled.a`
  color: ${(props) => props.theme.colors.Gray700};

  &:hover {
    cursor: pointer;
  }
`;

export const ContentItemInfosWrapperStyled = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentItemInfosInnerStyled = styled.div<ContentItemWrapperStyledProps>`
  width: 100%;
  ${({ alignCenter }) =>
    alignCenter
      ? `
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > h4 {
      justify-self: center;
    }
  `
      : `align-self: start`}
`;

export const ContentItemButtonWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  height: 100%;
`;

export const ContentItemButtonMoreStyled = styled(Button)`
  border-radius: 50%;
  width: ${Spacing.Small};
  height: ${Spacing.Small};
  padding: ${PaddingSizes.Small};
  background: ${({ theme }) => theme.colors.Gray300};
  transition: all ${Transition.Slow};

  svg {
    font-size: ${FontSize.XLarge};
    color: ${({ theme }) => theme.colors.Gray600};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.Gray600};

    svg {
      color: ${({ theme }) => theme.colors.Gray300};
    }
  }
`;

export const ContentItemButtonActionStyled = styled(Button)`
  margin: 0;
`;

export const ContentItemActionWrapperStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  height: 100%;
  cursor: pointer;
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.colors.Gray200}00 0%,
    ${(props) => props.theme.colors.Gray200} 57.46%,
    ${(props) => props.theme.colors.Gray200} 99.65%
  );
  display: flex;
  align-items: center;
  padding-right: ${Spacing.SemiSmall};
  padding-left: ${Spacing.Medium};
  opacity: 0;
  transition: opacity 0.2s ease-out;
  &:hover {
    opacity: 1;
  }
  & > * {
    width: ${Spacing.SemiSmall};
    height: ${Spacing.SemiSmall};
    color: ${(props) => props.theme.colors.Gray600};
  }
`;
