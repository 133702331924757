import React from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "styled-components";
import { ShimmerStyled, ShimmerWrapperStyled } from "./shimmer.component.style";

interface ShimmerProps {
  width?: string;
  height?: string;
}

export const Shimmer: React.FC<ShimmerProps> = ({ height, width }) => {
  const theme = useTheme();

  return (
    <SkeletonTheme
      color={theme.colors.Gray300}
      highlightColor={theme.colors.Gray200}
    >
      <ShimmerWrapperStyled height={height}>
        <ShimmerStyled width={width} />
      </ShimmerWrapperStyled>
    </SkeletonTheme>
  );
};
