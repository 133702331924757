import { Border, Breakpoint, Spacing } from "@constants";
import styled from "styled-components";

export const HeaderWrapperStyled = styled.header`
  display: flex;
  background-color: ${(props) => props.theme.colors.White};

  padding-top: ${Spacing.Nano};
  padding-bottom: ${Spacing.Nano};

  box-shadow: ${Border.Bottom} ${(props) => props.theme.colors.Gray200};

  @media only screen and (max-width: ${Breakpoint.XSmall}) {
    .container {
      :last-of-type {
        width: 100%;
      }
    }
  }
`;

export const HeaderContentStyled = styled.div``;
