import { useAuthContext } from "@contexts";
import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useQuery,
  QueryKey,
  UseQueryResult,
  UseQueryOptions,
} from "react-query";
import { SearchPath } from ".";

export const getSearchKey = (): QueryKey => "SearchControllerFindAll";

type QueryError = AxiosError<SearchPath[]>;
type QueryOptions = UseQueryOptions<SearchPath[], QueryError>;

export const useGetSearch = (
  options?: QueryOptions
): UseQueryResult<SearchPath[], QueryError> => {
  const { userLocalStorage } = useAuthContext();
  const { all_class_ids } = userLocalStorage;

  const handleGetSearch = async () => {
    try {
      const url = "/platform/search";
      const params = { filter_class_ids_list: all_class_ids.join(",") };
      const { data } = await api.get<SearchPath[]>(url, { params });
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  return useQuery<SearchPath[], QueryError>({
    queryKey: getSearchKey(),
    queryFn: handleGetSearch,
    ...options,
  });
};
