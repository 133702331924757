import { PaddingSizes, Color, Radius, Spacing } from "@constants";
import { getCurrentTheme } from "@utils";
import styled, { CSSObject } from "styled-components";
import { Body, H3 } from "../../atomic/typography";

interface IconType {
  color: string;
}

const isDark = getCurrentTheme() === "dark";

export const ToastStyled: CSSObject = {
  background: isDark ? Color.Black : Color.White,
  borderRadius: Radius.Small,
  border: `1px solid ${isDark ? Color.White+ "4b" : Color.Black + "4b"}`,
  padding: 0,
};

export const ToastBody: CSSObject = {
  margin: 0,
  padding: PaddingSizes.Nano,
};

export const ToastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.XNano};
`;

export const ToastTitle = styled(H3)<IconType>`
  color: ${({ color }) => color && color};
`;

export const ToastIcon = styled.div<IconType>`
  justify-content: center;
  align-items: center;
  display: flex;

  border-radius: ${Radius.Small};

  height: 100%;
  width: 100%;

  background: ${({ color }) => color + 52};
  color: ${({ color }) => color};
`;

export const ToastMessage = styled(Body)`
  color: ${isDark ? Color.Gray300 : Color.Gray700};
  margin-left: 44px;
  padding: 0;
`;

export const ToastCloseButton = styled.div`
  color: ${isDark ? Color.Gray300 : Color.Gray700};
  padding: ${PaddingSizes.Nano};
`;
