import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from "react-query";
import { CreateSavedMaterial, StudentSavedMaterial } from ".";

type MutationError = AxiosError<StudentSavedMaterial>;
type MutationOptions = UseMutationOptions<
  StudentSavedMaterial,
  MutationError,
  CreateSavedMaterial
>;
type MutationReturn = UseMutationResult<
  StudentSavedMaterial,
  MutationError,
  CreateSavedMaterial
>;

export const usePostStudentSavedMaterial = (
  options?: MutationOptions
): MutationReturn => {
  const handlePostStudentSavedMaterial = async (
    bodyData: CreateSavedMaterial
  ) => {
    try {
      const url = "/platform/students/saved-materials";
      const { data } = await api.post<StudentSavedMaterial>(url, bodyData);
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<StudentSavedMaterial, MutationError, CreateSavedMaterial>({
    mutationFn: handlePostStudentSavedMaterial,
    ...options,
  });
};
