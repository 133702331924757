/* eslint-disable curly */
import React from "react";
import ReactPlayer from "react-player/lazy";
import { FaIcon, Hbox, Button, H3, Separator, Shimmer } from "@atomic";
import { useMediaPlayer } from "./media-player.component.data";
import {
  MediaRangeVolumeWrapperStyled,
  MediaProgressBackgroundStyled,
  MediaProgressWrapperStyled,
  MediaControlsWrapperStyled,
  MediaLoadedProgressStyled,
  MediaVolumeButtonStyled,
  MediaRangeVolumeStyled,
  MediaProgressStyled,
  MediaWrapperStyled,
  MediaRateStyled,
  MediaHideStyled,
} from "./media-player.component.style";

interface MediaPlayerProps {
  type: "video" | "audio";
  materialId: number;
  url: string;
  isComplete: (isComplete: boolean) => void;
}

export const MediaPlayer: React.FC<MediaPlayerProps> = ({
  url,
  type,
  isComplete,
  materialId: material_id,
}) => {
  const {
    handle,
    controls,
    isBuffer,
    PlayerRef,
    mediaSpeed,
    setIsBuffer,
    setControls,
    isHideControl,
    handleProgress,
    handleMediaRate,
    secondsToMinutes,
    toggleFullScreen,
    handleHideControl,
    setHandleMediaRate,
    playedToPercentage,
    setChangeTimeMedia,
  } = useMediaPlayer({ material_id });

  return (
    <>
      {!controls.duration && <Shimmer height="56%" width="100%" />}
      <MediaWrapperStyled handle={handle} isHidden={!controls.duration}>
        <ReactPlayer
          ref={PlayerRef}
          url={url}
          width={"100%"}
          height={"100%"}
          muted={controls.isMuted}
          playing={controls.isPlay}
          volume={controls.handleVolume}
          playbackRate={controls.playbackRate}
          onProgress={(state) => isComplete(handleProgress(state))}
          onBuffer={() => setIsBuffer(true)}
          onBufferEnd={() => setIsBuffer(false)}
          config={{
            vimeo: {
              playerOptions: {
                responsive: true,
                controls: false,
              },
            },
            youtube: {
              playerVars: {
                controls: 0,
                showinfo: 0,
                modestbranding: 1,
              },
            },
          }}
          onPause={() =>
            setControls((prevState) => ({ ...prevState, isPlay: false }))
          }
          onPlay={() =>
            setControls((prevState) => ({ ...prevState, isPlay: true }))
          }
          onEnded={() =>
            setControls((prevState) => ({
              ...prevState,
              isPlay: !prevState.isPlay,
            }))
          }
          onDuration={(duration) =>
            setControls((prevState) => ({
              ...prevState,
              duration: duration,
            }))
          }
        />

        <MediaHideStyled
          type={type}
          onMouseEnter={handleHideControl}
          onMouseLeave={handleHideControl}
          onMouseMove={handleHideControl}
          onClick={handleHideControl}
        />
        <MediaControlsWrapperStyled type={type} is_hide={isHideControl}>
          <Button
            kind="callToAction"
            onClick={() =>
              setControls((prevState) => ({
                ...prevState,
                isPlay: !prevState.isPlay,
              }))
            }
          >
            {controls.isPlay ? <FaIcon.Pause /> : <FaIcon.Play />}
          </Button>
          <Separator type="Small" />

          <Hbox.Item>
            <MediaProgressWrapperStyled>
              <MediaProgressStyled
                type="range"
                min={0}
                max={1}
                step={0.001}
                value={controls.progress.played}
                onChange={(e) => setChangeTimeMedia(Number(e.target.value))}
              />
              <MediaProgressBackgroundStyled
                loading={playedToPercentage(controls.progress.played)}
              />
              <MediaLoadedProgressStyled
                buffered={isBuffer}
                loaded={playedToPercentage(controls.progress.loaded)}
              />
            </MediaProgressWrapperStyled>
            <Separator type="Nano" />

            <Hbox v_align={"space-between"}>
              <H3>{secondsToMinutes(controls.progress.playedSeconds)}</H3>
              <H3> {secondsToMinutes(controls.duration)}</H3>
            </Hbox>
          </Hbox.Item>
          <Separator type="Small" />

          <MediaVolumeButtonStyled>
            <MediaRangeVolumeWrapperStyled>
              <MediaRangeVolumeStyled
                type="range"
                min={0}
                max={1}
                step={0.1}
                value={controls.handleVolume}
                onChange={(e) =>
                  setControls((prevState) => ({
                    ...prevState,
                    handleVolume: Number(e.target.value),
                  }))
                }
              />
            </MediaRangeVolumeWrapperStyled>

            <Button
              kind={"callToAction"}
              onClick={() =>
                setControls((prevState) => ({
                  ...prevState,
                  isMuted: !prevState.isMuted,
                }))
              }
            >
              {controls.isMuted ? <FaIcon.VolumeMute /> : <FaIcon.VolumeUp />}
            </Button>
          </MediaVolumeButtonStyled>
          <Separator type="Small" />

          <Button
            kind="callToAction"
            disableActiveState
            onClick={() => {
              setHandleMediaRate((oldState) => {
                if (oldState >= mediaSpeed.length - 1) {
                  return (oldState = 0);
                }
                return oldState + 1;
              });
            }}
          >
            <MediaRateStyled>{mediaSpeed[handleMediaRate]}x </MediaRateStyled>
          </Button>
          {type === "video" && (
            <>
              <Separator type="Small" />
              <Button
                kind="callToAction"
                onClick={toggleFullScreen}
                disableActiveState
              >
                {handle.active ? <FaIcon.Compress /> : <FaIcon.Expand />}
              </Button>
            </>
          )}
        </MediaControlsWrapperStyled>
      </MediaWrapperStyled>
    </>
  );
};
