import styled, { css } from "styled-components";

interface FrameProps {
  expanded?: boolean;
  align?: "center" | "left" | "right";
  paddingSize?: "XNano" | "Nano" | "Small" | "SemiSmall";
  flex?: boolean;
}

export const FrameStyled = styled.div<FrameProps>`
  ${({ theme, expanded, align, paddingSize, flex }) => css`
    width: ${expanded && "100%"};
    padding: ${theme.spacings.SemiSmall};

    ${paddingSize === "XNano" &&
    css`
      padding: ${theme.spacings.XNano};
    `}

    ${paddingSize === "Nano" &&
    css`
      padding: ${theme.spacings.Nano};
    `}

    ${paddingSize === "Small" &&
    css`
      padding: ${theme.spacings.Small};
    `}

    ${paddingSize === "SemiSmall" &&
    css`
      padding: ${theme.spacings.SemiSmall};
    `}

    background-color: ${theme.colors.White};
    border-radius: ${theme.radius.Small};
    box-shadow: ${theme.border.All} ${theme.colors.Gray200};

    text-align: ${align ? align : "left"};

    ${flex &&
    css`
      display: flex !important;
      flex-direction: column;
    `}
  `}
`;
