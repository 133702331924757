import { FontSize } from "@constants";
import styled, { css } from "styled-components";

interface TabStyledProps {
  active?: boolean;
}

const TabStyled = styled.div<TabStyledProps>`
  ${({ theme, active }) => css`
    padding: ${theme.spacings.Small} ${theme.spacings.SemiSmall};

    border-radius: ${theme.radius.Small} ${theme.radius.Small} 0 0;

    color: ${theme.colors.Gray500};

    font-family: ${theme.font.Family};
    font-size: ${FontSize.Medium};
    font-weight: ${theme.font.medium};

    text-decoration: none;
    line-height: ${theme.font.LineHeight.XSmall};

    margin-bottom: -1px;

    cursor: pointer;

    white-space: nowrap;

    &:hover {
      color: ${theme.colors.Gray800};
      background-color: ${theme.colors.Gray100};
    }

    ${active &&
    css`
      color: ${theme.colors.Primary};

      box-shadow: none;
      box-shadow: ${theme.border.Bottom} ${theme.colors.Primary};

      &:hover {
        color: ${theme.colors.Primary};
      }
    `}

    &::first-letter {
      text-transform: uppercase;
    }
  `}
`;

export const Tab = TabStyled;

export const TabsWrapperStyled = styled.div`
  ${({ theme }) => css`
    width: 100%;
    box-shadow: ${theme.border.Bottom} ${theme.colors.Gray200};

    padding-bottom: 1px;

    @media only screen and (max-width: ${theme.breakpoints.XSmall}) {
      overflow-x: auto;
      overflow-y: hidden;
    }
  `}
`;
