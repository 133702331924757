import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from "react-query";
import { DeleteSavedMaterial, ObjectSavedMaterials } from ".";

type MutationVariables = DeleteSavedMaterial & { material_id: number };
type MutationError = AxiosError<ObjectSavedMaterials>;
type MutationOptions = UseMutationOptions<
  ObjectSavedMaterials,
  MutationError,
  MutationVariables
>;
type MutationReturn = UseMutationResult<
  ObjectSavedMaterials,
  MutationError,
  MutationVariables
>;

export const useDeleteStudentSavedMaterial = (
  options?: MutationOptions
): MutationReturn => {
  const handleDeleteStudentSavedMaterial = async (
    variables: MutationVariables
  ) => {
    try {
      const { material_id, ...bodyData } = variables;
      const url = `/platform/students/saved-materials/${material_id}`;

      const { data } = await api.delete<ObjectSavedMaterials>(url, {
        data: bodyData,
      });
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<ObjectSavedMaterials, MutationError, MutationVariables>({
    mutationFn: handleDeleteStudentSavedMaterial,
    ...options,
  });
};
