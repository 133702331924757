import { Radius, Spacing } from "@constants";
import styled from "styled-components";

export const LockerWrapperStyled = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  border-radius: ${Radius.Medium};

  cursor: not-allowed;

  //Overlay styled
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 2;

    background-color: ${(props) => props.theme.colors.Black};
    opacity: 30%;

    border-radius: ${Radius.Medium};
  }
`;

export const LockerWrapperIconStyled = styled.div`
  position: absolute;
  z-index: 3;

  top: ${Spacing.Small};
  right: ${Spacing.Small};

  font-size: ${Spacing.Medium};

  color: ${(props) => props.theme.colors.Primary};

  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
`;
