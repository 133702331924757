interface Greetings {
  morning: string;
  afternoon: string;
  night: string;
}

const getGreetings = (brandName: string | undefined): Greetings => {
  switch (brandName) {
    case "kordsa":
      return {
        morning: "Que bom ter você aqui, %STUDENTNAME%!",
        afternoon: "Que bom ter você aqui, %STUDENTNAME%!",
        night: "Que bom ter você aqui, %STUDENTNAME%!",
      };
    case "mbm":
      return {
        morning: "Excelente dia, %STUDENTNAME%!",
        afternoon: "Excelente tarde, %STUDENTNAME%!",
        night: "Excelente noite, %STUDENTNAME%!",
      };
    case "omni":
      return {
        morning: "Que bom ter você aqui, %STUDENTNAME%!",
        afternoon: "Que bom ter você aqui, %STUDENTNAME%!",
        night: "Que bom ter você aqui, %STUDENTNAME%!",
      };
    case "shinyAcademy":
      return {
        morning: "Que bom ter você aqui, %STUDENTNAME%!",
        afternoon: "Que bom ter você aqui, %STUDENTNAME%!",
        night: "Que bom ter você aqui, %STUDENTNAME%!",
      };
    default:
      return {
        morning: "Bom dia, %STUDENTNAME%!",
        afternoon: "Boa tarde, %STUDENTNAME%!",
        night: "Boa noite, %STUDENTNAME%!",
      };
  }
};

const getPeriodOfTheDay = (): string => {
  const hours = new Date().getHours();

  if (hours >= 18) {
    return "night";
  } else if (hours >= 12) {
    return "afternoon";
  }
  return "morning";
};

export const getSingleGreeting = (
  brandName: string | undefined,
  studentName: string
): string => {
  const singleGreeting: string = getGreetings(brandName)[getPeriodOfTheDay()];
  return singleGreeting.replace("%STUDENTNAME%", studentName);
};
