import React from "react";
import { BiSortAlt2, BiSortDown, BiSortUp } from "react-icons/bi";
import * as Fa from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { HiFolder } from "react-icons/hi";
import { TbCertificate, TbNumbers, TbShare } from "react-icons/tb";

export const FaIcon = {
  AddressCard: (): JSX.Element => <Fa.FaAddressCard />,
  Adjust: (): JSX.Element => <Fa.FaAdjust />,
  AlignLeft: (): JSX.Element => <Fa.FaAlignLeft />,
  AngleDown: (): JSX.Element => <Fa.FaAngleDown />,
  AngleUp: (): JSX.Element => <Fa.FaAngleUp />,
  ArrowLeft: (): JSX.Element => <Fa.FaArrowLeft />,
  ArrowRight: (): JSX.Element => <Fa.FaArrowRight />,
  Bell: (): JSX.Element => <Fa.FaBell />,
  BirthdayCake: (): JSX.Element => <Fa.FaBirthdayCake />,
  Bookmark: (): JSX.Element => <Fa.FaBookmark />,
  BookReader: (): JSX.Element => <Fa.FaBookReader />,
  Brain: (): JSX.Element => <Fa.FaBrain />,
  Briefcase: (): JSX.Element => <Fa.FaBriefcase />,
  Building: (): JSX.Element => <Fa.FaBuilding />,
  Certificate: (): JSX.Element => <TbCertificate style={{ fontSize: "35" }} />,
  ChartLine: (): JSX.Element => <Fa.FaChartLine />,
  Check: (): JSX.Element => <Fa.FaCheck />,
  CheckSquare: (): JSX.Element => <Fa.FaCheckSquare />,
  Compress: (): JSX.Element => <Fa.FaCompress />,
  Download: (): JSX.Element => <Fa.FaDownload />,
  EllipsisV: (): JSX.Element => <Fa.FaEllipsisV />,
  Envelope: (): JSX.Element => <Fa.FaEnvelope />,
  Exclamation: (): JSX.Element => <Fa.FaExclamation />,
  Expand: (): JSX.Element => <Fa.FaExpand />,
  ExternalLinkAlt: (): JSX.Element => <Fa.FaExternalLinkAlt />,
  Eye: (): JSX.Element => <Fa.FaEye />,
  EyeSlash: (): JSX.Element => <Fa.FaEyeSlash />,
  File: (): JSX.Element => <Fa.FaFileAlt />,
  FileImage: (): JSX.Element => <Fa.FaFileImage />,
  FileInvoice: (): JSX.Element => <Fa.FaFileInvoice />,
  Flag: (): JSX.Element => <Fa.FaFlag />,
  Folder: (): JSX.Element => <HiFolder />,
  HeadphonesAlt: (): JSX.Element => <Fa.FaHeadphonesAlt />,
  Image: (): JSX.Element => <Fa.FaImage />,
  Info: (): JSX.Element => <Fa.FaInfo />,
  InfoCircle: (): JSX.Element => <Fa.FaInfoCircle />,
  LinkedIn: (): JSX.Element => <Fa.FaLinkedin />,
  Lock: (): JSX.Element => <Fa.FaLock />,
  More: (): JSX.Element => <FiMoreVertical />,
  Numbers: (): JSX.Element => <TbNumbers />,
  PaperPlaneTop: (): JSX.Element => <Fa.FaPaperPlane />,
  Pause: (): JSX.Element => <Fa.FaPause />,
  PenSquare: (): JSX.Element => <Fa.FaPenSquare />,
  Phone: (): JSX.Element => <Fa.FaPhone />,
  Play: (): JSX.Element => <Fa.FaPlay />,
  QuestionCircle: (): JSX.Element => <Fa.FaQuestionCircle />,
  Route: (): JSX.Element => <Fa.FaRoute />,
  Save: (): JSX.Element => <Fa.FaSave />,
  Search: (): JSX.Element => <Fa.FaSearch />,
  Share: (): JSX.Element => <TbShare />,
  SignOutAlt: (): JSX.Element => <Fa.FaSignOutAlt />,
  Sort: (): JSX.Element => <BiSortAlt2 />,
  SortDown: (): JSX.Element => <BiSortDown />,
  SortUp: (): JSX.Element => <BiSortUp />,
  Suitcase: (): JSX.Element => <Fa.FaSuitcase />,
  Task: (): JSX.Element => <Fa.FaTasks />,
  ThList: (): JSX.Element => <Fa.FaThList />,
  ThumbsUp: (): JSX.Element => <Fa.FaThumbsUp />,
  Times: (): JSX.Element => <Fa.FaTimes />,
  TrashAlt: (): JSX.Element => <Fa.FaTrashAlt />,
  User: (): JSX.Element => <Fa.FaUser />,
  UserGraduate: (): JSX.Element => <Fa.FaUserGraduate />,
  Users: (): JSX.Element => <Fa.FaUsers />,
  Video: (): JSX.Element => <Fa.FaVideo />,
  VolumeMute: (): JSX.Element => <Fa.FaVolumeMute />,
  VolumeUp: (): JSX.Element => <Fa.FaVolumeUp />,
};
