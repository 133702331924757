import React from "react";
import { InternalLinkStyled, ExternalLinkStyled } from "./custom-link.component.style";

interface CustomLinkProps {
  to: string;
  external?: boolean;
  newTab?: boolean;
}

export const CustomLink: React.FC<CustomLinkProps> = ({
  to,
  children,
  external,
  newTab,
}) => {
  const target = newTab ? "_blank" : "self";
  if (external) {
    return (
      <ExternalLinkStyled href={to} target={target}>
        {children}
      </ExternalLinkStyled>
    );
  } else {
    return <InternalLinkStyled to={to}>{children}</InternalLinkStyled>;
  }
};
