import React from "react";
import { useTheme } from "styled-components";
import {
  FillImage,
  ImageSectionStyled,
  ImageWrapper,
} from "./image-fill.component.style";

interface ImageFillI {
  imageUrlLight: string;
  imageUrlDark: string;
}

export const ImageFill: React.FC<ImageFillI> = ({
  imageUrlDark,
  imageUrlLight,
}) => {
  const { title } = useTheme();

  return (
    <ImageWrapper>
      <ImageSectionStyled
        imageUrl={title === "light" ? imageUrlLight : imageUrlDark}
      ></ImageSectionStyled>
      <FillImage />
    </ImageWrapper>
  );
};
