import { FontSize } from "@constants";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const MenuMobileWrapperStyled = styled.div`
  position: relative;

  display: flex;
  align-items: center;
`;

export const MenuMobileButtonStyled = styled.button`
  ${({ theme }) => css`
    width: calc(${theme.spacings.Medium} + ${theme.spacings.Nano});
    height: calc(${theme.spacings.Medium} + ${theme.spacings.Nano});

    background-color: ${theme.colors.White};

    box-shadow: ${theme.border.All} ${theme.colors.Gray200};

    border-radius: ${theme.radius.Small};

    margin-right: ${theme.spacings.SemiSmall};

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    cursor: pointer;

    transition: box-shadow ${theme.transition.Fast} ease-in;

    &:focus-within {
      z-index: 10;
      border-radius: 5px;
      box-shadow: 0 0 0 100000rem #0000005a;
    }
  `}
`;

interface MenuMobileProps {
  open?: boolean;
}

export const MenuMobileButtonLineStyled = styled.span<MenuMobileProps>`
  ${({ theme, open }) => css`
    display: block;
    height: 2px;
    width: ${theme.spacings.SemiSmall};
    background-color: ${theme.colors.Gray700};
    transform-origin: center center;
    transition: 0.2s ease-out;

    &:first-child {
      margin-bottom: ${theme.spacings.XNano};
      transform: rotate(0deg);
    }

    &:last-child {
      margin-top: ${theme.spacings.XNano};
      transform: rotate(0deg);
    }

    ${open &&
    css`
      :first-child {
        margin-bottom: -2px;
        transform: rotate(45deg);
      }
      :last-child {
        margin-top: -2px;
        transform: rotate(-45deg);
      }
      :nth-child(2) {
        opacity: 0;
      }
    `}
  `}
`;

const navigationMaxWidth = "240px";

export const MenuMobileNavigationStyled = styled.div<MenuMobileProps>`
  ${({ theme, open }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;

    background-color: ${theme.colors.White};

    box-shadow: ${theme.border.All} ${theme.colors.Gray200};

    padding: ${theme.spacings.Small};

    border-radius: ${theme.radius.Small};

    opacity: 0;
    visibility: hidden;

    position: absolute;
    z-index: 999;
    top: calc(${theme.spacings.Large} - 1px);

    transition: all 0.2 ease-out;

    @media only screen and (max-width: ${theme.breakpoints.Medium}) {
      width: ${navigationMaxWidth};
    }

    @media only screen and (max-width: ${theme.breakpoints.XSmall}) {
      width: 100%;
    }

    @keyframes openMenu {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    ${open &&
    css`
      visibility: visible;
      animation: openMenu 0.2s ease-out;
      animation-fill-mode: forwards;
    `}
  `}
`;

interface MenuMobileNavigationLinkStyledProps {
  $active?: boolean;
}

export const MenuMobileNavigationLinkStyled = styled(
  Link
)<MenuMobileNavigationLinkStyledProps>`
  ${({ theme, $active }) => css`
    font-family: ${theme.font.Family};
    font-size: ${FontSize.Medium};
    font-weight: ${theme.font.medium};
    line-height: ${theme.font.LineHeight.XSmall};

    padding: ${theme.spacings.SemiSmall};

    border-radius: ${theme.radius.Small};

    text-decoration: none;

    transition: all 0.2 ease-out;

    color: ${theme.colors.Gray700};

    background-color: ${theme.colors.White};

    user-select: none;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: ${theme.colors.Primary};
      background-color: ${theme.colors.Primary}26;
    }

    ${$active &&
    css`
      color: ${theme.colors.Primary};
      background-color: ${theme.colors.Primary}26;
    `};

    svg {
      margin-right: ${theme.spacings.XNano};
    }
  `}
`;

export const MenuMobileSearchBarWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
