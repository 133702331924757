import { envLogoDesktopLight } from "@contexts";
import { ENV_APP_THEME, ENV_EMAIL_SUPPORT, ENV_WEBSITE_NAME } from "@variables";
import React, { FC, useEffect, useRef } from "react";
import { FallbackProps } from "react-error-boundary";
import styles from "./error-fallback.module.css";
import { mailto } from ".";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { default: logo } = require("@assets/" + envLogoDesktopLight);

export const ErrorFallback: FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const detailsRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    return detailsRef.current ? detailsRef.current?.showModal() : undefined;
  }, []);

  const handleLogout = () => {
    window.sessionStorage.clear();
    window.localStorage.clear();
    window.location.reload();
    window.location.href = "/login";
  };

  return (
    <main className={styles.background} role="main">
      <img
        src={`${logo}`}
        className={styles.img}
        alt={`Logo da plataforma de ensino ${ENV_APP_THEME}`}
      />

      <dialog role="alertdialog" ref={detailsRef} className={styles.modal}>
        <h3 className={styles.title}>Sentimos muito! :(</h3>
        <div>
          <p>
            A {ENV_WEBSITE_NAME.toLowerCase()} pede desculpas pelo ocorrido.
          </p>
          <p>
            Estamos trabalhando para resolver o problema o mais rápido possível.
          </p>
        </div>
        <p>Você pode tentar uma dessas opções.</p>
        <div className={styles.button_group}>
          <button className={styles.button} onClick={resetErrorBoundary}>
            Recarregar
          </button>
          <button className={styles.button} onClick={handleLogout}>
            Sair da conta
          </button>
        </div>

        <p>Ajude-nos a solucionar o problema.</p>
        <p>
          Envie um e-mail para{" "}
          <a href={mailto(error)} target="_blank" rel="noreferrer">
            {ENV_EMAIL_SUPPORT}
          </a>
        </p>

        <small>Aqui está o problema que conseguimos detectar.</small>
        <pre>{error.message}</pre>
      </dialog>
    </main>
  );
};
