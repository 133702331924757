import { useAuthContext } from "@contexts";
import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useQuery,
  QueryKey,
  UseQueryResult,
  UseQueryOptions,
} from "react-query";
import { Notification } from ".";

export const getNotificationsKey = (): QueryKey =>
  "NotificationsControllerFindAll";

type QueryError = AxiosError<Notification[]>;
type QueryOptions = UseQueryOptions<Notification[], QueryError>;

export const useGetNotifications = (
  options?: QueryOptions
): UseQueryResult<Notification[], QueryError> => {
  const { class_id } = useAuthContext().userLocalStorage;

  const handleGetNotifications = async () => {
    try {
      const url = "/notifications";
      const params = { class_id };
      const { data } = await api.get<Notification[]>(url, { params });
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  return useQuery<Notification[], QueryError>({
    queryKey: getNotificationsKey(),
    queryFn: handleGetNotifications,
    ...options,
  });
};
