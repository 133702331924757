import { ENV_APP_THEME, ENV_LABEL_MODULE, ENV_LABEL_MODULE_ARTICLE } from "@variables";

export interface ConfigLabel {
  label: string;
  plural: string;
  isMaleWord: boolean;
}

interface UseGetLabelHookInterface {
  getPathLabel: () => ConfigLabel;
}

export const useGetLabelHook = (): UseGetLabelHookInterface => {
  const getPathLabel = (): ConfigLabel => {
    if (ENV_APP_THEME === "mbm") {
      return {
        label: "Módulo/Tema",
        plural: "Módulos/Temas",
        isMaleWord: true,
      };
    } else if (ENV_LABEL_MODULE) {
      return {
        label: ENV_LABEL_MODULE,
        plural: ENV_LABEL_MODULE + "s",
        isMaleWord: ENV_LABEL_MODULE_ARTICLE === "o",
      };
    } else {
      return { label: "Módulo", plural: "Módulos", isMaleWord: true };
    }
  };

  return { getPathLabel };
};
