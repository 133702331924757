import { useLoginGuard } from "@hooks";
import { Footer } from "@molecules";
import { Header } from "@organism";
import React, { lazy, Suspense } from "react";
import {
  Redirect,
  Switch,
  Route,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
// eslint-disable-next-line import/order
import { Hbox, Loader } from "@atomic";

//All pages in application
const RedefinePasswordPage = lazy(
  () => import("../pages/auth/redefine-password")
);
const LearningUnitPage = lazy(() => import("../pages/learning-unit"));
const RegisterPage = lazy(() => import("../pages/auth/register"));
const StaticMessagePage = lazy(() => import("../pages/messages"));
const CategoriesPage = lazy(() => import("../pages/categories"));
const EvolutionPage = lazy(() => import("../pages/evolution"));
const LoginPage = lazy(() => import("../pages/auth/login"));
const MeetingPage = lazy(() => import("../pages/meeting"));
const ContactPage = lazy(() => import("../pages/contact"));
const ProfilePage = lazy(() => import("../pages/profile"));
const AgendaPage = lazy(() => import("../pages/calendar"));
const NotFound = lazy(() => import("../pages/not-found"));
const AdminPage = lazy(() => import("../pages/admin"));
const PathPage = lazy(() => import("../pages/path"));
const HomePage = lazy(() => import("../pages/home"));
const ComunityPage= lazy(() => import("../pages/comunity"));
import { FullHeight } from "./routes.style";

interface RoutesProps {
  toggleTheme: () => void;
}

export const Routes: React.FC<RoutesProps> = ({ toggleTheme }) => {
  return (
    <Switch>
      <Suspense fallback={<Loader size="XXLarge" type="Primary" />}>
        <PublicRoute>
          <Route path="/login" component={LoginRoutes} />
          <Route path="/pre-register" component={RegisterRoutes} />
          <Route path="/staticmessages" component={StaticMessages} />
          <Route path="/reset/password" component={RedefinePasswordPage} />
        </PublicRoute>
        <PrivateRoute>
          <FullHeight>
            <Header toggleAction={toggleTheme} />
            <Hbox.Item>
              <InternalRoutes />
            </Hbox.Item>
            <Footer />
          </FullHeight>
        </PrivateRoute>
      </Suspense>
    </Switch>
  );
};

const LoginRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={"/login"} render={() => <LoginPage type="pass" />} />
      <Route
        path={`${path}/validation-token`}
        render={() => <LoginPage type="token" />}
      />
    </Switch>
  );
};

const RegisterRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}/one`}
        render={() => <RegisterPage step="one" />}
      />
      <Route path={`${path}/two`} render={() => <RegisterPage step="two" />} />
      <Route
        path={`${path}/three`}
        render={() => <RegisterPage step="three" />}
      />
      <Route render={() => <Redirect to="/login" />} />
    </Switch>
  );
};

const InternalRoutes = () => {
  return (
    <Switch>
      <Route path="/" exact component={HomePage} />
      <Route path="/paths" component={PathRoutes} />
      <Route path="/student" component={StudentRoutes} />
      <Route path="/calendar" component={AgendaPage} />
      <Route path="/meetings/:meetingId" component={MeetingPage} />
      <Route path="/contact" component={ContactPage} />
      <Route path="/categories" component={CategoriesRoutes} />
      <Route path="/admin" exact component={AdminPage} />
      <Route path="/reset/password" component={RedefinePasswordPage} />
      <Route path="/comunity" component={ComunityPage} />
      <Route component={NotFound} />
    </Switch>
  );
};

const CategoriesRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:categoryId?`} exact component={CategoriesPage} />
      <Route component={NotFound} />
    </Switch>
  );
};

const PathRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:pathId?`} exact component={PathPage} />
      <Route
        path={`${path}/:pathId?/learning-units/:learningUnitId?`}
        exact
        component={LearningUnitPage}
      />
      <Route
        path={`${path}/:pathId?/learning-units/:learningUnitId/materials/:materialId?`}
        exact
        component={LearningUnitPage}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

const StaticMessages = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/max-users-company`}
        render={() => <StaticMessagePage message="max-users-company" />}
      />
      <Route
        path={`${path}/signup-blocked`}
        render={() => <StaticMessagePage message="signup-blocked" />}
      />
      <Route
        path={`${path}/status-on-hold`}
        render={() => <StaticMessagePage message="status-on-hold" />}
      />
      <Route
        path={`${path}/inactive`}
        render={() => <StaticMessagePage message="inactive" />}
      />
      <Route render={() => <NotFound expands />} />
    </Switch>
  );
};

const StudentRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/evolution`} component={EvolutionPage} />
      <Route path={`${path}/profile/:sectionSlug?`} component={ProfilePage} />
      <Route path={`${path}/profile`} component={ProfilePage} />
      <Route component={NotFound} />
    </Switch>
  );
};

const PrivateRoute: React.FC = ({ children, ...rest }) => {
  const auth = useLoginGuard();
  return (
    <Route
      {...rest}
      render={() => (auth ? children : <Redirect to="/login" />)}
    />
  );
};

const PublicRoute: React.FC = ({ children, ...rest }) => {
  const auth = useLoginGuard();
  const { pathname } = useLocation();

  return (
    <Route
      {...rest}
      render={() => (auth ? <Redirect to={pathname} /> : children)}
    />
  );
};
