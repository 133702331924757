import { Spacing } from "@constants";
import styled from "styled-components";

const SeparatorSize = {
  XNano: Spacing.XNano,
  Nano: Spacing.Nano,
  Small: Spacing.Small,
  SemiSmall: Spacing.SemiSmall,
  Medium: Spacing.Medium,
  SemiLarge: Spacing.SemiLarge,
  Large: Spacing.Large,
  XLarge: Spacing.XLarge,
  XXLarge: Spacing.XXLarge,
};

interface SeparatorProps {
  type?: keyof typeof SeparatorSize;
}

export const Separator = styled.div<SeparatorProps>`
  padding-top: ${(props) =>
    props.type ? SeparatorSize[props.type] : Spacing.SemiSmall};
  padding-left: ${(props) =>
    props.type ? SeparatorSize[props.type] : Spacing.SemiSmall};
`;
