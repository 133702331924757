import {
  FontFamily,
  FontWeight,
  LineHeight,
  FontSize,
  Spacing,
  Color,
} from "@constants";
import styled, { css } from "styled-components";

interface StateIndicatorProps {
  progressBarPercentage?: number;
  size?: "Small" | "Medium";
  notFinishable?: boolean;
  progressBar?: boolean;
  numberMarker?: number;
  completed?: boolean;
  noColor?: boolean;
  single?: boolean;
  active?: boolean;
  marker?: string;
}

const strokeSizeSmall = "2px";
const strokeSizeMedium = "3px";

export const StateIndicatorStyled = styled.div<StateIndicatorProps>`
  min-width: calc(${Spacing.Medium} + ${Spacing.XNano});
  min-height: ${Spacing.Medium};
  border-radius: 50%;

  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";

    width: ${strokeSizeMedium};
    height: calc(${Spacing.Nano} - ${strokeSizeMedium});
    display: block;

    margin-bottom: ${strokeSizeMedium};

    background-color: ${(props) => props.theme.colors.Primary};

    position: absolute;
    top: -${Spacing.Nano};
  }

  &:after {
    content: "";

    width: ${strokeSizeMedium};
    height: 100%;

    display: flex;

    margin-top: ${strokeSizeMedium};
    margin-bottom: -${Spacing.Nano};

    background-color: ${(props) => props.theme.colors.Primary};
  }

  ${(props) =>
    props.single &&
    css`
      &:before {
        background-color: ${Color.Transparent};
      }
      &:after {
        background-color: ${Color.Transparent};
        display: none;
      }
    `}

  ${(props) =>
    props.size === "Small" &&
    css`
      min-width: ${Spacing.SemiSmall};
      min-height: ${Spacing.SemiSmall};

      &:before {
        width: ${strokeSizeSmall};
        height: calc(${Spacing.Nano} - ${strokeSizeSmall});

        margin-bottom: ${strokeSizeSmall};
      }

      &:after {
        width: ${strokeSizeSmall};

        margin-top: ${strokeSizeSmall};
      }
    `}
`;

export const StateIndicatorSVG = styled.svg<StateIndicatorProps>`
  min-width: ${Spacing.Medium};
  width: ${Spacing.Medium};
  min-height: ${Spacing.Medium};
  height: ${Spacing.Medium};

  ${(props) =>
    props.size === "Small" &&
    css`
      min-width: ${Spacing.SemiSmall};
      width: ${Spacing.SemiSmall};
      min-height: ${Spacing.SemiSmall};
      height: ${Spacing.SemiSmall};
    `}
`;

interface StateIndicatorCircle extends StateIndicatorProps {
  strokeDash?: number;
}

export const StateIndicatorCircle = styled.circle<StateIndicatorCircle>`
  pointer-events: none;

  width: 100%;
  height: 100%;

  fill: none;
  stroke-width: ${strokeSizeMedium};
  stroke: ${(props) => props.theme.colors.Primary};

  position: absolute;
  z-index: 8;

  ${(props) =>
    props.progressBar &&
    css`
      &:first-of-type {
        opacity: 0.15;
      }

      &:last-of-type {
        stroke-dasharray: calc(${props.strokeDash}px * 2);

        stroke-dashoffset: ${props.progressBarPercentage !== undefined
          ? `calc(${props.strokeDash}px + (${props.strokeDash}px * (100 - ${props.progressBarPercentage}) / 100))`
          : `calc(${props.strokeDash}px * 2)`};

        transform-origin: center center;

        transform: rotate(-90deg);
      }
    `}
`;

export const StateIndicatorActiveStyled = styled.circle<StateIndicatorProps>`
  width: 100%;
  height: 100%;

  fill: ${({ theme, noColor }) =>
    noColor ? theme.colors.BrandContrast : theme.colors.Primary};

  transform-origin: center center;
  transform: scale(0.75);

  opacity: 0;

  transition: all 0.3s ease-out;

  position: absolute;

  ${(props) =>
    props.active &&
    css`
      transform: scale(1);

      opacity: 1;

      transition: all 0.3s ease-out;
    `}
`;

export const StateIndicatorCompletedStyled = styled.div<StateIndicatorProps>`
  position: absolute;
  z-index: 9;
  top: 0;

  min-width: ${Spacing.Medium};
  min-height: ${Spacing.Medium};

  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.colors.Primary};

  transform: scale(0.6);

  opacity: 0;

  transition: all 0.3s ease-out;

  & > * {
    display: block;

    width: ${Spacing.Small};
    height: ${Spacing.Small};
  }

  ${(props) =>
    props.completed &&
    `
      transform: scale(1);

      opacity: 1;

      transition: all 0.3s ease-out;
    `}

  ${(props) =>
    props.active &&
    `opacity: 0;

     transition: all 0.3s ease-out;
    `}

${(props) =>
    props.size === "Small" &&
    css`
      min-width: ${Spacing.SemiSmall};
      min-height: ${Spacing.SemiSmall};

      & > * {
        width: ${Spacing.Nano};
        height: ${Spacing.Nano};
      }
    `}
`;

export const StateIndicatorMarkerStyled = styled.h5<StateIndicatorProps>`
  font-size: ${FontSize.Medium};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};

  line-height: ${LineHeight.XSmall};

  color: ${(props) => props.theme.colors.Primary};

  position: absolute;
  top: 0;

  min-width: ${Spacing.Medium};
  min-height: ${Spacing.Medium};

  ${(props) =>
    props.size === "Small" &&
    css`
      min-width: ${Spacing.SemiSmall};
      min-height: ${Spacing.SemiSmall};
    `}

  display: flex;
  justify-content: center;
  align-items: center;

  transform: scale(1);

  transition: all 0.3s ease-out;

  ${(props) =>
    props.marker !== undefined &&
    css`
      transform: scale(1);

      transition: all 0.3s ease-out;
    `}

  ${(props) =>
    props.numberMarker !== undefined &&
    css`
      transform: scale(1);

      transition: all 0.3s ease-out;
    `}

    ${(props) =>
    props.notFinishable !== undefined &&
    css`
      display: none;
      opacity: 0;
    `}
`;
