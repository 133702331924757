import styled, { css, keyframes } from "styled-components";
import { Button } from "../../atomic/button";
import { H4 } from "../../atomic/typography";
import { ContentItem } from "../content-item/content-item.component";

interface NotificationIconProps {
  hasNotifications: boolean;
  isClicked: boolean;
}

export const NotificationButtonStyled = styled(Button)<NotificationIconProps>`
  ${({ theme, hasNotifications, isClicked }) => css`
    padding: ${theme.spacings.Nano}; 
    color: ${theme.colors.Gray700};
    background-color: ${theme.colors.White};

    ${
      isClicked &&
      css`
        background-color: ${theme.colors.Gray200};
        color: ${theme.colors.Primary};

        &::before {
          content: "";
          position: absolute;
          bottom: -30px;
          right: 10px;
          width: 20px;
          height: 20px;
          transform: rotate(45deg);
          background: ${theme.colors.White};
        }
      `
    }

    ${
      hasNotifications &&
      css`
        &::after {
          content: "";
          position: absolute;
          right: 0.4735rem;
          top: 0.3125rem;
          width: 0.375rem;
          height: 0.375rem;
          border-radius: 50%;

          background: ${theme.colors.Error};

          animation: ${HasNotificationAnimation} 1s ease-in-out normal;
        }
      `
    }

    &:hover {
      background-color: ${theme.colors.Gray200};
      color: ${theme.colors.Primary};
    }

    svg {
      display: block;
      width: ${theme.spacings.SemiSmall} !important;
      height: ${theme.spacings.SemiSmall};
      ${
        hasNotifications &&
        css`
          animation: ${BellAnimation} 1.5s linear normal;
        `
      }
  `}
`;

export const NotificationsWrapperStyled = styled.div`
  ${({ theme }) => css`
    transition: box-shadow ${theme.transition.Fast} ease-in;

    &:focus-within {
      z-index: 10;
      border-radius: 5px;
      box-shadow: 0 0 0 100000rem #0000005a;
    }
  `}
`;

export const NotificationsDropdownHeaderWrapperStyled = styled.header`
  ${({ theme }) => css`
    box-shadow: ${theme.border.Bottom} ${theme.colors.Gray200};
    padding: ${theme.spacings.Small} ${theme.spacings.Nano};
    padding-bottom: ${theme.spacings.SemiSmall};
    margin: 0 ${theme.spacings.Small};

    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;

export const NotificationsDropdownFooterWrapperStyled = styled.footer`
  ${({ theme }) => css`
    flex: 1;

    box-shadow: ${theme.border.Top} ${theme.colors.Gray200};

    margin: 0 ${theme.spacings.Small};

    button {
      margin-top: ${theme.spacings.XNano};
    }
  `}
`;

export const NotificationsListWrapperStyled = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.Nano} 0;

    & > * {
      margin-top: ${theme.spacings.XNano};
    }

    & *:first-of-type {
      margin-top: 0;
    }
  `}
`;

export const NotificationContent = styled(ContentItem)`
  & > div {
    max-width: 24rem;
  }
`;

export const NotificationText = styled(H4)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const HasNotificationAnimation = keyframes`
  0% {
    top: 25px;
    right: 17px;
    opacity: 0;
  }
    
  50% {
    right: 10px;
    opacity: .5;
  }
  
  100% {
    right: 0.4735rem;   
    opacity: 1;
  }
`;

const BellAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(30deg);
  }

  20% {
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(-30deg);
  }

  40% {
    transform: rotate(0deg);
  }
    
  50% {
   transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }
  
  70% {
    transform: rotate(-10deg);    
  }

  100% {
    transform: rotate(0);
  }
`;
