import { Spacing } from "@constants";
import styled from "styled-components";

interface SwitchStyledProps {
  checked: boolean;
  disabled?: boolean;
}

export const SwitchStyled = styled.span<SwitchStyledProps>`
  display: flex;
  align-items: center;

  width: calc(${Spacing.Medium} + ${Spacing.XNano});
  height: ${Spacing.SemiSmall};

  border-radius: ${Spacing.Nano};
  cursor: pointer;

  background-color: ${(props) => props.theme.colors.Gray300};
  position: relative;

  transition: all 0.2s ease-out;

  ${(props) =>
    props.checked &&
    `
    background-color: ${props.theme.colors.Primary}26 !important;
  `}

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
  `}
`;

export const SwitchMarkerStyled = styled.span<SwitchStyledProps>`
  position: absolute;
  ${(props) => (props.checked ? "right: 0 " : "left: 0")};

  height: calc(${Spacing.SemiSmall} * 0.6);
  width: calc(${Spacing.SemiSmall} * 0.6);

  margin: calc(${Spacing.XNano} * 0.8);

  border-radius: 50%;

  background-color: ${(props) => props.theme.colors.Gray500};

  transition: all 0.2s ease-out;

  ${(props) =>
    props.checked &&
    `
      background-color: ${props.theme.colors.Primary} !important;
      left: auto;
    `}

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
  `}
`;
