import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useQuery,
  QueryKey,
  UseQueryResult,
  UseQueryOptions,
} from "react-query";
import { StudentCertificateHistory } from ".";

export const getStudentCertificatesKey = (): QueryKey =>
  "CertificatesControllerFindAllStudentCertificates";

type QueryError = AxiosError<StudentCertificateHistory[]>;
type QueryOptions = UseQueryOptions<StudentCertificateHistory[], QueryError>;

export const useGetStudentCertificates = (
  options?: QueryOptions
): UseQueryResult<StudentCertificateHistory[], QueryError> => {
  const handleGetStudentCertificates = async () => {
    try {
      const url = "/platform/students/certificates";
      const { data } = await api.get<StudentCertificateHistory[]>(url);
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  return useQuery<StudentCertificateHistory[], QueryError>({
    queryKey: getStudentCertificatesKey(),
    queryFn: handleGetStudentCertificates,
    ...options,
  });
};
