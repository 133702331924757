import { Spacing } from "@constants";
import styled from "styled-components";

export const ProgressBarBorderStyled = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.Primary}33;
  height: ${Spacing.XNano};
`;

interface ProgressBarContentProps {
  progress?: number;
}

export const ProgressBarContentStyled = styled.div<ProgressBarContentProps>`
  height: 100%;
  width: ${(props) => (props.progress ? props.progress : 0)}%;
  max-width: 100%;
  background-color: ${(props) => props.theme.colors.Primary};
  border-radius: inherit;
`;
