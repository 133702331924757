import styled, { css } from "styled-components";

interface ActionButtonStyledProps {
  active?: boolean;
}

export const ActionButtonWrapperStyled = styled.button<ActionButtonStyledProps>`
  ${({ theme, active }) => css`
    position: absolute;
    top: ${theme.spacings.Small};
    right: ${theme.spacings.Small};
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    width: ${theme.spacings.Medium};
    height: ${theme.spacings.Medium};

    border: none;
    box-shadow: none;
    border-radius: 50%;

    background-color: ${theme.colors.Transparent};

    cursor: pointer;

    transform-origin: center;
    transition: all 0 ${theme.transition.Slow} ease-out;

    & > * {
      width: 50%;
      height: 50%;
      color: ${active ? theme.colors.Primary : theme.colors.Gray600};
    }

    &:hover {
      background-color: ${theme.colors.Gray300};
    }

    &:active {
      background-color: ${theme.colors.Gray400};
    }

    @media only screen and (max-width: ${theme.breakpoints.XSmall}) {
      top: ${theme.spacings.XNano};
      right: 0;
    }
  `}
`;
