import { useAuthContext } from "@contexts";
import Identicon, { IdenticonOptions } from "identicon.js";
import { useTheme } from "styled-components";

export const GenerateDefaultImage = (): string => {
  const { colors } = useTheme();
  const { userLocalStorage } = useAuthContext();

  let hashUser = `${userLocalStorage?.social_name || userLocalStorage?.name} ${
    userLocalStorage?.last_name
  }`;

  //Caso hashUser seja menor que 15, adiciona a letra 'a'
  if (hashUser.length < 15) {
    const hashLength = 15 - hashUser.length;
    hashLength > 0 && (hashUser += new Array(hashLength).fill("a").join(""));
  }

  //Converte hash de ASCII(utf-8) para string hexadecimal
  const AsciiToHexString = (hash: string) =>
    hash
      .split("")
      .reduce(
        (hex, c) => (hex += c.charCodeAt(0).toString(16).padStart(2, "0")),
        ""
      );

  //Converte hexadecimal para rgb
  const hexToRgb = (hex: any) =>
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));

  const options = {
    foreground: hexToRgb(colors.Primary),
    background: [0, 0, 0, 0],
    margin: 0,
    size: 420,
    format: "svg",
  } as IdenticonOptions;

  // create a base64 encoded SVG
  const data = new Identicon(AsciiToHexString(hashUser), options).toString();

  return `data:image/svg+xml;base64,${data}`;
};
