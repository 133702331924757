import { useField } from "@unform/core";
import React, {
  FC,
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { TextareaStyled } from "./textarea.component.style";

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
}

export const Textarea: FC<TextareaProps> = ({ name, ...rest }) => {
  const textareaRef = useRef() as React.MutableRefObject<HTMLTextAreaElement>;

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: "value",
    });
  }, [fieldName, textareaRef, registerField]);

  const handleClick = useCallback(() => {
    textareaRef.current?.focus();
  }, []);

  return <TextareaStyled ref={textareaRef} onClick={handleClick} {...rest} />;
};
