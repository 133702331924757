/* eslint-disable curly */
import { useConfigContext } from "@contexts";
import {
  SearchPathDto,
  SearchMaterialsDto,
  SearchLearningUnitsDto,
} from "@interfaces/kerala-api";
import { useGetSearch } from "@services";
import { FormHandles } from "@unform/core";
import { AxiosError } from "axios";
import { useRef, useState, RefObject, ChangeEvent, useCallback } from "react";

interface ResultsI {
  materials?: SearchMaterialsDto & { type: string };
  path?: Omit<SearchPathDto, "learning_units"> & { type: string };
  learning_units?: Omit<SearchLearningUnitsDto, "materials"> & { type: string };
}

interface SearchBarI {
  error: AxiosError<SearchPathDto[]> | null;
  results: ResultsI[];
  isLoading: boolean;
  refSearch: RefObject<FormHandles>;
  handleReset: () => void;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const useSearchBar = (): SearchBarI => {
  const refSearch = useRef<FormHandles>(null);
  const [results, setResults] = useState<ResultsI[]>([]);
  const { getPathLabel } = useConfigContext();
  const { label } = getPathLabel();

  const { data, isLoading, error } = useGetSearch({
    staleTime: 10 * 60 * 1000, //10 minutos,
  });

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setResults([]);
      const paths = data;
      const value = event.target.value;

      if (!paths || !value.length) {
        return setResults([]);
      }

      const auxResult: ResultsI[] = [];
      const expressao = new RegExp(value, "i");

      paths.forEach(({ learning_units, ...path }) => {
        if (expressao.test(path.name)) {
          auxResult.push({
            path: { ...path, type: label },
          });
        }

        learning_units?.forEach(({ materials, ...learning_unit }) => {
          if (expressao.test(learning_unit.name)) {
            auxResult.push({
              learning_units: {
                type: "Unidade",
                ...learning_unit,
              },
            });
          }

          materials?.forEach((material) => {
            if (expressao.test(material.name)) {
              auxResult.push({
                materials: {
                  type: "Conteúdo",
                  ...material,
                },
              });
            }
          });
        });
      });

      if (!auxResult.length) {
        auxResult.push({
          path: {
            _id: 0,
            type: "Não achamos nada :(",
            name: "Infelizmente a busca em nossa base não encontrou resultados.",
          },
        });
      }

      return setResults(
        auxResult.sort((a, b) => {
          if (a.path && b.learning_units) return -1;

          if (a.path && b.materials) return -1;

          if (a.learning_units && b.path) return 1;

          if (a.learning_units && b.materials) return -1;

          if (a.materials && b.path) return 1;

          if (a.materials && b.learning_units) return 1;

          return 1;
        })
      );
    },
    [label, data]
  );

  const handleReset = useCallback(() => {
    refSearch.current?.reset();
    setResults([]);
  }, []);

  return {
    error,
    results,
    isLoading,
    refSearch,
    handleReset,
    handleChangeInput,
  };
};
