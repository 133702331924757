import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useQuery,
  QueryKey,
  UseQueryResult,
  UseQueryOptions,
} from "react-query";
import { RegisterValuesForm } from ".";

export const getAuthenticationLabelsRegisterKey = (): QueryKey =>
  "AuthenticationControllerFindAllLabelsForms";

type QueryError = AxiosError<RegisterValuesForm>;
type QueryOptions = UseQueryOptions<RegisterValuesForm, QueryError>;

export const useGetAuthenticationLabelsRegister = (
  options?: QueryOptions
): UseQueryResult<RegisterValuesForm, QueryError> => {
  const handleGetAuthenticationLabelsRegister = async () => {
    try {
      const url = "/platform/authentication/labels-register";
      const { data } = await api.get<RegisterValuesForm>(url);
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  return useQuery<RegisterValuesForm, QueryError>({
    queryKey: getAuthenticationLabelsRegisterKey(),
    queryFn: handleGetAuthenticationLabelsRegister,
    ...options,
  });
};
