/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { useClickOutside } from "@hooks";
import React, {
  useImperativeHandle,
  MutableRefObject,
  useCallback,
  forwardRef,
  useEffect,
  useState,
  useRef,
} from "react";
import { CloseIcon } from "@assets/icons/close-icon";
import { Grid, Row, Col } from "@atomic";
import {
  ModalHeaderLabelStyled,
  ModalOverlayStyled,
  CloseButtonStyled,
  ModalHeaderStyled,
  ModalFrameStyled,
  ModalBodyStyled,
} from "./modal.component.style";

interface ModalProps {
  title: string;
  modalIsOpen?: boolean;
  setCloseModal?: (closeModal: boolean) => void;
  disabledClickOutside?: boolean;
}

export interface ModalHandles {
  openModal: () => void;
}

export const Modal = forwardRef<
  ModalHandles,
  React.PropsWithChildren<ModalProps>
>(
  (
    { title, children, modalIsOpen, setCloseModal, disabledClickOutside },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const ModalOverlayRef = useRef() as MutableRefObject<HTMLDivElement>;
    useClickOutside(ModalOverlayRef, () =>
      disabledClickOutside ? null : handleCloseModal()
    );

    const openModal = useCallback(() => {
      setOpen(true);
    }, []);

    const handleCloseModal = useCallback(() => {
      setOpen(false);
      {
        setCloseModal && setCloseModal(true);
      }
    }, [setCloseModal]);

    useImperativeHandle(ref, () => {
      return {
        openModal,
      };
    });

    useEffect(() => setOpen(!!modalIsOpen), [modalIsOpen]);

    return (
      <ModalOverlayStyled isOpen={open}>
        <Grid>
          <Row h_align="center">
            <Col xs={12} sm={10} md={9} lg={7} xl={7}>
              <ModalFrameStyled
                type="secondary"
                noPadding
                ref={ModalOverlayRef}
              >
                <ModalHeaderStyled>
                  <ModalHeaderLabelStyled>
                    {title}
                    <CloseButtonStyled onClick={handleCloseModal}>
                      <CloseIcon />
                    </CloseButtonStyled>
                  </ModalHeaderLabelStyled>
                </ModalHeaderStyled>

                <ModalBodyStyled>{children}</ModalBodyStyled>
              </ModalFrameStyled>
            </Col>
          </Row>
        </Grid>
      </ModalOverlayStyled>
    );
  }
);
