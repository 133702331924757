import { api } from "@utils";
import { AxiosError } from "axios";
import {
  MutationKey,
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from "react-query";
import { AuthenticationStudentToken } from ".";

export type UserJWT = Record<"access_token", string>;

type MutationError = AxiosError<UserJWT>;
type MutationOptions = UseMutationOptions<
  UserJWT,
  MutationError,
  AuthenticationStudentToken
>;
type MutationReturn = UseMutationResult<
  UserJWT,
  MutationError,
  AuthenticationStudentToken
>;

export const postAuthenticationValidationUserTokenKey = (): MutationKey =>
  "AuthenticationControllerValidateWithToken";

export const usePostAuthenticationValidationUserToken = (
  options?: MutationOptions
): MutationReturn => {
  const handlePostAuthenticationValidationUserToken = async (
    bodyData: AuthenticationStudentToken
  ) => {
    try {
      const url = "/platform/authentication/validation-user-token";
      const { data } = await api.post<UserJWT>(url, bodyData);
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<UserJWT, MutationError, AuthenticationStudentToken>({
    mutationKey: postAuthenticationValidationUserTokenKey(),
    mutationFn: handlePostAuthenticationValidationUserToken,
    ...options,
  });
};
