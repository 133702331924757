import { ENV_APP_THEME } from "./variables.config";

interface BrandColors {
  Primary100: string;
  Primary200: string;
  Secondary100: string;
  Secondary200: string;
  BrandContrast100: string;
  BrandContrast200: string;
}
type BrandThemes = Record<string, BrandColors>;

interface BrandLogoStructure {
  desktop: { light: string; dark: string }; //Logo interno do Header no desktop
  mobile: { light: string; dark: string }; //Logo interno do Header no mobile
  default: { light: string; dark: string }; //Logo usado no Login Desktop/Mobile
}
type BrandLogos = Record<string, BrandLogoStructure>;

const themes: BrandThemes = {
  albertoCouto: {
    Primary100: "#0040b3",
    Primary200: "#0040b3",
    Secondary100: "#52c5d0",
    Secondary200: "#52c5d0",
    BrandContrast100: "#FFFFFF",
    BrandContrast200: "#FFFFFF",
  },
  atmo: {
    Primary100: "#017ded",
    Primary200: "#017ded",
    Secondary100: "#73ee1a",
    Secondary200: "#73ee1a",
    BrandContrast100: "#000",
    BrandContrast200: "#FFF",
  },
  default: {
    Primary100: "#1FC3FF",
    Primary200: "#0B7BF2",
    Secondary100: "#FFA33A",
    Secondary200: "#FA8909",
    BrandContrast100: "#000",
    BrandContrast200: "#000 ",
  },
  eskala: {
    Primary100: "#FFB500",
    Primary200: "#FFB500",
    Secondary100: "#494F56",
    Secondary200: "#494F56",
    BrandContrast100: "#000",
    BrandContrast200: "#000 ",
  },
  gentelab: {
    Primary100: "#1FC3FF",
    Primary200: "#0B7BF2",
    Secondary100: "#FFA33A",
    Secondary200: "#FA8909",
    BrandContrast100: "#000",
    BrandContrast200: "#000 ",
  },
  kordsa: {
    Primary100: "#2e3093",
    Primary200: "#2e3093",
    Secondary100: "#2e3093",
    Secondary200: "#f36220",
    BrandContrast100: "#FFFFFF",
    BrandContrast200: "#FFFFFF ",
  },
  mbm: {
    Primary100: "#D33B3B",
    Primary200: "#8C1515",
    Secondary100: "#D33B3B",
    Secondary200: "#8C1515",
    BrandContrast100: "#FFFFFF",
    BrandContrast200: "#FFFFFF ",
  },
  omni: {
    Primary100: "#D9D9D9",
    Primary200: "#003569",
    Secondary100: "#D9D9D9",
    Secondary200: "#D9D9D9",
    BrandContrast100: "#FFFFFF",
    BrandContrast200: "#000000 ",
  },
  shinyAcademy: {
    Primary100: "#AA8AEA",
    Primary200: "#000000",
    Secondary100: "#6e2eed",
    Secondary200: "#5E17EB",
    BrandContrast100: "#FFFFFF",
    BrandContrast200: "#FFFFFF ",
  },
};

const logos: BrandLogos = {
  albertoCouto: {
    desktop: {
      light: "img-alberto-couto.png",
      dark: "img-alberto-couto.png",
    },
    mobile: {
      light: "img-alberto-couto.png",
      dark: "img-alberto-couto.png",
    },
    default: {
      light: "img-alberto-couto.png",
      dark: "img-alberto-couto.png",
    },
  },
  atmo: {
    desktop: {
      light: "img-atmo-light.png",
      dark: "img-atmo-dark.png",
    },
    mobile: {
      light: "img-atmo-light.png",
      dark: "img-atmo-dark.png",
    },
    default: {
      light: "img-atmo-light.png",
      dark: "img-atmo-dark.png",
    },
  },
  default: {
    desktop: {
      light: "img-gente-icon-light.png",
      dark: "img-gente-icon-dark.png",
    },
    mobile: {
      light: "img-gente-icon-light.png",
      dark: "img-gente-icon-dark.png",
    },
    default: {
      light: "img-gente.png",
      dark: "img-gente.png",
    },
  },
  eskala: {
    desktop: {
      light: "img-eskala-light.png",
      dark: "img-eskala-dark.png",
    },
    mobile: {
      light: "img-eskala-light.png",
      dark: "img-eskala-dark.png",
    },
    default: {
      light: "img-eskala-light.png",
      dark: "img-eskala-dark.png",
    },
  },
  gentelab: {
    desktop: {
      light: "img-gente-icon-light.png",
      dark: "img-gente-icon-dark.png",
    },
    mobile: {
      light: "img-gente-icon-light.png",
      dark: "img-gente-icon-dark.png",
    },
    default: {
      light: "img-gente.png",
      dark: "img-gente.png",
    },
  },
  kordsa: {
    desktop: {
      light: "img-unikordsa.png",
      dark: "img-unikordsa.png",
    },
    mobile: {
      light: "img-unikordsa.png",
      dark: "img-unikordsa.png",
    },
    default: {
      light: "img-unikordsa.png",
      dark: "img-unikordsa.png",
    },
  },
  mbm: {
    desktop: {
      light: "img-mbm.png",
      dark: "img-mbm.png",
    },
    mobile: {
      light: "img-mbm.png",
      dark: "img-mbm.png",
    },
    default: {
      light: "img-mbm.png",
      dark: "img-mbm.png",
    },
  },
  omni: {
    desktop: {
      light: "img-omni-light.png",
      dark: "img-omni-dark.png",
    },
    mobile: {
      light: "img-omni-mobile-light.png",
      dark: "img-omni-mobile-dark.png",
    },
    default: {
      light: "img-omni-light.png",
      dark: "img-omni-dark.png",
    },
  },
  shinyAcademy: {
    desktop: {
      light: "img-shiny-academy-light.png",
      dark: "img-shiny-academy-dark.png",
    },
    mobile: {
      light: "img-shiny-academy-light.png",
      dark: "img-shiny-academy-dark.png",
    },
    default: {
      light: "img-shiny-academy-light.png",
      dark: "img-shiny-academy-dark.png",
    },
  },
};

export const envBrandColor = themes[ENV_APP_THEME];

export const envLogoDesktopLight = logos[ENV_APP_THEME].desktop.light;
export const envLogoDesktopDark = logos[ENV_APP_THEME].desktop.dark;

export const envLogoMobileLight = logos[ENV_APP_THEME].mobile.light;
export const envLogoMobileDark = logos[ENV_APP_THEME].mobile.dark;

export const envLogoLight = logos[ENV_APP_THEME].default.light;
export const envLogoDark = logos[ENV_APP_THEME].default.dark;
