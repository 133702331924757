import { useEffect, useRef, useState } from "react";

interface UseTimerProps {
  initialTimer: number;
  onTimerEnd?: () => void;
}

type UseTimerReturn = readonly [
  number,
  React.Dispatch<React.SetStateAction<boolean>>
];

export const useTimer = (props: UseTimerProps): UseTimerReturn => {
  const { initialTimer, onTimerEnd } = props;

  const [timer, setTimer] = useState<number>(initialTimer);
  const [startTimer, setStartTimer] = useState<boolean>(false);

  const intervalId = useRef<any>(null);

  useEffect(() => {
    startTimer && setTimer(initialTimer);
  }, [initialTimer, startTimer]);

  useEffect(() => {
    if (!startTimer) {
      return;
    }

    intervalId.current = setInterval(() => {
      setTimer((prevState) => prevState - 1);
    }, 1000);

    return () => clearInterval(intervalId.current);
  }, [startTimer]);

  useEffect(() => {
    if (timer <= 0) {
      clearInterval(intervalId.current);
      onTimerEnd ? onTimerEnd() : null;
    }
  }, [timer, onTimerEnd]);

  return [timer, setStartTimer] as const;
};
