import React, {
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useState,
} from "react";
import { Separator } from "../separator";
import {
  ButtonWrapperStyledProps,
  ButtonContentStyled,
  ButtonWrapperStyled,
  ButtonKind,
  ResponsiveButtonTextStyled,
  LoadingSpinStyled,
} from "./button.component.style";

interface ButtonProps extends ButtonWrapperStyledProps {
  href?: string;
  onClick?: (param: any) => void;
  type?: "button" | "submit" | "reset";
  kind: keyof typeof ButtonKind | "callToAction";
  loading?: boolean;
  textAlign?: string;
  disabled?: boolean;
  newTab?: boolean;
  active?: boolean;
  activeText?: string;
  disableActiveState?: boolean;
  icon?: ReactNode;
  iconPosition?: "left" | "right";
}

export const ResponsiveButtonText = ResponsiveButtonTextStyled;

export const Button = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>((props, ref) => {
  const { loading, icon, iconPosition = "left", ...other } = props;
  const [active, setActive] = useState(false);

  if (props.href && !props.disabled) {
    return (
      <ButtonWrapperStyled {...other}>{props.children}</ButtonWrapperStyled>
    );
  }

  return (
    <ButtonWrapperStyled
      ref={ref}
      {...other}
      active={active}
      disabled={loading || props.disabled}
      disableActiveState={props.disableActiveState}
      onMouseUp={() => setActive((prev) => !prev)}
    >
      <LoadingSpinStyled kind={props.kind} processing={loading} />
      <ButtonContentStyled processing={loading}>
        {iconPosition === "left" && (
          <>
            {!!icon && icon}
            {!!icon && <Separator type="Nano" />}
          </>
        )}

        {props.activeText !== undefined && active === true
          ? props.activeText
          : props.children}

        {iconPosition === "right" && (
          <>
            {!!icon && <Separator type="Nano" />}
            {!!icon && icon}
          </>
        )}
      </ButtonContentStyled>
    </ButtonWrapperStyled>
  );
});
