import { MutableRefObject, useEffect } from "react";

export const useClickOutside = (
  ref: MutableRefObject<HTMLDivElement>,
  handler: () => void
): void => {
  useEffect(() => {
    const maybeHandler = (event: any) => {
      if (!ref.current || ref.current?.contains(event.target as Node)) {
        return;
      }
      handler();
    };

    document.addEventListener("mousedown", maybeHandler);
    document.addEventListener("touchstart", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
      document.removeEventListener("touchstart", maybeHandler);
    };
  }, [ref, handler]);
};
