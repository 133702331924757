//CONFIGS VARIÁVEIS DA PLATAFORMA
export const ENV_DEVELOPMENT = process.env.REACT_APP_DEVELOPMENT === "true";

export const ENV_AGREE_POLICY_URL = process.env.REACT_APP_AGREE_POLICY_URL || "https://gentelab.s3-sa-east-1.amazonaws.com/arquivos/tech/Pol%C3%ADtica+de+Privacidade_Gentelab.pdf";

export const ENV_AGREE_TERMS_URL = process.env.REACT_APP_AGREE_TERMS_URL || "https://gentelab.s3-sa-east-1.amazonaws.com/arquivos/tech/Termos+de+Uso_GenteLab.pdf";

export const ENV_KERALA_BACKEND = process.env.REACT_APP_KERALA_BACKEND || "http://localhost:3001";

export const ENV_EMAIL_SUPPORT = process.env.REACT_APP_EMAIL_SUPPORT || "ajuda@gentelab.com.br";

export const ENV_PLATFORM_AUTH_KEY = process.env.REACT_APP_PLATFORM_AUTH_KEY || "email";

export const ENV_UNLOCK_SIGNUP = process.env.REACT_APP_UNLOCK_SIGNUP === "true";

export const ENV_DISABLE_LOGIN = process.env.REACT_APP_DISABLE_LOGIN === "true";

export const ENV_DISQUS_SHORTNAME = process.env.REACT_APP_DISQUS_SHORTNAME || "";

export const ENV_DISQUS_URL = process.env.REACT_APP_DISQUS_URL || "";

export const ENV_LABEL_MODULE_ARTICLE = process.env.REACT_APP_LABEL_MODULE_ARTICLE || "o";

export const ENV_LABEL_MODULE = process.env.REACT_APP_LABEL_MODULE || "Módulo";

export const ENV_SEGMENTATION_CONTENT = process.env.REACT_APP_SEGMENTATION_CONTENT || "turmas";

export const ENV_TYPE_CARD = process.env.REACT_APP_TYPE_CARD || "Large";

export const ENV_GTAG_ID = process.env.REACT_APP_ANALYTICS_GTAG_ID || "";

//CONFIGS VARIÁVEIS PLUGINS
export const ENV_SHOW_UNDER_DEVELOPMENT_FEATURES = process.env.REACT_APP_SHOW_UNDER_DEVELOPMENT_FEATURES === "true";

export const ENV_SHOW_EXTERNAL_TRAINING = process.env.REACT_APP_THEME === "kordsa";

export const ENV_SHOW_MEETINGS = process.env.REACT_APP_METTINGS === "true";

export const ENV_SHOW_DISQUS = process.env.REACT_APP_DISQUS === "true";

export const ENV_SHOW_CERTIFICATES = process.env.REACT_APP_CERTIFICATES === "true";

export const ENV_DISABLE_TOKEN = process.env.REACT_APP_DISABLE_TOKEN === "true";

export const ENV_COMUNITY = process.env.REACT_APP_COMUNITY === "true";

//CONFIGS VARIÁVEIS PARA THEMES
export const ENV_WEBSITE_NAME = process.env.REACT_APP_WEBSITE_NAME || "GenteLab";

export const ENV_APP_THEME = process.env.REACT_APP_THEME || "default"; /*clientPlatform*/

//CHAVES PARA LOCAL STORAGE
export const LOCAL_STORAGE_ACCESS_TOKEN = "AkCoApCMlwÇ-000+§~=";

export const LOCAL_STORAGE_THEME = "856258AndIclFoeRV,-@=";

export const LOCAL_STORAGE_STUDENT = "moKosCls#$%*%";

export const LOCAL_STORAGE_USER = "@#$%*()kjk=%#$%";

//CHAVES PARA SESSION STORAGE
export const SESSION_STORAGE_LOGIN_WITH_TOKEN = "MksOp8523=-!@#$%";

export const SESSION_STORAGE_REGISTER = "123456789jKD(*&$!@#$%";

export const SESSION_STORAGE_STUDENT = "+_)(*&$#7452)(*&2345";

export const SESSION_STORAGE_LINK_DASHBOARD = "MksOp852jhgfd3=-!@#$%";

export const SESSION_STORAGE_STEPS_ID = "dsfghgfds=-!@#$%";

export const SESSION_STORAGE_IS_SEND_ANALYTICS = "addaf00=-!@[]~%";
