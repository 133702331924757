import { useClickOutside } from "@hooks";
import { debounce } from "@utils";
import React, { FC, MutableRefObject } from "react";
import { Link } from "react-router-dom";
import {
  H3,
  Frame,
  Input,
  FaIcon,
  UnForm,
  Shimmer,
  HighlightLetter,
} from "@atomic";
import { useSearchBar } from "./search-bar.component.data";
import {
  SearchDiamond,
  SearchWrapper,
  SearchResults,
  SearchResultsWrapper,
} from "./search-bar.component.style";

interface SearchBarProps {
  refParentElement: MutableRefObject<HTMLDivElement>;
}

export const SearchBar: FC<SearchBarProps> = ({ refParentElement }) => {
  const {
    error,
    results,
    isLoading,
    refSearch,
    handleReset,
    handleChangeInput,
  } = useSearchBar();
  useClickOutside(refParentElement, handleReset);

  return (
    <SearchWrapper>
      <UnForm
        ref={refSearch}
        onSubmit={() => ""}
        onChange={debounce(handleChangeInput, 500)}
      >
        {isLoading && <Shimmer height="37.69px" width="100%" />}
        {!isLoading && (
          <Input
            name="search"
            type="search"
            icon={<FaIcon.Search />}
            disabled={isLoading || !!error}
            style={{ width: "100%", height: "100%" }}
            placeholder={error ? "Erro ao carregar :(" : "Pesquisar"}
          />
        )}
      </UnForm>

      {!!results.length && (
        <>
          <SearchDiamond />
          <SearchResultsWrapper>
            <SearchResults>
              {results.map(({ materials, learning_units, path }, index) => {
                const linkURL = path?._id
                  ? `/paths/${path._id}`
                  : learning_units?._id
                  ? `/paths/${learning_units.path_id}/learning-units/${learning_units._id}`
                  : materials?._id &&
                    `/paths/${materials.path_id}/learning-units/${materials.learning_unit_id}/materials/${materials._id}`;

                const linkKey = `${path?.name} ${learning_units?.name} ${materials?.name} ${index}`;

                const name =
                  materials?.name || learning_units?.name || path?.name;

                const type =
                  materials?.type || learning_units?.type || path?.type;

                return (
                  <Link to={linkURL || ""} onClick={handleReset} key={linkKey}>
                    <Frame type="secondary" border clickable={path?._id !== 0}>
                      <H3>{type}</H3>

                      <HighlightLetter
                        text={name}
                        letter={refSearch.current?.getFieldValue("search")}
                      />
                    </Frame>
                  </Link>
                );
              })}
            </SearchResults>
          </SearchResultsWrapper>
        </>
      )}
    </SearchWrapper>
  );
};
