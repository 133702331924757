import { useAuthContext, useGetLabelHook } from "@contexts";
import { useClickOutside } from "@hooks";
import { Notifications, ProfileDropdown, SearchBar } from "@molecules";
import { ENV_COMUNITY, ENV_SEGMENTATION_CONTENT } from "@variables";
import React, { MutableRefObject, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Separator, BoxStyled, Logo, Hbox, Grid, Row, Col } from "@atomic";
import {
  MenuMobileNavigationLinkStyled,
  MenuMobileButtonLineStyled,
  MenuMobileNavigationStyled,
  MenuMobileWrapperStyled,
  MenuMobileButtonStyled,
  MenuMobileSearchBarWrapperStyled,
} from "./menu-mobile.component.style";

interface MenuMobileProps {
  toggleAction: () => void;
  showSearchBar: boolean;
}

export const MenuMobile: React.FC<MenuMobileProps> = ({
  toggleAction,
  showSearchBar,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const urlPath = useHistory().location.pathname;
  const { getPathLabel } = useGetLabelHook();

  const { userLocalStorage } = useAuthContext();
  const admin = userLocalStorage?.admin;

  const containerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const MenuMobileRef = useRef() as MutableRefObject<HTMLDivElement>;
  useClickOutside(MenuMobileRef, () => setIsOpen(false));

  return (
    <Grid $lg_hidden $xl_hidden>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <MenuMobileWrapperStyled>
            <Hbox ref={containerRef}>
              <Hbox>
                <BoxStyled v_align="center" ref={MenuMobileRef}>
                  <MenuMobileButtonStyled
                    onClick={() => setIsOpen(!isOpen)}
                    id="menu-mobile-tour"
                  >
                    <MenuMobileButtonLineStyled open={isOpen} />
                    <MenuMobileButtonLineStyled open={isOpen} />
                    <MenuMobileButtonLineStyled open={isOpen} />
                  </MenuMobileButtonStyled>

                  <MenuMobileNavigationStyled open={isOpen}>
                    <MenuMobileNavigationLinkStyled
                      to="/"
                      $active={urlPath === "/"}
                      onClick={() => setIsOpen(false)}
                    >
                      Home
                    </MenuMobileNavigationLinkStyled>

                    <MenuMobileNavigationLinkStyled
                      $active={urlPath === "/paths"}
                      to="/paths"
                      onClick={() => setIsOpen(false)}
                    >
                      {getPathLabel().plural}
                    </MenuMobileNavigationLinkStyled>

                    {ENV_SEGMENTATION_CONTENT === "categorias" && (
                      <MenuMobileNavigationLinkStyled
                        $active={urlPath === "/categories"}
                        to="/categories"
                        onClick={() => setIsOpen(false)}
                      >
                        Categorias
                      </MenuMobileNavigationLinkStyled>
                    )}

                    <MenuMobileNavigationLinkStyled
                      $active={urlPath === "/calendar"}
                      to="/calendar"
                      onClick={() => setIsOpen(false)}
                    >
                      Agenda
                    </MenuMobileNavigationLinkStyled>

                    {ENV_COMUNITY && (
                      <MenuMobileNavigationLinkStyled
                        $active={urlPath === "/comunity"}
                        to="/comunity"
                        onClick={() => setIsOpen(false)}
                      >
                        Comunidade
                      </MenuMobileNavigationLinkStyled>
                    )}

                    {admin && (
                      <MenuMobileNavigationLinkStyled
                        $active={urlPath === "/admin"}
                        to="/admin"
                        onClick={() => setIsOpen(false)}
                      >
                        Admin
                      </MenuMobileNavigationLinkStyled>
                    )}

                    <MenuMobileNavigationLinkStyled
                      $active={urlPath === "/contact"}
                      to="/contact"
                      onClick={() => setIsOpen(false)}
                    >
                      Ajuda
                    </MenuMobileNavigationLinkStyled>
                  </MenuMobileNavigationStyled>
                  <Logo />
                </BoxStyled>
              </Hbox>

              {showSearchBar && (
                <>
                  <Separator type="SemiLarge" />
                  <MenuMobileSearchBarWrapperStyled id="search-bar-medium-tour">
                    <SearchBar refParentElement={containerRef} />
                  </MenuMobileSearchBarWrapperStyled>
                  <Separator type="Medium" />
                </>
              )}

              <Hbox.Item h_align="flex-end">
                <BoxStyled h_align="flex-end" v_align="center">
                  <Notifications />
                  <Separator type="Small" />
                  <div id="profile-mobile-tour">
                    <ProfileDropdown toggleAction={toggleAction} />
                  </div>
                </BoxStyled>
              </Hbox.Item>
            </Hbox>
          </MenuMobileWrapperStyled>
        </Col>
      </Row>
    </Grid>
  );
};
