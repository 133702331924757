import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useQuery,
  QueryKey,
  UseQueryResult,
  UseQueryOptions,
} from "react-query";
import { Path } from ".";

export const getPathsKey = (props: ParamsPaths): QueryKey => [
  "PathsControllerFindAllPaths",
  ...Object.entries(props).flat(),
];

type QueryError = AxiosError<Path[]>;
type QueryOptions = UseQueryOptions<Path[], QueryError>;
export interface ParamsPaths {
  class_id: number;
  category_id?: number;
  min_release_date?: string;
  max_release_date?: string;
  filter_path_ids_list?: string;
  filter_class_ids_list?: string;
  return_learning_units?: boolean;
  include_released_paths?: boolean;
  include_not_released_paths?: boolean;
}

export const useGetPaths = (
  params: ParamsPaths,
  options?: QueryOptions
): UseQueryResult<Path[], QueryError> => {
  const handleGetPaths = async () => {
    try {
      const url = "/platform/paths";
      const { data } = await api.get<Path[]>(url, { params });
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  return useQuery<Path[], QueryError>({
    queryKey: getPathsKey({ class_id: params.class_id }),
    queryFn: handleGetPaths,
    ...options,
  });
};
