import styled, { css } from "styled-components";
import { FrameStyled } from "../frame";

interface DropdownFrameStyledProps {
  dropdownSize?: "Medium" | "Large";
  noFloat?: boolean;
}

const dropdownMinWidth = "10.8rem";
const largeDropdownWidth = "30rem";
const mediumDropdownWidth = "16.2rem";

export const DropdownFrameStyled = styled(
  FrameStyled
)<DropdownFrameStyledProps>`
  ${({ theme, noFloat, dropdownSize }) => css`
    min-width: ${dropdownMinWidth};

    position: absolute;
    right: 0;
    top: ${theme.spacings.Large};
    z-index: 99;

    ${noFloat === true &&
    css`
      position: relative;
      top: auto;
      right: auto;
      z-index: 99;
    `}

    ${dropdownSize === "Large" &&
    css`
      & {
        min-width: ${largeDropdownWidth};

        @media only screen and (max-width: ${theme.breakpoints.Large}) {
          width: calc(0.85 * ${largeDropdownWidth});
        }

        @media only screen and (max-width: ${theme.breakpoints.XSmall}) {
          min-width: 100%;
          width: 100%;
        }
      }
    `}

  ${dropdownSize === "Medium" &&
      css`
        & {
          min-width: ${mediumDropdownWidth};

          @media only screen and (max-width: ${theme.breakpoints.Large}) {
            width: calc(0.85 * ${mediumDropdownWidth});
          }

          @media only screen and (max-width: ${theme.breakpoints.XSmall}) {
            min-width: 60%;
            max-width: 100%;
          }
        }
      `}
    `}
`;
