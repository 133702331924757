import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
  MutationKey,
} from "react-query";
import { ChangePasswordWithOld } from ".";

type MutationError = AxiosError<boolean>;
type MutationOptions = UseMutationOptions<
  boolean,
  MutationError,
  ChangePasswordWithOld
>;

export const postAuthenticationChangePasswordWithOldKey = (): MutationKey =>
  "AuthenticationControllerChangePasswordWithOld";

export const usePostAuthenticationChangePasswordWithOld = (
  options?: MutationOptions
): UseMutationResult<boolean, MutationError, ChangePasswordWithOld> => {
  const handleAuthenticationChangePasswordWithOld = async (
    body: ChangePasswordWithOld
  ) => {
    try {
      const url = "/platform/authentication/change-password-with-old";
      const { data } = await api.post<boolean>(url, body);
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<boolean, MutationError, ChangePasswordWithOld>({
    mutationKey: postAuthenticationChangePasswordWithOldKey(),
    mutationFn: handleAuthenticationChangePasswordWithOld,
    ...options,
  });
};
