import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
  MutationKey,
} from "react-query";
import { ChangePassword } from ".";

type MutationError = AxiosError<boolean>;
type MutationOptions = UseMutationOptions<
  boolean,
  MutationError,
  ChangePassword
>;

export const postAuthenticationChangePasswordKey = (): MutationKey =>
  "AuthenticationControllerChangePassword";

export const usePostAuthenticationChangePassword = (
  options?: MutationOptions
): UseMutationResult<boolean, MutationError, ChangePassword> => {
  const handleAuthenticationChangePassword = async (body: ChangePassword) => {
    try {
      const url = "/platform/authentication/change-password";

      const { data } = await api.post<boolean>(url, body);
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<boolean, MutationError, ChangePassword>({
    mutationKey: postAuthenticationChangePasswordKey(),
    mutationFn: handleAuthenticationChangePassword,
    ...options,
  });
};
