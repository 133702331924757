/* eslint-disable max-classes-per-file */
/* eslint-disable no-shadow */

import { darken } from "polished";
import React from "react";
import { FaIcon } from "../components/atomic/fontawesome";
import { envBrandColor } from "../configs/brand-themes.config";

export class Color {
  public static readonly Primary100 = envBrandColor.Primary100;

  public static readonly Primary200 = envBrandColor.Primary200;

  public static readonly Secondary100 = envBrandColor.Secondary100;

  public static readonly Secondary200 = envBrandColor.Secondary200;

  public static readonly BrandContrast100 = envBrandColor.BrandContrast100;

  public static readonly BrandContrast200 = envBrandColor.BrandContrast200;

  public static readonly Black = "#1B1B2B";

  public static readonly White = "#FFFFFF";

  public static readonly Transparent = "transparent";

  public static readonly Success = "#2ABB4A";

  public static readonly Error = "#ED4848";

  public static readonly Warning = "#D4AD22";

  public static readonly Info = "#1F8AC6";

  public static readonly Gray900 = "#27273C";

  public static readonly Gray800 = "#303044";

  public static readonly Gray700 = "#454558";

  public static readonly Gray600 = "#6C6C7A";

  public static readonly Gray500 = "#8A8A9C";

  public static readonly Gray400 = "#B0B0B9";

  public static readonly Gray300 = "#DDDEDF";

  public static readonly Gray200 = "#F0F2F2";

  public static readonly Gray100 = "#FAFBFB";
}

export enum Spacing {
  XNano = "0.3rem",
  Nano = "0.6rem",
  Small = "0.9rem",
  SemiSmall = "1.2rem",
  Medium = "1.8rem",
  SemiLarge = "2.7rem",
  Large = "3.6rem",
  XLarge = "5.4rem",
  XXLarge = "7.2rem",
}

export enum FontFamily {
  Primary = "Poppins",
}

export enum FontWeight {
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
}

export enum LineHeight {
  XSmall = "1em",
  Small = "1.25em",
  Medium = "1.4em",
  Large = "1.6em",
}

export enum Radius {
  Small = "0.3rem",
  Medium = "0.6rem",
  Large = "1.6rem",
}

export enum Breakpoint {
  XSmall = "575px",
  Small = "767px",
  Medium = "991px",
  Large = "1199px",
  XLarge = "1200px",
}

export enum Transition {
  Fast = "0.2s",
  Slow = "1s",
}

export enum LRSActionsType {
  accessed = "access",
  concluded = "concluded",
  bookmarked = "bookmarked",
}

export enum Shadow {
  Primary = `#00000018 0px 1px 3px 0px, #00000008 0px 1px 2px 0px`,
}

export const Border = {
  All: "inset 0px 0px 0px 1px",
  Top: "inset 0px 1px 0px 0px",
  Bottom: "inset 0px -1px 0px",
  Left: "inset 1px 0px 0px 0px",
  Right: "inset -1px 0px 0px 0px",
};

export const Gradients = {
  Primary: `linear-gradient(90deg, ${darken(0.15, Color.Secondary200)} 0%, ${
    Color.Secondary200
  } 100%);`,
};

export const PaddingSizes = {
  XNano: Spacing.XNano,
  Nano: Spacing.Nano,
  Small: Spacing.Small,
  SemiSmall: Spacing.SemiSmall,
  Medium: Spacing.Medium,
  SemiLarge: Spacing.SemiLarge,
  Large: Spacing.Large,
  XLarge: Spacing.XLarge,
  XXLarge: Spacing.XXLarge,
};

export interface PaddingProps {
  noPadding?: boolean;
  paddingTop?: keyof typeof PaddingSizes;
  paddingBottom?: keyof typeof PaddingSizes;
  paddingRight?: keyof typeof PaddingSizes;
  paddingLeft?: keyof typeof PaddingSizes;
  paddingTopBottom?: keyof typeof PaddingSizes;
  paddingLeftRight?: keyof typeof PaddingSizes;
  padding?: keyof typeof PaddingSizes;
}

export enum InputTypes {
  email = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?",
  telephone = `([\(]?[0-9]{2}[\)]?[\ ]?[0-9]{5}[\-]?[0-9]{4})`,
  cpf = `([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})`,
  password = `.{6,}`,
  //Esta RegExp valida a data
  //TODO ela não considera anos bissextos, precisa melhorá-la
  date_of_birth = `(((0[1-9]|[12][0-9]|3[01])/(0[13578]|(1[02])))|((0[1-9]|[12][0-9]|3[0])/(0[469]|(11)))|((0[1-9]|[12][0-9])/(02)))/(19|20)\\d\\d`,
}

export const materialTypeIcon = {
  1: <FaIcon.Video />,
  2: <FaIcon.AlignLeft />,
  3: <FaIcon.Brain />,
  4: <FaIcon.FileImage />,
  5: <FaIcon.CheckSquare />,
  6: <FaIcon.Route />,
  7: <FaIcon.ThumbsUp />,
  /* 8: <FaIcon.BookReader />, */
  9: <FaIcon.File />,
  10: <FaIcon.Task />,
  11: <FaIcon.HeadphonesAlt />,
  12: <FaIcon.FileInvoice />,
};
