import styled, { css } from "styled-components";

export const Image = styled.img`
  display: inline-block;

  object-fit: fill;

  border-radius: ${({ theme }) =>
    css`
      ${theme.radius.Small}
    `};

  width: 100%;
`;
