import { FontSize } from "@constants";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Col } from "../../atomic/grid";
// import { NotificationIconStyled } from "../notifications";

export const MenuDesktopLeftSideStyled = styled(Col)`
  display: flex;
  width: 100%;
  align-items: center;
  ${({ theme }) =>
    css`
      @media only screen and (max-width: ${theme.breakpoints.Medium}) {
        & {
          display: none;
        }
      }
    `}
`;

export const MenuDesktopRightSideStyled = styled(Col)`
  flex-direction: row;
  justify-content: flex-end;
`;

export const NavigationDesktopStyled = styled.nav`
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const LogoStyled = styled.div``;

export const ContentSearchBarStyled = styled.div`
  width: 100%;
`;

interface NavigationLinkStyledProps {
  $active?: boolean;
}

export const NavigationLinkStyled = styled(Link)<NavigationLinkStyledProps>`
  ${({ theme, $active }) => css`
    font-family: ${theme.font.Family};
    font-size: ${FontSize.Medium};
    font-weight: ${theme.font.medium};
    line-height: ${theme.font.LineHeight.XSmall};
    padding: ${theme.spacings.Nano} ${theme.spacings.Small};
    text-decoration: none;
    transition: 0.2 ease-out;
    color: ${$active ? theme.colors.Primary : theme.colors.Gray700};

    user-select: none;

    user-select: none;

    user-select: none;

    & + & {
      margin-left: ${theme.spacings.XNano};
    }

    display: inline-flex;
    align-items: center;

    &:hover {
      color: ${theme.colors.Primary};
    }

    svg {
      margin-right: ${theme.spacings.XNano};
    }
  `}
`;
