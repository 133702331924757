import { FontSize } from "@constants";
import { FullScreen } from "react-full-screen";
import styled, { css } from "styled-components";
import { Button } from "../button";

interface IsFullScreenI {
  isFull: boolean;
}

export const BodyFullScreen = styled(FullScreen)<IsFullScreenI>`
  position: relative;
  overflow: ${({ isFull }) => isFull && "auto"};
`;

export const ButtonFullScreen = styled(Button)<IsFullScreenI>`
  ${({ theme, isFull }) => css`
    position: ${isFull ? "fixed" : "absolute"};
    bottom: ${isFull && theme.spacings.Medium};
    top: ${!isFull && theme.spacings.Medium};
    right: ${theme.spacings.Medium};

    padding: ${theme.spacings.Nano};
    font-size: ${FontSize.XLarge};

    opacity: 70%;
  `}
`;
