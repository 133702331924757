import { Doubts, StudentValidation } from "@services";
import { api } from "@utils";
import {
  ENV_APP_THEME,
  ENV_EMAIL_SUPPORT,
  LOCAL_STORAGE_ACCESS_TOKEN,
} from "@variables";
import jwtDecode from "jwt-decode";
import { mailto } from ".";

interface StudentJWT extends StudentValidation {
  exp: number;
  iat: number;
}

export const errorHandler = (
  error: Error,
  info: { componentStack: string }
): void => {
  const accessToken = window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);

  if (!accessToken) {
    window.open(
      mailto(error, info?.componentStack),
      "_blank",
      "height=600,width=600"
    );
    return;
  }

  const student = jwtDecode<StudentJWT>(accessToken);

  const formatStudent = (_: StudentJWT) => {
    const _string = JSON.stringify(_, null, 2);
    return _string.replace(/[{}"]/g, "").replace(/[,]/g, ";");
  };

  const form: Doubts = {
    email: student?.email,
    client: ENV_APP_THEME,
    emailSupport: ENV_EMAIL_SUPPORT,
    username: `${student?.name} ${student?.lastName}`,
    subject: `Error crítico na plataforma ${ENV_APP_THEME}`,
    message: `
      NOME: ${error?.name};
      MENSAGEM: ${error?.message};
      STACK: ${error?.stack};
      COMPONENT STACK: ${info?.componentStack};
      JWT DECODE DO ALUNO: ${formatStudent(student)}
      `,
  };

  api
    .post("/email/doubts", form)
    .then((res) => console.info("Email enviado com sucesso: ", res))
    .catch((err) => console.error("Erro ao enviar email: ", err));
};
