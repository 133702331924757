/**
 * Função para atenuar a execução de uma função, recebe dois parâmetros, sendo o primeiro deles a função a ser atenuada e o segundo o tempo de atenuação em milissegundos.
 *
 * @param func - function to be debounced
 * @param waitFor - time to wait for
 * @returns - debounced function
 */

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
): any => {
  let timeout: NodeJS.Timeout;

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor);
    });
};
