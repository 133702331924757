import { api } from "@utils";
import { AxiosError } from "axios";
import {
  MutationKey,
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from "react-query";
import { AuthenticationValidationToken } from ".";

type MutationError = AxiosError<boolean>;
type MutationOptions = UseMutationOptions<
  boolean,
  MutationError,
  AuthenticationValidationToken
>;
type MutationReturn = UseMutationResult<
  boolean,
  MutationError,
  AuthenticationValidationToken
>;

export const postAuthenticationValidationTokenKey = (): MutationKey =>
  "AuthenticationControllerValidateToken";

export const usePostAuthenticationValidationToken = (
  options?: MutationOptions
): MutationReturn => {
  const handlePostAuthenticationValidationToken = async (
    bodyData: AuthenticationValidationToken
  ) => {
    try {
      const url = "/platform/authentication/validation-token";
      const { data } = await api.post<boolean>(url, bodyData);
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<boolean, MutationError, AuthenticationValidationToken>({
    mutationKey: postAuthenticationValidationTokenKey(),
    mutationFn: handlePostAuthenticationValidationToken,
    ...options,
  });
};
