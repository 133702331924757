import { Color } from "@constants";
import React from "react";
import { toast, ToastOptions } from "react-toastify";
import { FaIcon, Logo } from "@atomic";
import { ContentItem } from "../content-item";
import {
  ToastCloseButton,
  ToastMessage,
  ToastWrapper,
  ToastStyled,
  ToastTitle,
  ToastBody,
  ToastIcon,
} from "./toast.component.style";
import "react-toastify/dist/ReactToastify.min.css";

interface ToastProps extends ToastOptions {
  title: string;
  message?: string;
}

/**
 * Essa função retornara um toast personalizado para a plataforma.
 * @param object ToastProps
 * @returns ReactText
 **/

export const Toast = ({
  message,
  title,
  ...rest
}: ToastProps): React.ReactText => {
  const { type: typeToast } = rest;
  const type = typeToast ? typeToast : "default";

  const configType = {
    info: { color: Color.Info, icon: <FaIcon.Info /> },
    success: { color: Color.Success, icon: <FaIcon.Check /> },
    warning: { color: Color.Warning, icon: <FaIcon.Exclamation /> },
    error: { color: Color.Error, icon: <FaIcon.Times /> },
    default: { color: Color.Secondary100, icon: <Logo /> },
    dark: { color: Color.Secondary200, icon: <Logo /> },
  };

  return toast(
    <ToastWrapper>
      <ContentItem
        icon={
          <ToastIcon color={configType[type].color}>
            {configType[type].icon}
          </ToastIcon>
        }
        iconSize="Small"
        noPadding
      >
        <ToastTitle color={configType[type].color}>{title}</ToastTitle>
      </ContentItem>
      <ToastMessage>{message}</ToastMessage>
    </ToastWrapper>,
    {
      closeButton: <ToastCloseButton>x</ToastCloseButton>,
      position: "bottom-center",
      progressStyle: {
        backgroundColor: configType[type].color,
      },
      bodyStyle: ToastBody,
      style: ToastStyled,
      ...rest,
    }
  );
};
