import React, { FC, Fragment } from "react";
import { Body } from "../typography";
import { MatchedLetterStyled } from "./high-light-letter.component.style";

interface HighlightLetterProps {
  text?: string;
  letter: string;
}

export const HighlightLetter: FC<HighlightLetterProps> = ({
  text = "",
  letter,
}) => {
  const regex = new RegExp(letter, "gi");
  const matched = text.match(regex);

  if (matched) {
    const words = text.split(regex);

    return (
      <Body>
        {words.map((item, index) => {
          const currentMatch = matched[index];
          return (
            <Fragment key={item + index}>
              {item}
              <MatchedLetterStyled>{currentMatch}</MatchedLetterStyled>
            </Fragment>
          );
        })}
      </Body>
    );
  }

  return <Body>{text}</Body>;
};
