import { useField } from "@unform/core";
import React, {
  InputHTMLAttributes,
  useEffect,
  useState,
  useCallback,
  useRef,
  ChangeEvent,
  FC,
} from "react";
import { InputLabel, Separator, FaIcon } from "@atomic";
import { maskCPF, maskTel, maskDate } from "./input.component.functions";
import {
  PasswordIconStyled,
  InputItemStyled,
  IconStyled,
  InputStyled,
  InputWrapperStyled,
  InputHiddenStyled,
} from "./input.component.style";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  icon?: JSX.Element;
}

export const Input: FC<InputProps> = (props) => {
  const { name, label, type, icon, ...rest } = props;

  const [showPassword, setShowPassword] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, inputRef, registerField]);

  const passwordIcon = showPassword ? <FaIcon.EyeSlash /> : <FaIcon.Eye />;

  const inputType = showPassword ? "text" : type;

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
    inputRef.current?.focus();
  };
  const handleClick = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const onKeyDown = (e) => {
    if (e.keyCode === 27) {
      inputRef.current?.blur();
    }

    if (e.keyCode === 13) {
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      form.elements[index + 1].focus();
      e.preventDefault();
    }
  };

  const handleOnChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (!inputRef.current) {
      return;
    }

    type === "cpf" &&
      ((inputRef.current.value = maskCPF(evt.target.value)),
      (inputRef.current.defaultValue = maskCPF(evt.target.value)));

    type === "tel" &&
      ((inputRef.current.value = maskTel(evt.target.value)),
      (inputRef.current.defaultValue = maskTel(evt.target.value)));

    type === "birthday" &&
      ((inputRef.current.value = maskDate(evt.target.value)),
      (inputRef.current.defaultValue = maskDate(evt.target.value)));
  };

  const inputMask = (prop: "value" | "defaultValue") => {
    const value = `${rest[prop]}`;
    type === "cpf" && (rest[prop] = maskCPF(value));
    type === "tel" && (rest[prop] = maskTel(value));
    type === "birthday" && (rest[prop] = maskDate(value));
  };

  !!rest.value && inputMask("value");
  !!rest.defaultValue && inputMask("defaultValue");

  if (type?.toLocaleLowerCase() === "hidden") {
    return (
      <InputHiddenStyled ref={inputRef} name={name} type={type} {...rest} />
    );
  }

  return (
    <InputItemStyled>
      {label && (
        <>
          <InputLabel onClick={handleClick}>{label}</InputLabel>
          <Separator type="XNano" />
        </>
      )}
      <InputWrapperStyled disabled={rest?.disabled} onClick={handleClick}>
        {icon && <IconStyled>{icon}</IconStyled>}

        <InputStyled
          ref={inputRef}
          type={inputType}
          onKeyDown={onKeyDown}
          onChange={handleOnChange}
          showPassword={showPassword}
          {...rest}
        />

        {type === "password" && (
          <PasswordIconStyled onClick={handleShowPassword}>
            {passwordIcon}
          </PasswordIconStyled>
        )}
      </InputWrapperStyled>
    </InputItemStyled>
  );
};
