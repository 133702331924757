import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

interface ShimmerWrapperStyledProps {
  height?: string;
  width?: string;
}

export const ShimmerWrapperStyled = styled.div<ShimmerWrapperStyledProps>`
  position: relative;

  width: ${(props) => props.width || "auto"};
  height: 0;
  padding-bottom: ${(props) => props.height || "auto"};
`;

export const ShimmerStyled = styled(Skeleton)`
  height: 100%;
  width: 100%;

  position: absolute;
`;
