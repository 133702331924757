import { FontSize } from "@constants";
import styled, { css } from "styled-components";

interface DropDownProps {
  showList?: boolean;
  active?: boolean;
}

export const DropDownStyled = styled.div<DropDownProps>`
  position: relative;
  ${({ theme }) => css`
    border-radius: ${theme.radius.Small};
  `}

  z-index: 1;
`;

export const DropDownHeaderStyled = styled.div<DropDownProps>`
  ${({ theme }) => css`
    padding: ${theme.spacings.XNano};

    background-color: ${theme.colors.Gray200};

    user-select: none;

    cursor: pointer;
  `}
`;

export const DropDownListWraperStyled = styled.div<DropDownProps>`
  ${({ theme, showList }) => css`
    position: absolute;

    width: 100%;

    background-color: ${theme.colors.White};

    display: ${showList ? "block" : "none"};
  `}
`;

export const DropDownItemStyled = styled.div<DropDownProps>`
  ${({ theme, active }) => css`
    width: 100%;

    padding: ${theme.spacings.XNano};

    font-family: ${theme.font.Family};
    font-size: ${FontSize.Small};
    line-height: ${theme.font.LineHeight.XSmall};
    font-weight: ${theme.font.medium};

    ${active &&
    css`
      color: ${theme.colors.Primary};
      background-color: ${theme.colors.Primary}33;
    `}

    user-select: none;

    &:hover {
      background-color: ${theme.colors.Gray200};
      cursor: pointer;
    }
  `}
`;

export const HiddenInputStyled = styled.input`
  display: none;
`;
