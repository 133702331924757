import { useClickOutside, useSessionStorage } from "@hooks";
import { Notification } from "@interfaces/kerala-api";
import { useGetNotifications } from "@services";
import React, { FC, MutableRefObject, useRef, useState } from "react";
import { FaIcon, DropdownFrame, Body, BodyLink, H4 } from "@atomic";
import {
  NotificationsDropdownHeaderWrapperStyled,
  NotificationsListWrapperStyled,
  NotificationsWrapperStyled,
  NotificationButtonStyled,
  NotificationContent,
  NotificationText,
} from "./notifications-dropdown.component.style";

const keySessionStorage = "has-new-notification";
interface Props {
  onClick: () => void;
  notifications?: Notification[];
}

export const Notifications: FC = () => {
  const { data } = useGetNotifications();
  const [hasNewNotifications, setHasNewNotifications] = useSessionStorage(
    keySessionStorage,
    true
  );

  const [notificationsIsOpen, setNotificationsIsOpen] = useState(false);

  const NotificationsWrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
  useClickOutside(NotificationsWrapperRef, () => setNotificationsIsOpen(false));
  const clickNotifications = () => {
    setHasNewNotifications(false);
    setNotificationsIsOpen((prev) => !prev);
  };

  return (
    <NotificationsWrapperStyled ref={NotificationsWrapperRef}>
      <NotificationButtonStyled
        kind="tertiary"
        hasNotifications={hasNewNotifications}
        isClicked={notificationsIsOpen}
        onClick={clickNotifications}
      >
        <FaIcon.Bell />
      </NotificationButtonStyled>

      {notificationsIsOpen && (
        <NotificationsDropdown
          onClick={() => setNotificationsIsOpen(false)}
          notifications={data}
        />
      )}
    </NotificationsWrapperStyled>
  );
};

const NotificationsDropdown: FC<Props> = ({ onClick, notifications }) => {
  return (
    <DropdownFrame dropdownSize="Large" paddingSize="XNano">
      <NotificationsDropdownHeader onClick={onClick} />

      <NotificationsList notifications={notifications} />

      {/* <NotificationsDropdownFooter /> */}
    </DropdownFrame>
  );
};

const NotificationsDropdownHeader: FC<Props> = ({ onClick }) => {
  const { data } = useGetNotifications();
  const newsNotifications = data?.filter(({ isNew }) => isNew)?.length || 0;

  return (
    <NotificationsDropdownHeaderWrapperStyled>
      <H4 userSelect={false}>{newsNotifications} Notificações não lidas</H4>
      <BodyLink to="/student/profile/notifications" onClick={onClick}>
        Ver todas
      </BodyLink>
    </NotificationsDropdownHeaderWrapperStyled>
  );
};

//TODO: Implementar ações de marcar como lida e marcar todas como lidas
// const NotificationsDropdownFooter: FC = () => {
//   const { markAllAsRead } = useNotifications();
//   return (
//     <NotificationsDropdownFooterWrapperStyled>
//       <Button expanded kind="secondary" onClick={markAllAsRead}>
//         Marcar todas como lidas
//       </Button>
//     </NotificationsDropdownFooterWrapperStyled>
//   );
// };

interface NotificationsListProps {
  notifications?: Props["notifications"];
}

const NotificationsList: FC<NotificationsListProps> = ({ notifications }) => {
  return (
    <NotificationsListWrapperStyled>
      {!!notifications?.length &&
        notifications
          .filter(({ isNew }) => isNew)
          .sort(({ days: a }, { days: b }) => a - b)
          .slice(0, 4)
          .map(({ title, subtitle, link }) => (
            <NotificationContent
              hasHover
              external
              linkTo={link}
              key={title + link}
              iconSize="Medium"
              icon={<FaIcon.Bell />}
            >
              <NotificationText enhance title={title}>
                {title}
              </NotificationText>
              <NotificationText light title={subtitle}>
                {subtitle}
              </NotificationText>
            </NotificationContent>
          ))}

      {!notifications?.length && (
        <Body center>Não há mensagens à serem lidas.</Body>
      )}
    </NotificationsListWrapperStyled>
  );
};
