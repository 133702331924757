import { createGlobalStyle, css } from "styled-components";

const scrollbarWidth = "8px";

export const GlobalStyled = createGlobalStyle<any>`
  html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, canvas {
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
font-family: "Poppins";
vertical-align: baseline;

box-sizing: border-box;

backface-visibility: hidden;
outline: 0;
-webkit-backface-visibility: hidden;
-webkit-font-smoothing: antialiased;
-moz-font-smoothing: antialiased;
-o-font-smoothing: antialiased;
text-rendering: optimizeLegibility;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, img{
  display: block;
}
body {
  line-height: 1;
  font-size: 16px;
  background-color: ${(props) => props.theme.colors.White};
}
ol, ul {
  list-style: none;
}
a {
  text-decoration: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button {
  border: none;
  outline: none;
}

body {
  overflow-x: hidden;
}

* {
  transition: background 0.3s ease-out, background-color 0.3s ease-out, color 0.3s ease-out;
}


&::-webkit-scrollbar {
    width: ${scrollbarWidth};
  }

  &::-webkit-scrollbar-track {
    padding-top: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.Gray200};

    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(props) => props.theme.colors.Gray400};
  }


  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    ${({ theme }) => css`
      border: none;
      -webkit-text-fill-color: ${theme.colors.Gray900};
      -webkit-box-shadow: 0 0 0 0 ${theme.colors.Gray200} inset;
      transition: background-color 5000s ease-in-out 0s;
    `}
  }
`;
