import { Gradients } from "@constants";
import styled, { css } from "styled-components";

interface BannerStyledProps {
  h_align?: "left" | "center" | "right";
  insideGrid?: boolean;
  image?: string;
  isInformative?: boolean;
  bg_color?: string;
}

export const BannerStyled = styled.div<BannerStyledProps>`
  display: flex;
  align-items: center;

  text-shadow: 1px 1px 10px #00000097;

  padding: 0 25px 0 25px;

  overflow: hidden;

  height: 250px;

  cursor: grab;

  ${({ theme, insideGrid, h_align, image, bg_color, isInformative }) => css`
    border-radius: ${!insideGrid ? theme.radius.Medium : "none"};

    * {
      text-align: ${h_align || "left"};
      color: #fff;
      z-index: 1;
    }

    button {
      ${h_align === "center"
        ? css`
            margin: 0 auto;
          `
        : css`
            float: ${h_align};
          `};
    }

    background: ${image
      ? css`url(${image}) center center`
      : bg_color || Gradients.Primary};
    background-size: cover;

    ${isInformative &&
    css`
      position: relative;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2)),
        ${image ? css`url(${image})` : bg_color || Gradients.Primary};

      &:after {
        content: "";
        position: absolute;
        inset: 0;
        backdrop-filter: blur(15px);
      }

      &:before {
        content: "";
        position: absolute;
        inset: 0;

        width: 100%;
        height: 100%;
        background: url(${image}) no-repeat center center;
        background-size: contain;
        z-index: 1;
      }
    `}
  `}
`;
