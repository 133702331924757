import styled, { css, keyframes } from "styled-components";

const LoaderEffect = keyframes`
  from {
    transform: scale(0);
    opacity: 100%;
  }

  to {
    transform: scale(1);
    opacity: 0%;
  }
`;

export interface LoaderProps {
  size?:
    | "XNano"
    | "Nano"
    | "Small"
    | "SemiSmall"
    | "Medium"
    | "SemiLarge"
    | "Large"
    | "XLarge"
    | "XXLarge";
}

export const LoaderWrapperStyled = styled.div<LoaderProps>`
  position: relative;
  ${({ theme, size }) => css`
    width: ${size ? theme.spacings[size] : "100px"};
    height: ${size ? theme.spacings[size] : "100px"};
  `}
  margin: auto;
  display: flex;
  justify-content: center;
  border-radius: 50%;
`;

export interface WaveProps {
  delay?: string;
  type?: "Primary" | "Secondary" | "Tertiary" | "BrandContrast";
}

export const WaveStyled = styled.div<WaveProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  ${({ theme, type }) => css`
    background-color: ${type === "BrandContrast"
      ? theme.colors.Black
      : type
      ? theme.colors[type]
      : theme.colors.Primary};
  `}
  animation: ${LoaderEffect} 2s linear infinite;
  animation-delay: ${({ delay }) => (delay ? delay : "1s")};
  opacity: 0%;
`;
