import styled, { css } from "styled-components";

interface IconStyledProps {
  iconSize?: "Small" | "Medium" | "Large";
  iconImage?: string;
  date?: string;
  primaryMode?: boolean;
  kind?: "primary" | "secondary" | "tertiary" | "quaternary";
}

export const IconStyled = styled.span<IconStyledProps>`
  ${({ theme, iconSize, iconImage, date, primaryMode, kind }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${theme.spacings.SemiLarge};
    height: ${theme.spacings.SemiLarge};

    border-radius: ${theme.radius.Small};

    margin-right: ${theme.spacings.Small};

    background-color: ${theme.colors.Gray200};

    ${iconSize === "Small" &&
    css`
      width: ${theme.spacings.Medium};
      height: ${theme.spacings.Medium};
      margin-right: ${theme.spacings.Small};
    `}

    ${iconSize === "Medium" &&
    css`
      width: ${theme.spacings.SemiLarge};
      height: ${theme.spacings.SemiLarge};
      margin-right: ${theme.spacings.Small};
    `}

    ${iconSize === "Large" &&
    css`
      width: ${theme.spacings.Large};
      height: ${theme.spacings.Large};
      margin-right: ${theme.spacings.SemiSmall};
    `}

    ${iconImage !== undefined &&
    css`
      background-image: url(${iconImage});
      background-size: cover;
    `}

    ${kind === "primary" &&
    css`
      background-color: ${theme.colors.White}26;

      & > * {
        color: ${theme.colors.BrandContrast};
      }
    `}

    color: ${theme.colors.Gray600};

    ${primaryMode &&
    css`
      background-color: ${theme.colors.White}26;

      & > * {
        color: ${theme.colors.White};
      }
    `}

    ${kind === "secondary" &&
    css`
      background-color: ${theme.colors.Primary}26;

      & > * {
        color: ${theme.colors.Primary}99;
      }
    `}

    ${kind === "tertiary" &&
    css`
      background-color: ${theme.colors.Gray200};

      & > * {
        color: ${theme.colors.Gray600};
      }
    `}

    transition: all 0.2s ease-out;

    ${date !== undefined &&
    css`
      & {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        * {
          text-align: center;
          width: 100%;
        }

        h3 {
          color: ${theme.colors.Gray800};
          margin-bottom: calc((${theme.spacings.Nano} * 0.7) * -1);
        }

        h5 {
          color: ${theme.colors.Gray600};
          text-transform: lowercase;
        }
      }
    `}
  `}
`;
