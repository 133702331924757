import { Path } from "@interfaces/kerala-api";
import { useEffect, useState } from "react";

const ONBOARDING_STARTS_NAME = "Onboarding -";
const PERCENTAGE_OF_COMPLETE_ONBOARDING = 100;

interface UseIsCompleteOnboardingReturns {
  isComplete: boolean;
  name: string;
}

export const useIsCompleteOnboarding = (
  paths?: Path[] | null
): UseIsCompleteOnboardingReturns => {
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    const pathOnboarding = paths?.find(({ name }) =>
      name.startsWith(ONBOARDING_STARTS_NAME)
    );

    //TODO: Se não encontrar o path de Onboarding deve manter bloqueado os outros paths?
    //Por enquanto está liberando os outros paths
    if (!pathOnboarding) {
      return setIsComplete(true);
    }

    const { learning_units } = pathOnboarding;

    const pathOnBoardingProgress = learning_units?.reduce((acc, lu) => {
      const required = lu?.number_required_materials || 0;
      const completed = lu.student_stats?.total_completed_materials || 0;

      const luOnboardingProgress = (completed / required) * 100;

      return acc + luOnboardingProgress;
    }, 0);

    const onboardingProgress =
      (pathOnBoardingProgress || 0) / (learning_units?.length || 0);

    setIsComplete(onboardingProgress >= PERCENTAGE_OF_COMPLETE_ONBOARDING);
  }, [paths]);

  return { isComplete, name: ONBOARDING_STARTS_NAME };
};
