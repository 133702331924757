import { Breakpoint } from "@constants";
import { useResize } from "@hooks";
import { MenuMobile, MenuDesktop, SearchBar } from "@molecules";
import React, { MutableRefObject, useRef } from "react";
import {
  HeaderWrapperStyled,
  HeaderContentStyled,
} from "./header.component.style";

interface HeaderProps {
  toggleAction: () => void;
}

export const Header: React.FC<HeaderProps> = ({ toggleAction }) => {
  const BreakpointSm = Number(Breakpoint.XSmall.replace("px", ""));
  const [Width] = useResize();
  const headerRef = useRef() as MutableRefObject<HTMLDivElement>;

  return (
    <HeaderContentStyled ref={headerRef}>
      <HeaderWrapperStyled>
        <MenuDesktop toggleAction={toggleAction} />
        <MenuMobile
          toggleAction={toggleAction}
          showSearchBar={Width >= BreakpointSm}
        />
      </HeaderWrapperStyled>
      {Width < BreakpointSm && (
        <div id="search-bar-mobile-tour">
          <SearchBar refParentElement={headerRef} />
        </div>
      )}
    </HeaderContentStyled>
  );
};
