import styled, { css } from "styled-components";
import { Button } from "@atomic";

export const TabButton = styled(Button)`
  ${({ active, theme }) => css`
    transition: all ${theme.transition.Fast} ease-in-out;

    ${!active &&
    css`
      background-color: ${theme.colors.Primary + "26"};
      color: ${theme.colors.Primary};
    `}
  `}
`;
