import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useQuery,
  QueryKey,
  UseQueryResult,
  UseQueryOptions,
} from "react-query";
import { RegisterStudentInfo } from ".";

export const getStudentRegisterKey = (props: ParamsRegister): QueryKey => [
  "StudentsControllerGetStudentInfoRegister",
  ...Object.entries(props).flat(),
];

type QueryError = AxiosError<RegisterStudentInfo>;
type QueryOptions = UseQueryOptions<RegisterStudentInfo, QueryError>;
interface ParamsRegister {
  info: string;
}

export const useGetStudentRegister = (
  paramsRegister: ParamsRegister,
  options?: QueryOptions
): UseQueryResult<RegisterStudentInfo, QueryError> => {
  const handleGetStudentRegister = async () => {
    try {
      const url = "/platform/students/register";
      const params = { info: paramsRegister.info };
      const { data } = await api.get<RegisterStudentInfo>(url, { params });
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  return useQuery<RegisterStudentInfo, QueryError>({
    queryKey: getStudentRegisterKey(paramsRegister),
    queryFn: handleGetStudentRegister,
    ...options,
  });
};
