/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const glFormatPercentage = (percentageValue) => {
    if (percentageValue){
        return Math.round(percentageValue);
    } else {
        return percentageValue;
    }
  };

export const glFormatPercentageEmptyIfUndefined = (percentageValue, sufixStr="%") => {
    if (percentageValue != null){
        return `${Math.round(percentageValue)}${sufixStr}`;
    } else {
        return '';
    }
  };

