import React from "react";
import {
  ProgressCircleWrapperStyled,
  ProgressCircleSVGStyled,
  ProgressCircleStyled,
} from "./progress-circle.component.style";

interface ProgressCircleProps {
  progress?: number;
  size?: "Small" | "Medium";
  active?: boolean;
  noColor?: boolean;
}

export const ProgressCircle: React.FC<ProgressCircleProps> = ({
  progress,
  size,
  active,
  children,
  noColor,
}) => {
  return (
    <ProgressCircleWrapperStyled size={size}>
      <ProgressCircleSVGStyled
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <ProgressCircleStyled
          cx="15"
          cy="15"
          r="13.5"
          noColor={noColor}
          active={active}
        />
        <ProgressCircleStyled
          progress={progress}
          noColor={noColor}
          active={active}
          cx="15"
          cy="15"
          r="13.5"
        />
        {children}
      </ProgressCircleSVGStyled>
    </ProgressCircleWrapperStyled>
  );
};
