import React from "react";
import {
  WaveStyled,
  LoaderWrapperStyled,
  LoaderProps,
  WaveProps,
} from "./loader.component.style";

interface LoaderI extends LoaderProps, WaveProps {}

export const Loader: React.FC<LoaderI> = ({ delay, size, type }) => {
  return (
    <LoaderWrapperStyled size={size}>
      <WaveStyled type={type} delay="0" />
      <WaveStyled type={type} delay={delay} />
    </LoaderWrapperStyled>
  );
};
