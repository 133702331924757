import { Spacing } from "@constants";
import styled, { css } from "styled-components";

interface ProgressCircleSVGStyledProps {
  size?: "Small" | "Medium";
}

export const ProgressCircleWrapperStyled = styled.div<ProgressCircleSVGStyledProps>`
  min-width: ${Spacing.Medium};
  width: ${Spacing.Medium};
  min-height: ${Spacing.Medium};
  height: ${Spacing.Medium};

  ${(props) =>
    props.size === "Small" &&
    css`
      min-width: ${Spacing.SemiSmall};
      width: ${Spacing.SemiSmall};
      min-height: ${Spacing.SemiSmall};
      height: ${Spacing.SemiSmall};
    `};
`;

export const ProgressCircleSVGStyled = styled.svg<ProgressCircleSVGStyledProps>`
  min-width: 100%;
  width: 100%;
  min-height: 100%;
  height: 100%;
`;

interface ProgressCircleStyledProps {
  progress?: number;
  noColor?: boolean;
  active?: boolean;
  size?: "Small" | "Medium";
}

export const ProgressCircleStyled = styled.circle<ProgressCircleStyledProps>`
  position: absolute;
  transform-origin: center center;
  transform: rotate(-90deg);
  z-index: 8;

  pointer-events: none;

  width: 100%;
  height: 100%;

  fill: none;
  stroke-width: 3px;
  stroke: ${({ noColor, theme }) =>
    noColor ? theme.colors.BrandContrast : theme.colors.Primary};

  &:nth-child(1) {
    opacity: 0.25;
  }

  &:nth-child(2) {
    stroke-dasharray: calc((${Spacing.Medium} * 3.141) - 2.5px);

    stroke-dashoffset: ${(props) =>
      props.progress
        ? `calc(((${Spacing.Medium} * 3.141)) * (1 - ${props.progress} / 100))`
        : `calc((${Spacing.Medium} * 3.141))`};
  }

  ${(props) =>
    props.active === false &&
    css`
      &:nth-child(1) {
        stroke: ${props.theme.colors.Gray800};
      }

      stroke: ${props.theme.colors.Gray800};
    `};

  ${(props) =>
    props.active === true &&
    css`
      stroke: ${props.theme.colors.Primary};
    `};

  ${(props) =>
    props.size === "Small" &&
    css`
      &:nth-child(2) {
        stroke-dasharray: calc((${Spacing.SemiSmall} * 3.141) + 3px);

        stroke-dashoffset: ${props.progress
          ? `calc(((${Spacing.SemiSmall} * 3.141) + 3px) * (1 - ${props.progress} / 100))`
          : `calc((${Spacing.SemiSmall} * 3.141) + 3px)`};
      }
    `};
`;
