import styled, { css } from "styled-components";

interface BoxProps {
  // src: https://www.w3schools.com/cssref/css3_pr_align-items.asp
  h_align?:
    | "stretch"
    | "center"
    | "flex-start"
    | "flex-end"
    | "baseline"
    | "initial"
    | "inherit";
  // src: https://www.w3schools.com/cssref/css3_pr_justify-content.asp
  v_align?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "initial"
    | "inherit";
}

export interface HboxProps extends BoxProps {
  noGrow?: boolean;
}

const HboxStyled = styled.div<HboxProps>`
  flex-direction: row;
  align-content: flex-start;
  display: flex;
  flex: 1;

  ${({ v_align, h_align }) => css`
    justify-content: ${v_align ? v_align : "flex-start"};
    align-items: ${h_align ? h_align : "stretch"};
  `}
`;

export const Hbox: any = HboxStyled;
Hbox.displayName = "Hbox";

Hbox.Item = styled.div<HboxProps>`
  flex-direction: column;
  display: flex;
  ${({ v_align, h_align, noGrow }) => css`
    ${!noGrow ? "flex: 1" : null};
    justify-content: ${v_align ? v_align : "flex-start"};
    align-items: ${h_align ? h_align : "stretch"};
  `}
`;
Hbox.Item.displayName = "Hbox.Item";

Hbox.Separator = styled.div`
  ${({ theme }) => css`
    width: ${theme.spacings.Small};
  `}
`;
Hbox.Separator.displayName = "Hbox.Separator";
