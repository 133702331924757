import { ENV_APP_THEME } from "@variables";

export interface ConfigRegisterLabel {
  messageRegister: string;
  company: string;
  department: string;
  role: string;
  schooling: string;
  telephone: string;
  class: string;
  classRoom: string;
  email: string;
  meeting: string;
}

interface UseGetRegisterLabelHookInterface {
  getRegisterLabel: () => ConfigRegisterLabel;
}

export const useGetRegisterLabelHook = (): UseGetRegisterLabelHookInterface => {
  const defaultRegisterLabel = {
    messageRegister:
      "Preencha os dados relativos à empresa e selecione a Sala da Masterclass",
    company: "Empresa",
    department: "Departamento",
    role: "Cargo",
    schooling: "Escolaridade",
    telephone: "Telefone",
    class: "Turma",
    classRoom: "Sala",
    email: "Email",
    meeting: "Reuniões",
    matricula: "Matricula",
  };

  const getRegisterLabel = (): ConfigRegisterLabel => {
    if (ENV_APP_THEME === "eskala") {
      return { ...defaultRegisterLabel, class: "Área" };
    }
    if (ENV_APP_THEME === "kordsa") {
      return {
        ...defaultRegisterLabel,
        email: "Email (uso exclusivo plataforma)",
      };
    }
    if (ENV_APP_THEME === "mbm") {
      return {
        ...defaultRegisterLabel,
        meeting: "Masterclasses",
      };
    }
    if (ENV_APP_THEME === "shinyAcademy") {
      return {
        ...defaultRegisterLabel,
        messageRegister:
          "Vamos selecionar conteúdos alinhados com os seus objetivos atuais",
        company: "Assunto de maior prioridade para desenvolvimento",
        department: "Área de atuação",
        role: "Função ou Ocupação",
      };
    }
    if (ENV_APP_THEME === "gentelab") {
      return {
        ...defaultRegisterLabel,
        messageRegister: "Preencha os campos abaixo",
      };
    }
    if (ENV_APP_THEME === "omni") {
      return {
        ...defaultRegisterLabel,
        messageRegister:
          "Preencha os campos abaixo para completar seu cadastro",
        company: "Semestre e ano",
        department: "Área de atuação",
        meeting: "Aulas ao vivo",
      };
    }
    if (ENV_APP_THEME === "atmo") {
      return {
        ...defaultRegisterLabel,
        company: "Instituição de Ensino",
        department: "Área de atuação",
        schooling: "Nível de Ensino",
        messageRegister:
          "Preencha os dados relativos à instituição de ensino e selecione a Sala das Lives",
        meeting: "Lives",
        role: "Grupo Educacional",
      };
    }
    return defaultRegisterLabel;
  };

  return { getRegisterLabel };
};
