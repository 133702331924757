import { Placeholder } from "@molecules";
import React, { FC, PropsWithChildren, ReactElement } from "react";

//TODO: Eliminar o null da tipagem
export interface LoadingStateProps<T> {
  error?: string | number | null;
  loading: boolean;
  data?: T | null;
  shimmer?: Element | JSX.Element | FC;
  placeholder?: Element | JSX.Element | FC;
}

export const LoadingState: <T extends unknown>(
  p: PropsWithChildren<LoadingStateProps<T>>
) => ReactElement<LoadingStateProps<T>> = (props): any => {
  const { children, shimmer, error, loading, data, placeholder } = props;

  if (data) {
    if (!Array.isArray(data)) {
      return children;
    } else if (data.length) {
      return children;
    }
  }

  if (loading) {
    return shimmer;
  }

  if (placeholder) {
    return placeholder;
  }

  const err = error ? `${error}` : null;

  return err ? <Placeholder type={err} /> : <Placeholder type="empty" />;
};
