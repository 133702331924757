import { useAuthContext } from "@contexts";
import { useClickOutside } from "@hooks";
import { ContentItem } from "@molecules";
import { GenerateDefaultImage } from "@utils";
import { ENV_SHOW_CERTIFICATES } from "@variables";
import React, { MutableRefObject, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { DropdownFrame, FaIcon, Switch, H4, H5 } from "@atomic";
import {
  ProfileDropdownButtonWrapperStyled,
  ProfileDropdownHeaderWrapperStyled,
  ProfileDropdownButtonImageStyled,
  ProfileDropdownWrapperStyled,
  ProfileDropdownLinksStyled,
  DropdownItemStyled,
  InitialsStyled,
} from "./profile-dropdown.component.style";

interface ProfileDropdownProps {
  toggleAction: () => void;
}

export const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
  toggleAction,
}) => {
  const [profileMenuIsOpen, setProfileMenuIsOpen] = useState(false);

  const handleSetMenu = () => {
    setProfileMenuIsOpen(!profileMenuIsOpen);
  };

  const ProfileDropdownRef = useRef() as MutableRefObject<HTMLDivElement>;
  useClickOutside(ProfileDropdownRef, () => setProfileMenuIsOpen(false));

  const profileImage = GenerateDefaultImage();

  return (
    <ProfileDropdownWrapperStyled ref={ProfileDropdownRef}>
      <ProfileDropdownButton
        onClick={handleSetMenu}
        profileImage={profileImage}
      />
      {profileMenuIsOpen && (
        <ProfileDropdownFrame
          toggleAction={toggleAction}
          onSetMenu={handleSetMenu}
          profileImage={profileImage}
        />
      )}
    </ProfileDropdownWrapperStyled>
  );
};

interface ProfileDropdownButtonProps {
  onClick?: () => void;
  profileImage: string;
}

const ProfileDropdownButton: React.FC<ProfileDropdownButtonProps> = ({
  onClick,
  profileImage,
}) => {
  const { userLocalStorage } = useAuthContext();
  return (
    <ProfileDropdownButtonWrapperStyled onClick={onClick}>
      {profileImage ? (
        <ProfileDropdownButtonImageStyled src={profileImage} />
      ) : (
        <InitialsStyled>
          {userLocalStorage?.name_initials_two_letters}
        </InitialsStyled>
      )}
    </ProfileDropdownButtonWrapperStyled>
  );
};

interface ProfileDropdownFrameProps {
  toggleAction: () => void;
  onSetMenu?: () => void;
  profileImage: string;
}

const ProfileDropdownFrame: React.FC<ProfileDropdownFrameProps> = ({
  toggleAction,
  onSetMenu,
  profileImage,
}) => {
  function handleChange() {
    toggleAction();
  }

  const routeUrl = useHistory()?.location.pathname;

  const { logout } = useAuthContext();

  const handleLogout = () => {
    logout();
  };

  return (
    <DropdownFrame dropdownSize="Medium" paddingSize="XNano">
      <ProfileDropdownHeader onClick={onSetMenu} profileImage={profileImage} />

      <ProfileDropdownLinksStyled>
        <ContentItem
          icon={<FaIcon.Bookmark />}
          iconSize="Small"
          linkTo="/student/profile/saved-items"
          active={routeUrl === "/student/profile/saved-items"}
          hasHover
          userSelect={false}
          paddingTopBottom="Nano"
          onClick={onSetMenu}
        >
          <H4 enhance>Itens salvos</H4>
        </ContentItem>

        {ENV_SHOW_CERTIFICATES && (
          <ContentItem
            icon={<FaIcon.Certificate />}
            iconSize="Small"
            linkTo="/student/profile/certificate-items"
            active={routeUrl === "/student/profile/certificate-items"}
            hasHover
            userSelect={false}
            paddingTopBottom="Nano"
            onClick={onSetMenu}
          >
            <H4 enhance>Certificados</H4>
          </ContentItem>
        )}

        <ContentItem
          icon={<FaIcon.ChartLine />}
          iconSize="Small"
          linkTo="/student/evolution"
          hasHover
          active={routeUrl === "/student/evolution"}
          userSelect={false}
          paddingTopBottom="Nano"
          onClick={onSetMenu}
        >
          <H4 enhance>Evolução</H4>
        </ContentItem>

        <ContentItem
          icon={<FaIcon.Adjust />}
          iconSize="Small"
          onClick={handleChange}
          toggle={<Switch onChange={handleChange} toggleTheme />}
          hasHover
          userSelect={false}
          paddingTopBottom="Nano"
        >
          <H4 enhance>Modo escuro</H4>
        </ContentItem>

        <ContentItem
          icon={<FaIcon.SignOutAlt />}
          iconSize="Small"
          hasHover
          userSelect={false}
          // linkTo="/new/home"
          paddingTopBottom="Nano"
          alignCenter
          onClick={() => handleLogout()}
        >
          <DropdownItemStyled enhance>Sair</DropdownItemStyled>
        </ContentItem>
      </ProfileDropdownLinksStyled>
    </DropdownFrame>
  );
};

const ProfileDropdownHeader: React.FC<ProfileDropdownButtonProps> = ({
  onClick,
  profileImage,
}) => {
  const { userLocalStorage } = useAuthContext();
  return (
    <ProfileDropdownHeaderWrapperStyled onClick={onClick}>
      <ContentItem
        iconSize="Small"
        linkTo="/student/profile/edit"
        iconImage={profileImage}
        noPadding
        alignCenter
      >
        <H4 enhance>
          {userLocalStorage.social_name
            ? userLocalStorage.social_name
            : `${userLocalStorage.name} ${userLocalStorage.last_name}`}
        </H4>
        <H5>Ver perfil</H5>
      </ContentItem>
    </ProfileDropdownHeaderWrapperStyled>
  );
};
