import parse from "html-react-parser";
import React, { FC } from "react";
import { HtmlTextSectionStyled } from "./html-text-section.component.style";

export const HtmlTextSection: FC<{ htmlContent: string }> = (props) => {
  const htmlContent = props.htmlContent
    .replaceAll("<text>", "")
    .replaceAll("</text>", "");

  return <HtmlTextSectionStyled>{parse(htmlContent)}</HtmlTextSectionStyled>;
};
