import { useAuthContext, useGetLabelHook } from "@contexts";
import { ProfileDropdown, Notifications, SearchBar } from "@molecules";
import { ENV_COMUNITY, ENV_SEGMENTATION_CONTENT } from "@variables";
import React, { MutableRefObject, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Separator, Logo, Grid, Row } from "@atomic";
import {
  MenuDesktopRightSideStyled,
  MenuDesktopLeftSideStyled,
  NavigationDesktopStyled,
  ContentSearchBarStyled,
  NavigationLinkStyled,
} from "./menu-desktop.component.style";

interface MenuDesktopProps {
  toggleAction: () => void;
}

export const MenuDesktop: React.FC<MenuDesktopProps> = ({ toggleAction }) => {
  const { pathname } = useLocation();
  const { getPathLabel } = useGetLabelHook();

  const { userLocalStorage } = useAuthContext();
  const admin = userLocalStorage.admin;

  const menuRef = useRef() as MutableRefObject<HTMLDivElement>;

  return (
    <Grid $xs_hidden $sm_hidden $md_hidden>
      <Row>
        <MenuDesktopLeftSideStyled md={5} lg={5} xl={5}>
          <Logo />
          <Separator type="Medium" />
          <ContentSearchBarStyled ref={menuRef} id="search-bar-tour">
            <SearchBar refParentElement={menuRef} />
          </ContentSearchBarStyled>
        </MenuDesktopLeftSideStyled>

        <MenuDesktopRightSideStyled md={7} lg={7} xl={7} h_align="center">
          <NavigationDesktopStyled id="menu-tour">
            <NavigationLinkStyled to="/" $active={pathname === "/"}>
              Home
            </NavigationLinkStyled>

            <NavigationLinkStyled $active={pathname === "/paths"} to="/paths">
              {getPathLabel().plural}
            </NavigationLinkStyled>

            {ENV_SEGMENTATION_CONTENT === "categorias" && (
              <NavigationLinkStyled
                $active={pathname === "/categories"}
                to="/categories"
              >
                Categorias
              </NavigationLinkStyled>
            )}

            <NavigationLinkStyled
              $active={pathname === "/calendar"}
              to="/calendar"
            >
              Agenda
            </NavigationLinkStyled>

            {ENV_COMUNITY && (
              <NavigationLinkStyled
                $active={pathname === "/comunity"}
                to="/comunity"
              >
                Comunidade
              </NavigationLinkStyled>
            )}

            {admin && (
              <NavigationLinkStyled $active={pathname === "/admin"} to="/admin">
                Admin
              </NavigationLinkStyled>
            )}

            <NavigationLinkStyled
              $active={pathname === "/contact"}
              to="/contact"
            >
              Ajuda
            </NavigationLinkStyled>

            <Notifications />

            <Separator type="Small" />

            <div id="profile-tour">
              <ProfileDropdown toggleAction={toggleAction} />
            </div>
          </NavigationDesktopStyled>
        </MenuDesktopRightSideStyled>
      </Row>
    </Grid>
  );
};
