import {
  PaddingSizes,
  Transition,
  FontFamily,
  Breakpoint,
  FontWeight,
  FontSize,
  Spacing,
  Radius,
} from "@constants";
import { FullScreen } from "react-full-screen";
import styled, { css } from "styled-components";

interface AudioProgressStyledProps {
  loading?: number;
  loaded?: number;
  buffered?: boolean;
}

interface MediaType {
  type: "video" | "audio";
  is_hide?: boolean;
}

const progressLineWeight = "5px";

export const MediaHideStyled = styled.div<MediaType>`
  ${({ type }) =>
    type === "video" &&
    css`
      position: absolute;
      inset: 0;
    `};
`;

export const MediaWrapperStyled = styled(FullScreen)<{ isHidden?: boolean }>`
  ${({ handle, isHidden }) =>
    css`
      display: ${isHidden ? "none" : "flex"};
      flex-direction: column;

      position: relative;
      border-radius: ${Radius.Small};
      overflow: hidden;

      ${handle.active &&
      css`
        & iframe {
          position: fixed !important;
        }
      `}
    `}
`;

export const MediaControlsWrapperStyled = styled.div<MediaType>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${(props) => props.theme.colors.Gray200};
  padding: ${PaddingSizes.Nano};
  border-radius: ${Radius.Small};

  font-size: ${FontSize.Large};
  z-index: 1;

  transition: ${Transition.Slow};

  ${({ type, is_hide }) =>
    type === "video" &&
    css`
      visibility: ${is_hide ? "hidden" : "visible"};
      opacity: ${is_hide ? 0 : 0.8};
      transform-origin: bottom;
      transform: scale(0.8);
      position: absolute;
      width: 100%;
      bottom: 5px;
    `}
`;

export const MediaProgressWrapperStyled = styled.div`
  display: flex;
  align-items: center;

  background: ${(props) => props.theme.colors.Gray300};
  border-radius: ${Radius.Small};

  width: 100%;
  height: ${progressLineWeight};

  font-size: inherit;

  position: relative;
`;

export const MediaProgressStyled = styled.input`
  position: relative;
  border-radius: ${Radius.Small};
  z-index: 2;

  background: transparent;
  width: 100%;
  height: 100%;

  cursor: pointer;
  appearance: none;

  /* estiliza o marcador móvel */
  /* Chrome/Safari/Opera */
  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* remove estilo padrão do browser */
    background: ${(props) => props.theme.colors.Primary};
    border-radius: 50%;
    margin-top: -4px;
    height: 12px;
    width: 12px;
    cursor: pointer;
  }

  /* Firefox */
  &::-moz-range-thumb {
    background: ${(props) => props.theme.colors.Primary};
    border-radius: 50%;
    margin-top: -4px;
    height: 12px;
    width: 12px;
    cursor: pointer;
  }

  /* IE */
  &::-ms-thumb {
    background: ${(props) => props.theme.colors.Primary};
    border-radius: 50%;
    margin-top: -4px;
    height: 12px;
    width: 12px;
    cursor: pointer;
  }

  /* Chrome/Safari/Opera */
  &::-webkit-slider-runnable-track {
    border-radius: ${Radius.Small};
    background: transparent;
    width: 100%;
    height: 100%;
  }

  /* Firefox */
  &::-moz-range-track {
    border-radius: ${Radius.Small};
    background: transparent;
    width: 100%;
    height: 100%;
  }

  /* IE */
  &::-ms-track {
    border-radius: ${Radius.Small};
    background: transparent;
    width: 100%;
    height: 100%;
  }
`;

export const MediaProgressBackgroundStyled = styled.div<AudioProgressStyledProps>`
  background: ${({ theme }) => theme.colors.Primary};
  width: ${({ loading }) => loading}%;
  height: 100%;
  z-index: 1;
  position: absolute;
  border-radius: ${Radius.Small};
`;

export const MediaLoadedProgressStyled = styled.div<AudioProgressStyledProps>`
  width: ${(props) => props.loaded}%;
  background: ${(props) => props.theme.colors.Gray400};
  height: 100%;
  border-radius: ${Radius.Small};
  position: absolute;
  bottom: 0;

  ${({ buffered }) =>
    buffered &&
    css`
      animation: buffer infinite 1s ease-in-out;

      @keyframes buffer {
        from {
          width: 10%;
          transform: translateX(0);
        }
        10% {
          width: 10%;
          transform: translateX(10%);
        }
        20% {
          width: 10%;
          transform: translateX(20%);
        }
        30% {
          width: 10%;
          transform: translateX(30%);
        }
        40% {
          width: 10%;
          transform: translateX(40%);
        }
        50% {
          width: 10%;
          transform: translateX(50%);
        }
        60% {
          width: 10%;
          transform: translateX(60%);
        }
        70% {
          width: 20%;
          transform: translateX(70%);
        }
        80% {
          width: 30%;
          transform: translateX(80%);
        }
        90% {
          width: 40%;
          transform: translateX(90%);
        }
        to {
          width: 50%;
          transform: translateX(100%);
        }
      }
    `}
`;

export const MediaRangeVolumeWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  height: 100%;
  background: ${(props) => props.theme.colors.Gray300};

  transform-origin: center right;

  transition: ${Transition.Slow};
  border-radius: ${Radius.Small};
  padding: ${PaddingSizes.Small};

  position: absolute;
  top: 0;
  right: ${Spacing.Large};

  transform: scale(0);
  & > * {
    transform: scale(0);
  }
`;

export const MediaRangeVolumeStyled = styled.input.attrs({ type: "range" })`
  outline: none;
  appearance: none;
  & {
    -webkit-appearance: none; /* remove estilo padrão do browser */
  }

  /* estiliza o marcador móvel */

  /* Chrome/Safari/Opera */
  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* remove estilo padrão do browser */
    background: ${(props) => props.theme.colors.Primary};
    border-radius: ${Radius.Small};
    margin-top: -7px;
    height: 20px;
    width: 11px;
    cursor: pointer;
  }

  /* Firefox */
  &::-moz-range-thumb {
    background: ${(props) => props.theme.colors.Primary};
    border-radius: ${Radius.Small};
    margin-top: -7px;
    height: 20px;
    width: 11px;
    cursor: pointer;
  }

  /* IE */
  &::-ms-thumb {
    background: ${(props) => props.theme.colors.Primary};
    border-radius: ${Radius.Small};
    margin-top: -7px;
    height: 20px;
    width: 11px;
    cursor: pointer;
  }

  /* estiliza a a barra do slider */

  /* Chrome/Safari/Opera */
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${progressLineWeight};
    background: ${(props) => props.theme.colors.Gray500};
    border-radius: ${Radius.Small};
  }

  /* Firefox */
  &::-moz-range-track {
    width: 100%;
    height: ${progressLineWeight};
    background: ${(props) => props.theme.colors.Gray500};
    border-radius: ${Radius.Small};
  }

  /* IE */
  &::-ms-track {
    width: 100%;
    height: ${progressLineWeight};
    background: ${(props) => props.theme.colors.Gray500};
    border-radius: ${Radius.Small};
  }
`;

export const MediaVolumeButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: inherit;

  position: relative;

  @media only screen and (min-width: ${Breakpoint.XSmall}) {
    &:hover ${MediaRangeVolumeWrapperStyled} {
      transform: scale(1);

      & > * {
        transform: scale(1);
      }
    }
  }
`;

export const MediaRateStyled = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-family: ${FontFamily.Primary};
`;
