/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { PaddingProps } from "@constants";
import React, { forwardRef, ReactNode } from "react";
import { Icon, Separator, FaIcon } from "@atomic";
import { ContentItemAction } from "./content-item-action.component";
import {
  ContentItemWrapperStyled,
  ContentItemLinkStyled,
  ContentItemInfosWrapperStyled,
  ContentItemInfosInnerStyled,
  ContentItemButtonWrapperStyled,
  ExternalContentItemLinkStyled,
  ContentItemButtonMoreStyled,
  ContentItemButtonActionStyled,
} from "./content-item.component.style";

interface ContentItemProps extends PaddingProps {
  iconSize?: "Small" | "Medium" | "Large";
  linkTo?: string;
  external?: boolean;
  alignCenter?: boolean;
  icon?: ReactNode;
  iconImage?: string;
  toggle?: ReactNode;
  hasHover?: boolean;
  active?: boolean;
  userSelect?: boolean;
  action?: () => void;
  actionIcon?: ReactNode;
  buttonText?: string;
  buttonIcon?: ReactNode;
  buttonActiveText?: string;
  buttonAction?: () => void;
  buttonActionIsDisabled?: boolean;
  date?: string;
  onClick?: () => void;
  buttonMore?: () => void;
  buttonActionExternalLink?: string;
}

export const ContentItem = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ContentItemProps>
>(
  (
    {
      iconSize,
      children,
      linkTo,
      external,
      icon,
      toggle,
      alignCenter,
      iconImage,
      action,
      actionIcon,
      active,
      buttonText,
      buttonIcon,
      buttonActiveText,
      buttonAction,
      buttonActionIsDisabled,
      buttonActionExternalLink,
      date,
      onClick,
      buttonMore,
      ...rest
    },
    ref
  ) => {
    const InnerContentButtonAction: React.FC = () => (
      <>
        {!buttonText && buttonIcon && buttonIcon}
        {buttonText && !buttonIcon && buttonText}
        {buttonText && buttonIcon && (
          <>
            {buttonIcon} <Separator type="Nano" /> {buttonText}
          </>
        )}
      </>
    );

    const InnerContent: React.FC = () => (
      <ContentItemWrapperStyled
        {...rest}
        alignCenter={alignCenter}
        onClick={onClick}
        active={active}
        ref={ref}
      >
        <Icon
          iconSize={iconSize}
          icon={icon}
          iconImage={iconImage}
          date={date}
        />

        <ContentItemInfosWrapperStyled>
          <ContentItemInfosInnerStyled alignCenter={alignCenter}>
            {children}
          </ContentItemInfosInnerStyled>

          {toggle}
          {(!!buttonText || !!buttonIcon || !!buttonMore) && (
            <>
              <Separator type="Small" />
              <ContentItemButtonWrapperStyled>
                {buttonMore && (
                  <ContentItemButtonMoreStyled
                    type="button"
                    kind="callToAction"
                    onClick={buttonMore}
                  >
                    <FaIcon.More />
                  </ContentItemButtonMoreStyled>
                )}
                <Separator type="Small" />
                {(!!buttonText || !!buttonIcon) &&
                  (buttonActionExternalLink ? (
                    <ExternalContentItemLinkStyled
                      href={buttonActionExternalLink}
                      target="_blank"
                    >
                      <ContentItemButtonActionStyled
                        type="button"
                        kind="tertiary"
                        activeText={buttonActiveText}
                        disabled={buttonActionIsDisabled}
                      >
                        <InnerContentButtonAction />
                      </ContentItemButtonActionStyled>
                    </ExternalContentItemLinkStyled>
                  ) : (
                    <ContentItemButtonActionStyled
                      type="button"
                      kind="tertiary"
                      onClick={buttonAction}
                      activeText={buttonActiveText}
                      disabled={buttonActionIsDisabled}
                    >
                      <InnerContentButtonAction />
                    </ContentItemButtonActionStyled>
                  ))}
              </ContentItemButtonWrapperStyled>
            </>
          )}
        </ContentItemInfosWrapperStyled>

        {action !== undefined && (
          <ContentItemAction icon={actionIcon} clickAction={action} />
        )}
      </ContentItemWrapperStyled>
    );

    return (
      <>
        {linkTo !== undefined ? (
          external ? (
            <ExternalContentItemLinkStyled href={linkTo} target="_blank">
              <InnerContent />
            </ExternalContentItemLinkStyled>
          ) : (
            <ContentItemLinkStyled to={linkTo}>
              <InnerContent />
            </ContentItemLinkStyled>
          )
        ) : (
          <InnerContent />
        )}
      </>
    );
  }
);
