import styled from "styled-components";

interface BoxStyledProps {
  h_align?:
    | "stretch"
    | "center"
    | "flex-start"
    | "flex-end"
    | "baseline"
    | "initial"
    | "space-between"
    | "space-around"
    | "space-between"
    | "initial"
    | "inherit";

  // src: https://www.w3schools.com/cssref/css3_pr_justify-content.asp
  v_align?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "initial"
    | "inherit"
    | "stretch";
  direction?: "row" | "column";
}

export const BoxStyled = styled.div<BoxStyledProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  justify-content: ${(props) => (props.h_align ? props.h_align : "flex-start")};
  align-items: ${(props) => (props.v_align ? props.v_align : "flex-start")};
`;
