/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import {
  CardContentStyled,
  CardHeaderInnerStyled,
  CardHeaderStyled,
  CardHeaderStyledProps,
  CardWrapperStyled,
  CardWrapperStyledProps,
} from "./card.component.style";

type CardHeaderProps = CardHeaderStyledProps;
export const CardHeader: React.FC<CardHeaderProps> = (props) => (
  <CardHeaderStyled {...props}>
    <CardHeaderInnerStyled>{props.children}</CardHeaderInnerStyled>
  </CardHeaderStyled>
);

CardHeader.defaultProps = {
  kind: "primary",
};

type CardProps = CardWrapperStyledProps;
export class Card extends React.Component<CardProps> {
  static Header = CardHeader;
  static Content = CardContentStyled;

  render() {
    return (
      <CardWrapperStyled {...this.props}>
        {this.props.children}
      </CardWrapperStyled>
    );
  }
}
