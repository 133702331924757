export const maskTel = (telephone: string): string => {
  telephone = telephone.replace(/\D/g, "");
  telephone = telephone.replace(/^(\d)/, "($1");
  telephone = telephone.replace(/(.{3})(\d)/, "$1) $2");
  if (telephone.length === 9) {
    telephone = telephone.replace(/(.{1})$/, "-$1");
  } else if (telephone.length === 10) {
    telephone = telephone.replace(/(.{2})$/, "-$1");
  } else if (telephone.length === 11) {
    telephone = telephone.replace(/(.{3})$/, "-$1");
  } else if (telephone.length === 12) {
    telephone = telephone.replace(/(.{4})$/, "-$1");
  } else if (telephone.length > 12) {
    telephone = telephone.replace(/(.{4})$/, "-$1");
  }
  return telephone;
};

export const maskCPF = (cpf: string): string => {
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return cpf;
};

export const maskDate = (date: string): string => {
  date = date.replace(/\D/g, "");
  date = date.replace(/(\d{2})(\d)/, "$1/$2");
  date = date.replace(/(\d{2})(\d)/, "$1/$2");

  return date;
};
