import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useQuery,
  QueryKey,
  UseQueryResult,
  UseQueryOptions,
} from "react-query";
import { StudentHistorySummary } from ".";

export const getStudentMaterialHistoriesKey = (): QueryKey =>
  "MaterialHistoriesControllerFindAll";

type QueryError = AxiosError<StudentHistorySummary>;
type QueryOptions = UseQueryOptions<StudentHistorySummary, QueryError>;

export const useGetStudentMaterialHistories = (
  options?: QueryOptions
): UseQueryResult<StudentHistorySummary, QueryError> => {
  const handleGetStudentMaterialHistories = async () => {
    try {
      const url = "/platform/students/material-histories";
      const { data } = await api.get<StudentHistorySummary>(url);
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  return useQuery<StudentHistorySummary, QueryError>({
    queryKey: getStudentMaterialHistoriesKey(),
    queryFn: handleGetStudentMaterialHistories,
    ...options,
  });
};
