import React from "react";
import { useFullScreenHandle } from "react-full-screen";
import { FaIcon } from "../fontawesome";
import {
  BodyFullScreen,
  ButtonFullScreen,
} from "./full-screen.component.style";

interface FullScreenI {
  children: React.ReactNode | React.ReactNode[];
}

export const FullScreen: React.FC<FullScreenI> = ({ children }) => {
  const handle = useFullScreenHandle();

  const toggleFullScreen = async () => {
    if (!handle.active) {
      return await handle.enter();
    }
    return await handle.exit();
  };

  return (
    <BodyFullScreen handle={handle} isFull={handle.active}>
      <ButtonFullScreen
        onClick={toggleFullScreen}
        isFull={handle.active}
        kind={"callToAction"}
        disableActiveState
      >
        {handle.active ? <FaIcon.Compress /> : <FaIcon.Expand />}
      </ButtonFullScreen>
      {children}
    </BodyFullScreen>
  );
};
