import { api } from "@utils";
import { LOCAL_STORAGE_STUDENT } from "@variables";
import { AxiosError } from "axios";
import {
  useQuery,
  QueryKey,
  UseQueryResult,
  UseQueryOptions,
} from "react-query";
import { Student } from ".";

export const getStudentInfoKey = (): QueryKey => "StudentsControllerFindOne";

type QueryError = AxiosError<Student>;
type QueryOptions = UseQueryOptions<Student, QueryError>;

export const useGetStudentInfo = (
  options?: QueryOptions
): UseQueryResult<Student, QueryError> => {
  const studentLocalStorage = localStorage.getItem(LOCAL_STORAGE_STUDENT);
  const student = JSON.parse(studentLocalStorage || "{}") as Student;

  const handleGetStudentInfo = async () => {
    try {
      const url = "/platform/students/info";
      const { data } = await api.get<Student>(url);
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  return useQuery<Student, QueryError>({
    queryKey: getStudentInfoKey(),
    queryFn: handleGetStudentInfo,
    placeholderData: student,
    ...options,
  });
};
