import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from "react-query";
import { CreateMaterialHistory, MaterialHistory } from ".";

type MutationError = AxiosError<MaterialHistory>;
type UpdateMaterialHistory = Partial<CreateMaterialHistory>;
type MutationOptions = UseMutationOptions<
  MaterialHistory,
  MutationError,
  UpdateMaterialHistory
>;

export const usePostStudentMaterialHistory = (
  options?: MutationOptions
): UseMutationResult<MaterialHistory, MutationError, UpdateMaterialHistory> => {
  const handlePostStudentMaterialHistory = async (
    bodyData: UpdateMaterialHistory
  ) => {
    try {
      const url = "/platform/students/material-histories";
      const { data } = await api.post<MaterialHistory>(url, bodyData);
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<MaterialHistory, MutationError, UpdateMaterialHistory>({
    mutationFn: handlePostStudentMaterialHistory,
    ...options,
  });
};
