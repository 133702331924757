import React from "react";

export const CloseIcon: React.FC = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="12.4443"
      width="2.19988"
      height="17.5991"
      rx="0.5"
      transform="rotate(45 12.4443 0)"
      fill="#303044"
    />
    <rect
      x="14"
      y="12.4453"
      width="2.19988"
      height="17.5991"
      rx="0.5"
      transform="rotate(135 14 12.4453)"
      fill="#303044"
    />
  </svg>
);
