import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Hbox, Separator } from "@atomic";
import { Tab, TabsWrapperStyled } from "./tab-navigation.component.style";

interface TabItem {
  component: JSX.Element;
  buttonTitle: string;
  link?: string;
}

interface TabNavigationProps {
  currentItemId?: number;
  tabContent: TabItem[];
}

export const TabNavigation: FC<TabNavigationProps> = ({
  currentItemId,
  tabContent,
}) => {
  const [tabId, setTabId] = useState(currentItemId ? currentItemId : 0);

  useEffect(() => {
    currentItemId !== undefined && setTabId(currentItemId);
  }, [currentItemId]);

  return (
    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
      <TabsWrapperStyled>
        <Hbox>
          {tabContent.map(({ link, buttonTitle }, index) => {
            const Wrapper = link ? Link : MyFragment;
            return (
              <Wrapper key={buttonTitle} to={`/student/profile/${link}`}>
                <Hbox.Item noGrow onClick={() => setTabId(index)}>
                  <Tab active={index === tabId}>{buttonTitle}</Tab>
                </Hbox.Item>
              </Wrapper>
            );
          })}
        </Hbox>
      </TabsWrapperStyled>
      <Separator type="Medium" />
      <>{tabContent[tabId]?.component}</>
    </Col>
  );
};

const MyFragment: FC = ({ children }) => <div>{children}</div>;
