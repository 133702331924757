import { Spacing, Breakpoint } from "@constants";
import {
  Col as FlexCol,
  Grid as FlexGrid,
  Row as FlexRow,
} from "react-flexbox-grid";
import styled, { createGlobalStyle, css } from "styled-components";

export const GridConfigs = {
  xs: {
    containerWidth: "90%",
    containerMaxWidth: "90%",
    gap: `${Spacing.XNano}`,
  },
  sm: {
    containerWidth: "560px",
    containerMaxWidth: "90%",
    gap: `${Spacing.Nano}`,
  },
  md: {
    containerWidth: "640px",
    containerMaxWidth: "90%",
    gap: `${Spacing.Nano}`,
  },
  lg: {
    containerWidth: "976px",
    containerMaxWidth: "88%",
    gap: `${Spacing.Small}`,
  },
  xl: {
    containerWidth: "1200px",
    containerMaxWidth: "82%",
    gap: `${Spacing.Small}`,
  },
};

export interface RowProps {
  $mb?: boolean;
  $mt?: boolean;
  $no_gutter?: boolean;
  $overflow_hidden?: boolean;
  h_align?: string;
  ref?: any;
}

export const Row = styled(FlexRow)<RowProps>`
  margin-bottom: ${(props) => (props.$mb ? Spacing.SemiSmall : "0px")};
  margin-top: ${(props) => (props.$mt ? Spacing.SemiSmall : "0px")};
  ${(props) => props.$overflow_hidden && "overflow: hidden;"};

  ${(props: RowProps) =>
    props.h_align &&
    `
      justify-content: ${props.h_align};
    `}
`;

const gutterWidth = `${Spacing.Nano}`;

export interface ColProps {
  h_align?: string;
  v_align?: string;
  $mb?: boolean;
  $mt?: boolean;
  $flex_col?: boolean;
  $no_gutter?: boolean;
  $xs_hidden?: boolean;
  $sm_hidden?: boolean;
  $md_hidden?: boolean;
  $lg_hidden?: boolean;
  $xl_hidden?: boolean;
  $row_like?: boolean;
  ref?: any;
}

export const Col = styled(FlexCol)<ColProps>`
  ${(props) =>
    props.$mt
      ? css`
          padding-top: calc(${GridConfigs.xs.gap} * 2);

          @media only screen and (min-width: ${Breakpoint.XLarge}) {
            padding-top: calc(${GridConfigs.xl.gap} * 2);
          }

          @media only screen and (max-width: ${Breakpoint.Large}) {
            padding-top: calc(${GridConfigs.lg.gap} * 2);
          }

          @media only screen and (max-width: ${Breakpoint.Medium}) {
            padding-top: calc(${GridConfigs.md.gap} * 2);
          }

          @media only screen and (max-width: ${Breakpoint.Small}) {
            padding-top: calc(${GridConfigs.sm.gap} * 2);
          }

          @media only screen and (max-width: ${Breakpoint.XSmall}) {
            padding-top: calc(${GridConfigs.xs.gap} * 2);
          }
        `
      : css`
          margin-top: 0;
        `};

  ${(props) =>
    props.$mb
      ? css`
          padding-bottom: calc(${GridConfigs.xs.gap} * 2);

          @media only screen and (min-width: ${Breakpoint.XLarge}) {
            padding-bottom: calc(${GridConfigs.xl.gap} * 2);
          }

          @media only screen and (max-width: ${Breakpoint.Large}) {
            padding-bottom: calc(${GridConfigs.lg.gap} * 2);
          }

          @media only screen and (max-width: ${Breakpoint.Medium}) {
            padding-bottom: calc(${GridConfigs.md.gap} * 2);
          }

          @media only screen and (max-width: ${Breakpoint.Small}) {
            padding-bottom: calc(${GridConfigs.sm.gap} * 2);
          }

          @media only screen and (max-width: ${Breakpoint.XSmall}) {
            padding-bottom: calc(${GridConfigs.xs.gap} * 2);
          }
        `
      : css`
          margin-bottom: 0;
        `};

  ${(props: GridProps) => props.$no_gutter && "padding: 0;"};

  ${(props: ColProps) =>
    props.$row_like &&
    `
    box-sizing: border-box;
    display: flex;
    flex-flow: inherit;
    flex-direction: row;
    padding-left: 0 !important;
    padding-right: 0 !important;
    `}

  ${(props: ColProps) =>
    props.h_align &&
    `
      display: flex;
      flex-direction: column;
      align-items: ${props.h_align};
      text-align: ${props.h_align};
    `}

  ${(props: ColProps) =>
    props.v_align &&
    `
      display: flex;
      flex-direction: column;
      justify-content: ${props.v_align};
    `}

    ${(props: ColProps) =>
    props.$flex_col &&
    `
      display: inline-flex;
      flex-direction: column;
      flex: auto;
      flex-basis: 100%;
    `}

  ${(props: ColProps) =>
    props.$xs_hidden &&
    `
      @media only screen and (max-width: 575px) {
        display: none;
      }
    `}

  ${(props: ColProps) =>
    props.$sm_hidden &&
    `
      @media only screen and (max-width: 767px) and (min-width: 576px) {
        display: none;
      }
    `}

    ${(props: ColProps) =>
    props.$md_hidden &&
    `
      @media only screen and (max-width: 991px) and (min-width: 768px) {
        display: none;
      }
    `}

    ${(props: ColProps) =>
    props.$lg_hidden &&
    `
      @media only screen and (max-width: 1199px) and (min-width: 992px) {
        display: none;
      }
    `}

        ${(props: ColProps) =>
    props.$xl_hidden &&
    `
      @media only screen and (min-width: 1200px) {
        display: none;
      }
    `}
`;

export interface GridProps {
  $expanded?: boolean;
  $no_gutter?: boolean;
  $xs_hidden?: boolean;
  $sm_hidden?: boolean;
  $md_hidden?: boolean;
  $lg_hidden?: boolean;
  $xl_hidden?: boolean;
  ref?: any;
}

export const Grid = styled(FlexGrid)<GridProps>`
  padding: 0
    ${(props: GridProps) => (props.$no_gutter ? "0" : `${gutterWidth}px`)};
  width: ${(props: GridProps) => props.$expanded && "100%"};

  ${(props: GridProps) =>
    props.$xs_hidden &&
    `
      @media only screen and (max-width: 575px) {
        display: none;
      }
    `}
  ${(props: GridProps) =>
    props.$sm_hidden &&
    `
      @media only screen and (max-width: 767px) and (min-width: 576px) {
        display: none;
      }
    `}
    ${(props: GridProps) =>
    props.$md_hidden &&
    `
      @media only screen and (max-width: 991px) and (min-width: 768px) {
        display: none;
      }
    `}
    ${(props: GridProps) =>
    props.$lg_hidden &&
    `
      @media only screen and (max-width: 1199px) and (min-width: 992px) {
        display: none;
      }
    `}
        ${(props: GridProps) =>
    props.$xl_hidden &&
    `
      @media only screen and (min-width: 1200px) {
        display: none;
      }
    `}
`;

export const GridSystemStyled = createGlobalStyle`
  @media only screen and (min-width: ${Breakpoint.XLarge}) {
    .container {
      width: ${GridConfigs.xl.containerWidth};
      max-width: ${GridConfigs.xl.containerMaxWidth};
      padding-right: ${GridConfigs.xl.gap};
      padding-left: ${GridConfigs.xl.gap};
    }

    .row {
      margin-right: -${GridConfigs.xl.gap};
      margin-left: -${GridConfigs.xl.gap};
    }

    .col-xl,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl-offset-0,
    .col-xl-offset-1,
    .col-xl-offset-2,
    .col-xl-offset-3,
    .col-xl-offset-4,
    .col-xl-offset-5,
    .col-xl-offset-6,
    .col-xl-offset-7,
    .col-xl-offset-8,
    .col-xl-offset-9,
    .col-xl-offset-10,
    .col-xl-offset-11,
    .col-xl-offset-12 {
      padding-right: ${GridConfigs.xl.gap};
    padding-left: ${GridConfigs.xl.gap};
    }
  }

  @media only screen and (max-width: ${Breakpoint.Large}) {
    .container {
      width: ${GridConfigs.lg.containerWidth};
      max-width: ${GridConfigs.lg.containerMaxWidth};
      padding-right: ${GridConfigs.lg.gap};
      padding-left: ${GridConfigs.lg.gap};
    }

    .row {
      margin-right: -${GridConfigs.lg.gap};
      margin-left: -${GridConfigs.lg.gap};
    }

    .col-lg,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-offset-0,
    .col-lg-offset-1,
    .col-lg-offset-2,
    .col-lg-offset-3,
    .col-lg-offset-4,
    .col-lg-offset-5,
    .col-lg-offset-6,
    .col-lg-offset-7,
    .col-lg-offset-8,
    .col-lg-offset-9,
    .col-lg-offset-10,
    .col-lg-offset-11,
    .col-lg-offset-12{
      padding-right: ${GridConfigs.lg.gap};
    padding-left: ${GridConfigs.lg.gap};
    }
  }

  @media only screen and (max-width: ${Breakpoint.Medium}) {
    .container {
      width: ${GridConfigs.md.containerWidth};
      max-width: ${GridConfigs.md.containerMaxWidth};
      padding-right: ${GridConfigs.md.gap};
      padding-left: ${GridConfigs.md.gap};
    }

    .row {
      margin-right: -${GridConfigs.md.gap};
      margin-left: -${GridConfigs.md.gap};
    }

    .col-md,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-offset-0,
    .col-md-offset-1,
    .col-md-offset-2,
    .col-md-offset-3,
    .col-md-offset-4,
    .col-md-offset-5,
    .col-md-offset-6,
    .col-md-offset-7,
    .col-md-offset-8,
    .col-md-offset-9,
    .col-md-offset-10,
    .col-md-offset-11,
    .col-md-offset-12{
      padding-right: ${GridConfigs.md.gap};
    padding-left: ${GridConfigs.md.gap};
    }
  }

  @media only screen and (max-width: ${Breakpoint.Small}) {
    .container {
      width: ${GridConfigs.sm.containerWidth};
      max-width: ${GridConfigs.sm.containerMaxWidth};
      padding-right: ${GridConfigs.sm.gap};
      padding-left: ${GridConfigs.sm.gap};
    }

    .row {
      margin-right: -${GridConfigs.sm.gap};
      margin-left: -${GridConfigs.sm.gap};
    }

    .col-sm,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-offset-0,
    .col-sm-offset-1,
    .col-sm-offset-2,
    .col-sm-offset-3,
    .col-sm-offset-4,
    .col-sm-offset-5,
    .col-sm-offset-6,
    .col-sm-offset-7,
    .col-sm-offset-8,
    .col-sm-offset-9,
    .col-sm-offset-10,
    .col-sm-offset-11,
    .col-sm-offset-12{
      padding-right: ${GridConfigs.sm.gap};
    padding-left: ${GridConfigs.sm.gap};
    }
  }

  @media only screen and (max-width: ${Breakpoint.XSmall}) {
    .container {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    width: ${GridConfigs.xs.containerWidth};
    max-width: ${GridConfigs.xs.containerMaxWidth};
    padding-right: ${GridConfigs.xs.gap};
    padding-left: ${GridConfigs.xs.gap};
  }

  .row {
    margin-right: -${GridConfigs.xs.gap};
    margin-left: -${GridConfigs.xs.gap};
  }

  .col-xs,
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-offset-0,
  .col-xs-offset-1,
  .col-xs-offset-2,
  .col-xs-offset-3,
  .col-xs-offset-4,
  .col-xs-offset-5,
  .col-xs-offset-6,
  .col-xs-offset-7,
  .col-xs-offset-8,
  .col-xs-offset-9,
  .col-xs-offset-10,
  .col-xs-offset-11,
  .col-xs-offset-12 {
    padding-right: ${GridConfigs.xs.gap};
    padding-left: ${GridConfigs.xs.gap};
  }
  }
`;
