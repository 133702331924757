import { Breakpoint, Transition } from "@constants";
import styled, { css } from "styled-components";
import { GridConfigs } from "../grid";

interface SlideItemProps {
  item_width: number;
  snap_align?: boolean;
}

interface SlideContainerProps {
  showButtons: boolean;
}

interface SlideDotsProps {
  isActive: boolean;
}

const SlideButton = styled.button`
  display: none;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;

  padding: 0;
  z-index: 1;

  height: 100%;
  width: 7%;

  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 1.9em;

  cursor: pointer;

  &:hover > svg {
    transition: All ${Transition.Fast} ease-in-out;
    transform: scale(1.5);
  }

  &:active > svg {
    transform: translateY(2px);
  }
`;

export const SlideContainer = styled.div<SlideContainerProps>`
  position: relative;
  overflow: hidden;

  ${({ showButtons }) =>
    showButtons &&
    css`
      &:hover ${SlideButton} {
        display: flex;
      }
    `}

  &:hover {
    cursor: grab;
  }

  & *:active {
    cursor: grabbing;
  }
`;

export const SlideItems = styled.div`
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;

  @media only screen and (min-width: calc(${Breakpoint.XLarge})) {
    margin-left: -${GridConfigs.xl.gap};
    margin-right: -${GridConfigs.xl.gap};
  }

  @media only screen and (max-width: calc(${Breakpoint.Large})) {
    margin-left: -${GridConfigs.lg.gap};
    margin-right: -${GridConfigs.lg.gap};
  }

  @media only screen and (max-width: calc(${Breakpoint.Small})) {
    margin-left: -${GridConfigs.sm.gap};
    margin-right: -${GridConfigs.sm.gap};
  }

  @media only screen and (max-width: calc(${Breakpoint.Medium})) {
    margin-left: -${GridConfigs.md.gap};
    margin-right: -${GridConfigs.md.gap};
  }

  @media only screen and (max-width: calc(${Breakpoint.XSmall})) {
    margin-left: -${GridConfigs.xs.gap};
    margin-right: -${GridConfigs.xs.gap};
  }
`;

export const SlideItem = styled.div<SlideItemProps>`
  z-index: 0;
  scroll-snap-align: center start;
  min-width: ${({ item_width }) => item_width}px;
  scroll-snap-align: ${({ snap_align }) =>
    snap_align ? "center start" : "none"};
`;

export const SlideButtonRight = styled(SlideButton)`
  right: 0;
  background: ${({ theme }) =>
    css`linear-gradient( to right,  rgba(255, 255, 255, 0) 20%, ${theme.colors.White} 100%)`};
`;

export const SlideButtonLeft = styled(SlideButton)`
  left: 0;
  background: ${({ theme }) =>
    css`linear-gradient( to right, ${theme.colors.White} 20%, rgba(255, 255, 255, 0) 100%)`};
`;

export const SlideDots = styled.ul`
  display: flex;
  justify-content: flex-end;

  font-size: 1.7em;
  color: ${({ theme }) => theme.colors.Black};
`;

export const SlideDot = styled.li<SlideDotsProps>`
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
`;
