/* eslint-disable no-shadow */
import { Border, FontSize, FontWeight, Radius, Spacing } from "@constants";
import styled from "styled-components";

enum CardKind {
  primary = "primary",
}

export interface CardHeaderStyledProps {
  kind?: keyof typeof CardKind;
  imageUrl?: string;
}

const imageProportion = "62.5%";

export const CardHeaderStyled = styled.div<CardHeaderStyledProps>`
  border-radius: ${Radius.Small} ${Radius.Small} 0 0;

  background: ${(props) =>
      props.imageUrl ? props.theme.colors.Gray200 : props.theme.colors.Primary}
    url(${(props) => props.imageUrl}) center center;
  background-size: cover;

  ${(props) =>
    props?.imageUrl &&
    `
    height: 0;
    padding-bottom: ${imageProportion};
  `};

  position: relative;
`;

export const CardHeaderInnerStyled = styled.div`
  padding: ${Spacing.Small};

  text-align: center;

  font-size: ${FontSize.Medium};
  font-weight: ${FontWeight.Bold};

  height: 100%;
  width: 100%;

  position: absolute;
`;

interface CardContentStyledProps {
  h_align?: string;
  v_align?: string;
  flexSpacing?: boolean;
}

const flexSpacing = `
display: flex;
flex: 1;
flex-direction: column;
`;

export const CardContentStyled = styled.div<CardContentStyledProps>`
  position: relative;
  padding: ${Spacing.SemiSmall};
  padding-bottom: calc(${Spacing.SemiSmall} + ${Spacing.XNano});

  ${(props) => props.flexSpacing && flexSpacing};

  align-items: ${(props: CardContentStyledProps) =>
    props.h_align || "flex-start"};
  justify-content: ${(props: CardContentStyledProps) =>
    props.v_align || "flex-start"};
`;

export interface CardWrapperStyledProps {
  clickable?: boolean;
  kind?: "primary" | "secondary";
  fullHeight?: boolean;
}

export const CardWrapperStyled = styled.div<CardWrapperStyledProps>`
  position: relative;

  border-radius: ${Radius.Small};

  box-shadow: ${Border.All} ${(props) => props.theme.colors.Gray200};

  background-color: ${(props) => props.theme.colors.Gray100};

  width: 100%;
  height: ${(props) => (props.fullHeight ? "100%" : "auto")};

  flex: 1;
  cursor: ${(props) => (props.clickable ? "pointer" : "auto")};
  text-decoration: none;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.clickable &&
    `
      &:hover {
        background-color: ${props.theme.colors.Gray200};
      }
  `};

  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
`;
