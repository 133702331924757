import { Author as AuthorI } from "@interfaces/kerala-api";
import { Author } from "@organism";
import { ENV_SHOW_DISQUS } from "@variables";
import React from "react";
import { DisqusComment, H1, Separator } from "@atomic";

interface MaterialBodyPageProps {
  authors?: AuthorI[];
  children: React.ReactNode;
  duration?: string | number;
  title: string;
}

export const MaterialBodyPage: React.FC<MaterialBodyPageProps> = ({
  authors,
  children,
  duration,
  title,
}) => {
  return (
    <>
      <H1>{title}</H1>
      {!!authors && (
        <>
          <Separator type="Small" />
          <Author authors={authors} duration={duration} />
        </>
      )}
      <Separator type="Small" />

      {children}

      <Separator type="Large" />

      {ENV_SHOW_DISQUS && (
        <>
          <DisqusComment title={title} />
          <Separator type="Large" />
        </>
      )}
    </>
  );
};
