import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from "react-query";
import { ValidationToken } from ".";

type MutationError = AxiosError<void>;
type MutationOptions = UseMutationOptions<void, MutationError, ValidationToken>;

export const usePostEmailToken = (
  options?: MutationOptions
): UseMutationResult<void, MutationError, ValidationToken> => {
  const handlePostEmailToken = async (bodyData: ValidationToken) => {
    try {
      const url = "/email/token";
      const { data } = await api.post<void>(url, bodyData);
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<void, MutationError, ValidationToken>({
    mutationFn: handlePostEmailToken,
    ...options,
  });
};
