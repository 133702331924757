import { Spacing, Radius, Color } from "@constants";
import styled, { css } from "styled-components";

export const CheckboxWrapperStyled = styled.div`
  padding: ${Spacing.Nano};

  margin-top: -${Spacing.Nano};
  margin-left: -${Spacing.Nano};

  cursor: pointer;
`;

export const DefaultCheckboxStyled = styled.input`
  width: 0px;
  height: 0px;

  position: absolute;
`;

interface CheckboxBoxStyledProps {
  checked: boolean;
  disabled?: boolean;
}

export const CheckboxBoxStyled = styled.span<CheckboxBoxStyledProps>`
  width: ${Spacing.SemiSmall};
  height: ${Spacing.SemiSmall};

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${Color.Transparent};
  box-shadow: inset 0px 0px 0px 2px ${(props) => props.theme.colors.Gray400};

  border-radius: ${Radius.Small};

  transition: background-color 0.2s ease-out;

  ${(props) =>
    props.checked &&
    css`
      background-color: ${props.theme.colors.Primary};
      box-shadow: inset 0px 0px 0px 2px ${props.theme.colors.Primary};
      cursor: default;
    `};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `};
`;

export const CheckboxArrowStyled = styled.span<CheckboxBoxStyledProps>`
  display: inline-block;
  transform: rotate(45deg);
  height: ${Spacing.Nano};
  width: ${Spacing.XNano};
  border-bottom: 2px solid ${Color.Transparent};
  border-right: 2px solid ${Color.Transparent};

  margin-bottom: 3px;

  ${(props) =>
    props.checked &&
    css`
      border-bottom: 2px solid ${props.theme.colors.White};
      border-right: 2px solid ${props.theme.colors.White};
    `};
`;
