import { useAuthContext } from "@contexts";
import { api, auxDateDDMMYYYYinverter } from "@utils";
import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from "react-query";
import { UpdateStudent } from ".";

type MutationError = AxiosError<number>;
type Student = Partial<UpdateStudent>;
type MutationOptions = UseMutationOptions<number, MutationError, Student>;

export const usePutStudentInfo = (
  options?: MutationOptions
): UseMutationResult<number, MutationError, Student> => {
  const { email, ...defaultStudent } = useAuthContext().userLocalStorage;

  const handlePutStudentInfo = async (student: Student) => {
    try {
      const url = "/platform/students/update";
      const updateStudent = { ...defaultStudent, ...student };

      const dateWithoutBar = updateStudent?.date_of_birth.replaceAll("/", "");
      const date_of_birth = auxDateDDMMYYYYinverter(dateWithoutBar);
      const telephone = +`${updateStudent?.telephone}`?.replace(/\D/g, "");

      const body = { ...updateStudent, date_of_birth, telephone };
      const { data } = await api.put<number>(url, body);
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<number, MutationError, Student>({
    mutationFn: handlePutStudentInfo,
    ...options,
  });
};
