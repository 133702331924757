import { Color } from "@constants";

export const theme = {
  border: {
    All: "inset 0px 0px 0px 1px",
    Top: "inset 0px 1px 0px 0px",
    Bottom: "inset 0px -1px 0px",
    Left: "inset 1px 0px 0px 0px",
    Right: "inset -1px 0px 0px 0px",
  },
  radius: {
    Small: "0.3rem",
    Medium: "0.6rem",
    Large: "1.6rem",
  },
  breakpoints: {
    XSmall: "575px",
    Small: "767px",
    Medium: "991px",
    Large: "1199px",
    XLarge: "1200px",
  },
  font: {
    Family: "Poppins",
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    LineHeight: {
      XSmall: "1em",
      Small: "1.25em",
      Medium: "1.4em",
      Large: "1.6em",
    },
  },
  spacings: {
    XNano: "0.3rem",
    Nano: "0.6rem",
    Small: "0.9rem",
    SemiSmall: "1.2rem",
    Medium: "1.8rem",
    SemiLarge: "2.7rem",
    Large: "3.6rem",
    XLarge: "5.4rem",
    XXLarge: "7.2rem",
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },
  transition: {
    Fast: "0.2s",
    Slow: "1s",
  },
};

export const darkTheme = {
  title: "dark",
  colors: {
    Primary: Color.Primary100,
    Secondary: Color.Secondary100,

    Black: "#FFFFFF",
    White: "#1B1B2B",

    BrandContrast: Color.BrandContrast200,

    Transparent: "transparent",

    Success: "#2ABB4A",

    Error: "#ED4848",

    Warning: "#D4AD22",

    Info: "#1F8AC6",

    Gray100: Color.Gray900,

    Gray200: Color.Gray800,

    Gray300: Color.Gray700,

    Gray400: Color.Gray600,

    Gray500: Color.Gray500,

    Gray600: Color.Gray400,

    Gray700: Color.Gray300,

    Gray800: Color.Gray200,

    Gray900: Color.Gray100,
  },
  ...theme,
};

export const lightTheme = {
  title: "light",
  colors: {
    Primary: Color.Primary200,
    Secondary: Color.Secondary200,

    Black: "#1B1B2B",
    White: "#FFFFFF",

    BrandContrast: Color.BrandContrast100,

    Transparent: "transparent",

    Success: Color.Success,

    Error: Color.Error,

    Warning: Color.Warning,

    Info: Color.Info,

    Gray900: Color.Gray900,

    Gray800: Color.Gray800,

    Gray700: Color.Gray700,

    Gray600: Color.Gray600,

    Gray500: Color.Gray500,

    Gray400: Color.Gray400,

    Gray300: Color.Gray300,

    Gray200: Color.Gray200,

    Gray100: Color.Gray100,
  },
  ...theme,
};
