import React, { forwardRef, ReactNode } from "react";
import { ActionButtonWrapperStyled } from "./action-button.component.style";

interface ActionButtonProps {
  icon: ReactNode;
  active?: boolean;
  action?: () => void;
}

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ icon, action, active }, ref) => {
    return (
      <ActionButtonWrapperStyled onClick={action} ref={ref} active={active}>
        {icon}
      </ActionButtonWrapperStyled>
    );
  }
);
