/* eslint-disable @typescript-eslint/no-var-requires */
import { Breakpoint } from "@constants";
import { useResize } from "@hooks";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";
import {
  envLogoDesktopLight,
  envLogoDesktopDark,
  envLogoMobileLight,
  envLogoMobileDark,
  envLogoLight,
  envLogoDark,
} from "../../../configs/brand-themes.config";
import { LogoStyled, LogoDefaultStyled } from "./logo.component.style";

const envLogoDesktopLightAbsolutePath = require("@assets/" + envLogoDesktopLight);
const envLogoDesktopDarkAbsolutePath = require("@assets/" + envLogoDesktopDark);

const envLogoMobileLightAbsolutePath = require("@assets/" + envLogoMobileLight);
const envLogoMobileDarkAbsolutePath = require("@assets/" + envLogoMobileDark);

const envLogoLightAbsolutePath = require("@assets/" + envLogoLight);
const envLogoDarkAbsolutePath = require("@assets/" + envLogoDark);

export const Logo: React.FC = () => {
  const BreakpointSmall = Number(Breakpoint.Small.replace("px", ""));

  const [srcLogo, setSrcLogo] = useState(envLogoDesktopLightAbsolutePath.default);

  const isLight = useTheme().title === "light";

  const [Width] = useResize();

  useMemo(() => {
    if (BreakpointSmall >= Width) {
      return isLight
        ? setSrcLogo(envLogoMobileLightAbsolutePath.default)
        : setSrcLogo(envLogoMobileDarkAbsolutePath.default);
    }

    return isLight
      ? setSrcLogo(envLogoDesktopLightAbsolutePath.default)
      : setSrcLogo(envLogoDesktopDarkAbsolutePath.default);
  }, [BreakpointSmall, Width, isLight]);

  return (
    <Link to="/">
      <LogoStyled src={srcLogo} />
    </Link>
  );
};

export const LogoDefault: React.FC = () => {
  const isLight = useTheme().title === "light";

  return (
    <Link to="/">
      {isLight ? (
        <LogoDefaultStyled src={envLogoLightAbsolutePath.default} />
      ) : (
        <LogoDefaultStyled src={envLogoDarkAbsolutePath.default} />
      )}
    </Link>
  );
};
