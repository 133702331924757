/* eslint-disable curly */
import { usePostStudentMaterialHistory } from "@services";
import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFullScreenHandle } from "react-full-screen";
import ReactPlayer from "react-player";

interface Progress {
  played: number;
  loaded: number;
  playedSeconds: number;
  loadedSeconds: number;
}

interface Controls {
  isPlay: boolean;
  isMuted: boolean;
  duration: number;
  handleVolume: number;
  playbackRate: number;
  progress: Progress;
}

interface MediaPlayerProps {
  material_id: number;
}

const mediaSpeed: number[] = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
const controlsDefaultState: Controls = {
  isPlay: false,
  isMuted: false,
  handleVolume: 1, //Volume max is 1 and min is 0, step is 0.1
  playbackRate: 1, //Always started 1
  duration: 0,
  progress: {
    played: 0,
    loaded: 0,
    playedSeconds: 0,
    loadedSeconds: 0,
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useMediaPlayer = ({ material_id }: MediaPlayerProps) => {
  const [isBuffer, setIsBuffer] = useState(false);
  const [changeTimeMedia, setChangeTimeMedia] = useState(0);
  const [handleMediaRate, setHandleMediaRate] = useState(2);
  const [isHideControl, setIsHideControl] = useState(false);
  const [controls, setControls] = useState<Controls>(controlsDefaultState);

  const handle = useFullScreenHandle();
  const PlayerRef = useRef<ReactPlayer>(null);

  const materialHistories = usePostStudentMaterialHistory();

  useMemo(() => {
    if (!PlayerRef?.current) return;
    PlayerRef.current.seekTo(changeTimeMedia, "fraction");
  }, [changeTimeMedia]);

  useMemo(() => {
    setControls((prevState) => ({
      ...prevState,
      playbackRate: mediaSpeed[handleMediaRate],
    }));
  }, [handleMediaRate]);

  useEffect(() => {
    if (!controls.isPlay) return;

    materialHistories.mutate({
      duration: Math.ceil(controls.duration / 60),
      history_type: "view",
      material_id,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls.duration, controls.isPlay, material_id]);

  const toggleFullScreen = useCallback(async () => {
    if (!handle.active) {
      return await handle.enter();
    }
    return await handle.exit();
  }, [handle]);

  const secondsToMinutes = useCallback((time: number) => {
    return (
      Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
    );
  }, []);

  const playedToPercentage = useCallback((progress: number) => {
    return Math.ceil(100 * progress);
  }, []);

  const handleProgress = useCallback((progress: Progress) => {
    const percentage = Math.ceil(100 * progress.played);
    setControls((prevState) => ({
      ...prevState,
      progress: progress,
    }));

    if (percentage > 90) {
      return true;
    }

    return false;
  }, []);

  const handleHideControl = useCallback(
    (evt: MouseEvent<HTMLDivElement>) => {
      if (!controls.isPlay) {
        return setIsHideControl(false);
      }

      if (evt.type === "click") {
        return setIsHideControl((prevState) => !prevState);
      }
    },
    [controls.isPlay]
  );

  return {
    handle,
    isBuffer,
    controls,
    PlayerRef,
    mediaSpeed,
    setControls,
    setIsBuffer,
    isHideControl,
    handleProgress,
    handleMediaRate,
    secondsToMinutes,
    toggleFullScreen,
    handleHideControl,
    setHandleMediaRate,
    setChangeTimeMedia,
    playedToPercentage,
  };
};
