import { ConfigLabel, ConfigRegisterLabel } from "@hooks";
import { createContext } from "react";

export interface ConfigContextData {
  getPathLabel: () => ConfigLabel;
  getRegisterLabel: () => ConfigRegisterLabel;
  greeting: string;
}

export const ConfigContext = createContext<ConfigContextData>(
  {} as ConfigContextData
);
