/* eslint-disable @typescript-eslint/prefer-optional-chain */
import React from "react";
import {
  RadioButtonStyled,
  RadioButtonMarkerStyled,
} from "./radio.component.style";

interface RadioButtonProps {
  disabled?: boolean;
  checked?: boolean;
  active?: boolean;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  checked,
  disabled,
  active,
}) => {
  // const [isChecked, setIsChecked] = useState(checked || false);

  return (
    <RadioButtonStyled checked={checked} active={active} disabled={disabled}>
      <RadioButtonMarkerStyled
        checked={checked}
        active={active}
        disabled={disabled}
      />
    </RadioButtonStyled>
  );
};
