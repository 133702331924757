import { useAuthContext } from "@contexts";
import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useQuery,
  QueryKey,
  UseQueryResult,
  UseQueryOptions,
} from "react-query";
import { LearningUnit } from ".";

interface GetOneLearningUnitProps {
  pathId: number;
  classId: number;
  learningUnitId: number;
}

export const getPathsLearningUnitsKey = (
  props: GetOneLearningUnitProps
): QueryKey => [
  "LearningUnitsControllerFindOne",
  ...Object.entries(props).flat(),
];

type QueryError = AxiosError<LearningUnit>;
type QueryOptions = UseQueryOptions<LearningUnit, QueryError>;

export const useGetPathsLearningUnits = (
  { classId, learningUnitId, pathId }: GetOneLearningUnitProps,
  options?: QueryOptions
): UseQueryResult<LearningUnit, QueryError> => {
  const { userLocalStorage } = useAuthContext();
  const { all_classes, class_id, all_class_ids } = userLocalStorage;

  const handleGetPathsLearningUnits = async () => {
    try {
      const url = `/platform/paths/${pathId}/learning-units/${learningUnitId}`;

      const filter_class_ids_list = `${all_classes
        .flatMap(({ _id }) => _id)
        .concat(class_id)
        .toString()}`;

      const params = {
        class_id: classId,
        filter_class_ids_list:
          all_class_ids.toString() || filter_class_ids_list,
      };

      const { data } = await api.get<LearningUnit>(url, { params });
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  return useQuery<LearningUnit, QueryError>({
    queryKey: getPathsLearningUnitsKey({ classId, learningUnitId, pathId }),
    queryFn: handleGetPathsLearningUnits,
    ...options,
  });
};
