import styled, { css } from "styled-components";

interface ImageSectionStyledProps {
  imageUrl?: string;
}

export const ImageWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    flex: 1;
    @media only screen and (max-width: ${theme.breakpoints.XSmall}) {
      margin-top: ${theme.spacings.Small};
      flex: 3;
    }
  `}
`;

export const ImageSectionStyled = styled.div<ImageSectionStyledProps>`
  ${({ theme, imageUrl }) => css`
    background: ${theme.colors.Primary} url(${imageUrl}) center center no-repeat;
    background-size: cover;
    filter: grayscale(1);

    height: 100%;
    width: 100%;
  `}
`;

export const FillImage = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.Primary};
  `}
  position: absolute;
  opacity: 40%;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;
