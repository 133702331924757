import { api } from "@utils";
import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationResult,
  UseMutationOptions,
} from "react-query";
import { Welcome } from ".";

type MutationError = AxiosError<void>;
type MutationOptions = UseMutationOptions<void, MutationError, Welcome>;

export const usePostEmailWelcome = (
  options?: MutationOptions
): UseMutationResult<void, MutationError, Welcome> => {
  const handlePostEmailWelcome = async (bodyData: Welcome) => {
    try {
      const url = "/email/welcome";
      const { data } = await api.post<void>(url, bodyData);
      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  return useMutation<void, MutationError, Welcome>({
    mutationFn: handlePostEmailWelcome,
    ...options,
  });
};
