import { PaddingProps } from "@constants";
import { darken } from "polished";
import styled, { css, DefaultTheme } from "styled-components";

interface FrameTypeInterface {
  background: string;
  border: string;
}

interface FrameStyledProps extends PaddingProps {
  type: "primary" | "secondary" | "tertiary" | "quaternary";
  align?: string;
  expanded?: boolean;
  border?: boolean;
  clickable?: boolean;
}

const getType = (theme: DefaultTheme, type: string): FrameTypeInterface => {
  switch (type) {
    case "primary":
      return {
        background: theme.colors.Primary,
        border: theme.colors.Primary,
      };
    case "secondary":
      return {
        background: theme.colors.White,
        border: theme.colors.Gray200,
      };
    case "tertiary":
      return {
        background: theme.colors.Gray100,
        border: theme.colors.Gray200,
      };
    case "quaternary":
      return {
        background: theme.colors.Primary + "33",
        border: theme.colors.Primary,
      };
    default:
      return {
        background: theme.colors.Primary,
        border: theme.colors.Primary,
      };
  }
};

export const FrameStyled = styled.div<FrameStyledProps>`
  ${({
    theme,
    type,
    align,
    expanded,
    border,
    clickable,
    padding,
    paddingTop,
    paddingBottom,
    paddingRight,
    paddingLeft,
    paddingTopBottom,
    paddingLeftRight,
    noPadding,
  }) => css`
    width: 100%;
    min-height: ${expanded ? "100%" : "auto"};

    position: relative;

    padding: ${padding ? `${theme.spacings[padding]}` : `${theme.spacings.SemiSmall}`};
    ${paddingTop !== undefined && `padding-top: ${theme.spacings[paddingTop]}`};
    ${paddingBottom !== undefined &&
    `padding-bottom: ${theme.spacings[paddingBottom]}`};
    ${paddingRight !== undefined &&
    `padding-right: ${theme.spacings[paddingRight]}`};
    ${paddingLeft !== undefined &&
    `padding-left: ${theme.spacings[paddingLeft]}`};
    ${paddingTopBottom !== undefined &&
    `padding-top: ${theme.spacings[paddingTopBottom]}; padding-bottom: ${theme.spacings[paddingTopBottom]}`};
    ${paddingLeftRight !== undefined &&
    `padding-left: ${theme.spacings[paddingLeftRight]}; padding-right: ${theme.spacings[paddingLeftRight]}`};
    background-color: ${getType(theme, type).background};
    ${border !== undefined &&
    `box-shadow: ${theme.border.All} ${getType(theme, type).border}`};
    border-radius: ${theme.radius.Small};
    text-align: ${align || "left"};

    ${noPadding === true && `padding: 0;`}

    &:hover {
      background: ${clickable && darken(0.02, getType(theme, type).background)};
      cursor: ${clickable ? "pointer" : "default"};
    }
  `}
`;
