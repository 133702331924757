import {
  ENV_AGREE_POLICY_URL,
  ENV_AGREE_TERMS_URL,
  ENV_SHOW_UNDER_DEVELOPMENT_FEATURES,
} from "@variables";
import React from "react";
import { CustomLink, Separator, H4, Grid, Row } from "@atomic";
import {
  FooterWrapperStyled,
  FooterContentStyled,
  FooterRightLinksStyled,
} from "./footer.component.style";

export const Footer: React.FC = () => {
  return (
    <FooterWrapperStyled>
      <Grid>
        <Row>
          <FooterContentStyled xs={12} sm={12} md={12} lg={12} xl={12}>
            <H4>
              Plataforma{" "}
              <CustomLink external to="https://www.gentelab.com.br/" newTab>
                GenteLab
              </CustomLink>{" "}
              © Copyright 2023.
            </H4>

            <FooterRightLinksStyled>
              <CustomLink external newTab to={ENV_AGREE_TERMS_URL}>
                Termos de uso
              </CustomLink>

              <Separator type="Nano" />
              <CustomLink external newTab to={ENV_AGREE_POLICY_URL}>
                Políticas de privacidade
              </CustomLink>

              {ENV_SHOW_UNDER_DEVELOPMENT_FEATURES && (
                <CustomLink to="">Perguntas frequentes</CustomLink>
              )}
            </FooterRightLinksStyled>
          </FooterContentStyled>
        </Row>
      </Grid>
    </FooterWrapperStyled>
  );
};
