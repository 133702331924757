import { FontSize } from "@constants";
import styled, { css } from "styled-components";
import { InputText } from "../typography";

export const SelectContainerStyled = styled.div``;

export const SelectWrapperStyled = styled.div`
  position: relative;

  width: 100%;

  display: flex;
  flex-direction: column;
`;

interface SelectInputProps {
  disabled?: boolean;
  isOpen?: boolean;
  searchType?: "startWith" | "includes";
}

const onFocusColor = css<SelectInputProps>`
  ${({ theme, isOpen, disabled }) =>
    disabled
      ? theme.colors.Gray200
      : isOpen
      ? theme.colors.Gray300
      : theme.colors.Gray200};
`;

export const SelectInputWrapperStyled = styled.div<SelectInputProps>`
  ${({ theme, searchType, disabled }) => css`
    position: relative;

    width: 100%;
    display: flex;

    align-items: center;
    justify-content: space-between;

    overflow: hidden;

    background-color: ${theme.colors.Gray200};

    padding: ${theme.spacings.Nano} ${theme.spacings.SemiSmall};
    border-radius: ${theme.radius.Small};

    font-size: ${FontSize.Medium};

    box-shadow: ${theme.border.All} ${onFocusColor};

    cursor: pointer;

    ${disabled &&
    css`
      cursor: not-allowed;
      color: ${theme.colors.Gray500};
      background-color: transparent;
    `}

    ${searchType &&
    css`
      cursor: text;
    `}
  `}
`;

export const SelectInputContentWrapperStyled = styled.div`
  ${({ theme }) => css`
    position: relative;

    width: 100%;
    max-width: 100%;
    margin-right: ${theme.spacings.SemiSmall};
  `}
`;

export const SelectInputStyled = styled.input<SelectInputProps>`
  ${({ theme, searchType }) => css`
    background-color: ${theme.colors.Gray200};
    outline: none;
    padding: none;
    border: none;
    display: none;

    flex: 1;

    ${InputText};

    position: relative;
    z-index: 2;

    pointer-events: none;
    cursor: pointer;

    ${searchType === "startWith" &&
    css`
      text-transform: capitalize;
    `}

    ${searchType === "includes" &&
    css`
      text-transform: none;
    `}
      
    ${searchType &&
    css`
      display: block;

      cursor: text;

      width: 100%;
    `}
      
    &::placeholder {
      color: ${theme.colors.Gray500};

      text-transform: none;

      user-select: none;
    }

    &:disabled {
      cursor: not-allowed;
      color: ${theme.colors.Gray500};
      background-color: transparent;
    }
  `}
`;

interface SelectTextStyledProps extends SelectInputProps {
  hasValue?: boolean;
}

export const SelectTextStyled = styled.input<SelectTextStyledProps>`
  ${({ theme, searchType, hasValue }) => css`
    ${InputText};

    color: ${theme.colors.Gray500};

    background: transparent;
    appearance: none;
    outline: none;
    padding: none;
    border: none;

    cursor: pointer;

    display: block;
    width: 100%;
    max-width: 100%;

    position: relative;
    z-index: 1;

    white-space: nowrap;

    ${searchType === "startWith" &&
    css`
      text-transform: capitalize;

      color: ${theme.colors.Gray500};
    `}

    ${hasValue &&
    css`
      color: ${theme.colors.Gray800};
    `}

    &:disabled {
      cursor: not-allowed;
      color: ${theme.colors.Gray500};
      background-color: transparent;
    }
  `}
`;

interface SelectInputArrowStyledProps {
  isOpen: boolean;
  disabled?: boolean;
}

export const SelectInputArrowWrapperStyled = styled.div<SelectInputArrowStyledProps>`
  ${({ theme, isOpen, disabled }) => css`
    position: absolute;
    z-index: 9;
    right: 0;
    padding: ${theme.spacings.Nano};
    padding-right: calc(${theme.spacings.SemiSmall} - 1px);

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${disabled ? "transparent" : theme.colors.Gray200};

    ${isOpen &&
    css`
      box-shadow: ${theme.border.Right} ${theme.colors.Gray300};
    `};

    ${isOpen !== true &&
    css`
      box-shadow: ${theme.border.Right} ${theme.colors.Gray200};
    `};
  `}
`;

export const SelectInputArrowStyled = styled.div<SelectInputArrowStyledProps>`
  ${({ theme, isOpen }) => css`
    border: solid ${theme.colors.Gray500};
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;

    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);

    margin-bottom: 4px;

    ${isOpen &&
    css`
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);

      margin-bottom: -6px;
    `};
  `}
`;

interface OptionsWrapperStyledProps {
  isOpen: boolean;
}

export const OptionsWrapperStyled = styled.div<OptionsWrapperStyledProps>`
  ${({ theme, isOpen }) => css`
    position: absolute;
    top: calc(${theme.spacings.SemiLarge} + ${theme.spacings.XNano});
    z-index: 99;

    width: 100%;
    max-height: ${`calc(5 * 1em + (5.25 * ${theme.spacings.Medium}))`};

    overflow-y: auto;

    background-color: ${theme.colors.White};
    box-shadow: ${theme.border.All} ${theme.colors.Gray200},
      0 4px 12px ${theme.colors.Gray300};

    border-radius: ${theme.radius.Small};

    opacity: 0;
    visibility: hidden;

    ${isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `};
  `}
`;

interface OptionStyledProps extends SelectInputProps {
  value?: string;
  selected?: boolean;
  hovered?: boolean;
}

export const OptionStyled = styled.div<OptionStyledProps>`
  ${({ theme, hovered, selected, searchType }) => css`
    padding: ${theme.spacings.Small} ${theme.spacings.SemiSmall};

    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;

    ${InputText}

    user-select: none;

    &:hover {
      background-color: ${theme.colors.Gray200};
    }

    &:first-child {
      border-radius: ${theme.radius.Small} ${theme.radius.Small} 0 0;
    }

    &:last-child {
      border-radius: 0 0 ${theme.radius.Small} ${theme.radius.Small};
    }

    ${hovered &&
    css`
      background-color: ${theme.colors.Gray200};
    `};

    ${selected &&
    css`
      color: ${theme.colors.Primary};
      background-color: ${theme.colors.Primary}14;

      &:hover {
        background-color: ${theme.colors.Primary}26;
      }
    `};

    ${selected &&
    hovered &&
    css`
      background-color: ${theme.colors.Primary}26;
    `}

    ${searchType === "startWith" &&
    css`
      text-transform: capitalize;
    `}

    ${searchType === "includes" &&
    css`
      text-transform: none;
    `}
  `}
`;

export const OptionLeftSideWrapperStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    flex: 1;
    max-height: ${theme.spacings.SemiSmall};

    * {
      max-height: ${theme.spacings.SemiSmall};
    }
  `}
`;

export const OptionRightSideWrapperStyled = styled.div`
  ${InputText};

  display: flex;
  align-items: center;
`;

export const NoResultsWrapperStyled = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.Small} ${theme.spacings.SemiSmall};
  `}
`;

export const IconStyled = styled.span<OptionStyledProps>`
  ${({ theme, selected, isOpen, disabled }) => css`
    display: block;

    min-width: 1em;
    width: 1em;
    min-height: 1em;
    height: 1em;

    margin-right: ${theme.spacings.Small};

    color: ${theme.colors.Gray500};

    &,
    * {
      transition: none;
    }

    ${selected &&
    css`
      color: ${theme.colors.Primary};

      &:hover {
        color: ${theme.colors.Primary};
      }
    `};

    ${isOpen === false &&
    css`
      color: ${theme.colors.Gray400};
    `}

    ${isOpen &&
    css`
      color: ${theme.colors.Gray600};
    `}

    ${disabled &&
    css`
      color: ${theme.colors.Gray300};
    `}
  `}
`;
